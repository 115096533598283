.career {
    &__container {
        padding: rem(25px) 0 0;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: rem(50px) 0 0;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding: rem(80px) 0 0;
        }
    }

    &__subnavigation {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(80px);
        }
    }

    &__about {
        margin-bottom: rem(85px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(120px);
        }

        &-title {
            margin-bottom: rem(25px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(65px);
            }
        }

        &-text {
            margin-bottom: rem(25px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(30px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(40px);
            }
        }

        &-button {
            display: block;
            width: 100%;
            margin: 0 auto;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(223px);
                margin: 0 auto 0 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: rem(288px);
            }
        }
    }

    &__personnel {
        margin-bottom: rem(65px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(120px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(140px);
        }

        &-title {
            margin-bottom: rem(15px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(25px);
            }
        }

        .details-list {
            display: flex;
            flex-wrap: wrap;
            @media screen and (min-width: #{breakpoint-min('md')}) {
                justify-content: space-between;
                margin-right: 0;
            }
        }

        .details-list__item {
            @media screen and (min-width: #{breakpoint-min('sm')}) {
                width: 50%;

            }
            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 25%;
                min-height: 10.5rem;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                min-height: 11rem;
            }

            &.active .details-list__inner {
                position: relative;
            }
        }

        .details-list__header {
            font-size: rem(20px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(24px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(32px);
            }
        }

        .details-list__caption {
            width: 80%;
            font-size: rem(14px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 85%;
                font-size: rem(16px);
            }
        }
    }

    &__goals {
        margin-bottom: rem(65px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(120px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(140px);
        }

        &-wrap {
            margin-bottom: rem(65px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(120px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(140px);
            }

        }

        &-title {
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(50px);
            }
        }

        &-list {
            flex-direction: column;
            flex-wrap: nowrap;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: 0;
            }

            &-item {
                margin-bottom: rem(25px);
                width: 100%;
                @include typo-level(L);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(30px);
                }

                &:before {
                    content: unset;
                }
            }
        }

        .circled-text__place {
            margin-top: rem(40px);

            &-info-inner {
                right: unset;
            }
        }

        &-mission {
            &-title {
                margin-bottom: rem(25px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(30px);
                }
            }

            & .z-icon {
                margin-bottom: 24px;
                width: 72px;
                height: 72px;
            }
        }
    }

    &__benefits {
        margin-right: -16px;
        margin-bottom: rem(70px);

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            margin-right: -32px;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-right: -48px;
            margin-bottom: rem(120px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-right: -90px;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-right: -100px;
            margin-bottom: rem(140px);
        }

        &-title {
            margin-bottom: rem(50px);


            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(60px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(95px);
            }
        }

        &-item {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                min-height: rem(200px);
            }
        }

        &-button {
            @include margin-level(top, S);
            width: 100%;
        }

    }

    &__history {
        .company__history {
            &-info {
                margin-bottom: rem(40px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: 0;
                }
            }

            &-year {
                font-size: rem(24px);
                line-height: rem(28px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(40px);
                    line-height: rem(40px);
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    font-size: rem(56px);
                    line-height: rem(60px);
                }
            }

            &-slider {
                z-index: $slider-z-index;

                &-outer {
                    display: flex;
                    width: 9999px;
                    transition: transform 0.15s ease-in;
                }

                &-controls {
                    margin-right: rem(-72px);

                    &:before {
                        position: absolute;
                        content: '';
                        top: 0;
                        bottom: 0;
                        right: 102%;
                        background: $white;
                        left: -100vw;
                        z-index: $slider-z-index;
                    }

                    &-line {
                        width: 100vw;
                    }

                    &-dots {
                        margin-left: 0;
                        transition: transform 0.15s ease;
                    }

                    &-dragger-dot {
                        position: absolute;
                        left: rem(5px);
                    }

                    &-dragger-before {
                        left: rem(-9px);

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            left: rem(-15px);
                        }
                    }

                    &-dot--with-year {
                        margin-left: rem(10px);
                        margin-right: rem(208px);

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            margin-right: rem(278px);
                        }

                        @media screen and (min-width: #{breakpoint-min('lg')}) {
                            margin-right: rem(308px);
                        }
                    }
                }
            }

            &-slide {
                font-weight: 600;

                width: rem(230px);
                min-height: 0;
                padding-right: rem(20px);

                transition: color 0.15s ease;

                color: $silverSand;

                &.active {
                    color: $firefly;
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: rem(304px);
                    padding-right: rem(40px);
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    width: rem(334px);
                    min-height: 0;
                }
            }
        }
    }

    & .youth-program__quote {
        margin-top: 0;
    }

    &__quote {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(55px);
        }

        &-title {
            margin-bottom: rem(45px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(65px);
            }
        }

        &-info {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 54%;
            }
        }

        &-job {
            margin-bottom: rem(20px);

            font-weight: bold;
            font-size: rem(12px);
            text-transform: uppercase;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(14px);
            }
        }

        &-section {
            width: 34%;
            font-weight: bold;

            &:nth-child(2) {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: 51%;
                }
            }

            &:last-child {
                width: 100%;
                margin-bottom: rem(40px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(65px);
                    width: 100%;
                }
            }

            &-title {
                font-size: rem(14px);
                color: #a5b0b9;
            }
        }

        &-time {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: rem(15px);
        }

        &-text {
            margin-bottom: rem(30px);

            &--top {
                font-weight: bold;
                font-size: rem(16px);
            }
        }

        .owl-nav {
            position: absolute;
            left: rem(16px);
            bottom: rem(45px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                left: rem(30px);
                bottom: rem(60px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                left: rem(110px);
                bottom: rem(85px);
            }
        }
    }

    &__quotation {
        padding-bottom: rem(75px);
        margin-top: rem(20px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(85px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(130px);
        }
    }

    &__video {
        margin-bottom: rem(70px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(120px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(140px);
        }

        & iframe {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }

    &__video-col {
        position: relative;
        padding-bottom: 56.25%;
        padding-right: 0 !important;
        padding-left: 0 !important;
        width: 100%;
        height: 0;
    }

    .education-card {
        background-color: rgba(45, 170, 85, 0.15);
        background-image: url('/images/career/flower.png');
        background-position: 100% 100%;
        background-repeat: no-repeat;
        padding: 24px;
        border: 1px solid #D0D3DA;
        border-radius: 3px;
        position: relative;
        transition: all 500ms ease;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &:hover {
            border: 1px solid transparent;
            transform: translateY(-10px);
            box-shadow: 15px 15px 45px rgba(8, 37, 98, 0.1);
        }

        &__tag {
            padding-left: 8px;
            padding-right: 8px;
            color: white;
            text-transform: uppercase;
            background-color: #018FA1;
            font-size: 12px;
            font-weight: 400;
            display: inline-block;
            max-width: 150px;
        }

        &__title {
            margin-top: 128px;
            color: #3B414F;
            font-weight: 700;
            font-size: 24px;
        }

        &__link {
            margin-top: 16px;
            color: #249A4A;
            font-weight: 700;
            font-size: 18px;
            display: inline-block;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.25 0.999883C10.2501 0.585669 10.5859 0.249935 11.0001 0.25L14.9694 0.25062C15.1714 0.242425 15.3761 0.315442 15.5303 0.46967C15.6846 0.623896 15.7576 0.82858 15.7494 1.03058L15.75 4.99988C15.7501 5.4141 15.4143 5.74994 15.0001 5.75C14.5859 5.75006 14.2501 5.41433 14.25 5.00012L14.2497 2.811L8.53033 8.53033C8.23744 8.82322 7.76256 8.82322 7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967L13.189 1.75034L10.9999 1.75C10.5857 1.74994 10.2499 1.4141 10.25 0.999883ZM1.75 4.5C1.75 2.98122 2.98122 1.75 4.5 1.75H7C7.41421 1.75 7.75 1.41421 7.75 1C7.75 0.585786 7.41421 0.25 7 0.25H4.5C2.15279 0.25 0.25 2.15279 0.25 4.5V11.5C0.25 13.8472 2.15279 15.75 4.5 15.75H11.5C13.8472 15.75 15.75 13.8472 15.75 11.5V9C15.75 8.58579 15.4142 8.25 15 8.25C14.5858 8.25 14.25 8.58579 14.25 9V11.5C14.25 13.0188 13.0188 14.25 11.5 14.25H4.5C2.98122 14.25 1.75 13.0188 1.75 11.5V4.5Z' fill='%232DAA55'/%3e%3c/svg%3e ");
                margin-right: 8px;
                vertical-align: middle;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:hover {
                color: #33BE60;
            }
        }
    }

    &__text {
        @include typo-level(L);
    }
}
