.mt-0 {
    margin-top: 0;
}

.mt-5 {
    margin-top: rem(5px);
}

.mt-16 {
    margin-top: rem(16px);
}

.mt-24 {
    margin-top: rem(24px);
}

.mt-32 {
    margin-top: rem(32px);
}

.mt-40 {
    margin-top: rem(40px);
}

.mt-48 {
    margin-top: rem(48px);
}

.mt-64 {
    margin-top: rem(64px);
}

.mt-80 {
    margin-top: rem(80px);
}

.mt-96 {
    margin-top: rem(96px);
}

.mt-n96 {
    margin-top: rem(-96px);
}

.mt-n24 {
    margin-top: rem(-24px);
}

.mt-n32 {
    margin-top: rem(-32px);
}

.mt-n40 {
    margin-top: rem(-48px);
}

.mt-n48 {
    margin-top: rem(-48px);
}

.mb-n24 {
    margin-bottom: rem(-24px);
}

.mb-n32 {
    margin-bottom: rem(-32px);
}

.mb-n40 {
    margin-bottom: rem(-40px);
}

.mb-n48 {
    margin-bottom: rem(-48px);
}

.mt-100 {
    margin-top: rem(100px);
}

.mt-140 {
    margin-top: 8.75rem;
}

.mt-120 {
    margin-top: rem(48px);

    @include breakpoint (mobile) {
        margin-top: rem(80px);
    }

    @include breakpoint (tablet) {
        margin-top: rem(120px);
    }
}

.mt-200 {
    margin-top: rem(20px);

    @include breakpoint (tablet) {
        margin-top: rem(200px);
    }
}

.pt-120 {
    padding-top: rem(48px);

    @include breakpoint (mobile) {
        padding-top: rem(120px);
    }

    @include breakpoint (tablet) {
        padding-top: rem(60px);
    }
}

.pt-96 {
    padding-top: rem(96px);
}

.pt-60 {
    padding-top: rem(60px);
}

.pt-64 {
    padding-top: rem(64px);
}

.pt-48 {
    padding-top: rem(48px);
}

.pt-32 {
    padding-top: rem(32px);
}

.pt-16 {
    padding-top: rem(16px);
}

.mb-12 {
    margin-bottom: rem(12px);
}

.mb-0 {
    margin-bottom: 0;

    &--important {
        margin-bottom: 0 !important;
    }
}

.mb-16 {
    margin-bottom: rem(16px);
}

.mb-20 {
    margin-bottom: rem(20px);
}

.mb-24 {
    margin-bottom: rem(24px);
}

.mb-28 {
    margin-bottom: rem(28px);
}

.mb-32 {
    margin-bottom: rem(32px);
}

.mb-40 {
    margin-bottom: rem(40px);
}

.mb-48 {
    margin-bottom: rem(48px);

    &--important {
        margin-bottom: rem(48px) !important;
    }
}

.mb-64 {
    margin-bottom: rem(64px);
}

.mb-80 {
    margin-bottom: 5rem;
}

.mb-96 {
    margin-bottom: rem(96px);
}

.mb-98 {
    margin-bottom: rem(98px);
}

.mb-120 {
    margin-bottom: 7.5rem;
}

.mb-200 {
    margin-bottom: 12.5rem;
}

.mb-120 {
    margin-bottom: 7.5rem;
}

.ml-0 {
    margin-left: 0;
}

.ml-8 {
    margin-left: 0.5rem;
}

.ml-16 {
    margin-left: rem(16px);
}

.ml-n24 {
    margin-left: rem(-24px);
}

.ml-32 {
    margin-left: rem(32px);
}

.ml-100 {
    margin-left: rem(100px);
}

.mr-8 {
    margin-right: 0.5rem;
}

.mr-14 {
    margin-right: 0.875rem;
}

.mr-16 {
    margin-right: 1rem;
}

.mr-40 {
    margin-right: 2.5rem;
}

.mr-n24 {
    margin-right: rem(-24px);
}

.mr-n100 {
    margin-right: rem(-100px);
}

.mr-24 {
    margin-right: rem(24px);
}

.pb-0 {
    padding-bottom: rem(0px);

    &--important {
        padding-bottom: 0 !important;
    }
}

.pb-5 {
    padding-bottom: rem(5px);
}

.pb-10 {
    padding-bottom: rem(10px);
}

.pb-20 {
    padding-bottom: rem(20px);
}

.pb-40 {
    padding-bottom: rem(40px);
}

.pb-60 {
    padding-bottom: rem(60px);
}

.pt-6 {
    padding-top: rem(6px);
}

.pt-10 {
    padding-top: rem(10px);
}

.pt-15 {
    padding-top: rem(15px);
}

.pt-20 {
    padding-top: rem(20px);
}

.pt-30 {
    padding-top: rem(30px);
}

.pt-35 {
    padding-top: rem(35px);
}

.pt-40 {
    padding-top: rem(40px);
}

.pt-60 {
    padding-top: rem(60px);
}

.pl-0 {
    padding-left: 0;
}

.pl-8 {
    padding-left: rem(8px);
}

.pl-20 {
    padding-left: rem(20px);
}

.pl-32 {
    padding-left: rem(32px);
}

.pb-32 {
    padding-bottom: rem(32px);
}

.pb-64 {
    padding-bottom: 4rem;
}

.pb-80 {
    padding-bottom: 5rem;
}

.pr-50 {
    padding-right: rem(50px);
}

.pr-60 {
    padding-right: rem(60px);
}

@media screen and (max-width: #{breakpoint-min('sm')}) {
    .mt-sm-24 {
        margin-top: 1.5rem;
    }

    .mt-sm-40 {
        margin-top: 2.5rem;
    }

    .mb-sm-0 {
        margin-bottom: 0;
    }

    .mb-sm-16 {
        margin-bottom: 1rem;
    }

    .mb-sm-24 {
        margin-bottom: 1.5rem;
    }

    .mb-sm-32 {
        margin-bottom: 2rem;
    }

    .mb-sm-40 {
        margin-bottom: 2.5rem;
    }

    .mb-sm-48 {
        margin-bottom: 3rem;
    }

    .mb-sm-64 {
        margin-bottom: 4rem;
    }

    .mb-sm-80 {
        margin-bottom: 5rem;
    }

    .pt-sm-0 {
        padding-top: 0;
    }
}

@media screen and (min-width: #{breakpoint-min('sm')}) {
    .pl-sm-24 {
        padding-left: rem(24px);
    }

    .pl-sm-32 {
        padding-left: rem(32px);
    }

    .pt-sm-60 {
        padding-top: rem(60px);
    }

    .pt-sm-64 {
        padding-top: rem(64px);
    }

    .mt-sm-32 {
        padding-top: rem(32px);
    }

    .mt-sm-48 {
        margin-top: rem(48px);
    }

    .pl-sm-24 {
        padding-left: rem(24px);
    }

    .pl-sm-32 {
        padding-left: rem(32px);
    }

    .pt-sm-60 {
        padding-top: rem(60px);
    }

    .pt-sm-64 {
        padding-top: rem(64px);
    }
}

@media only screen and (max-width: 598px) {
    .pl-xs-20 {
        padding-left: 1.25rem;
    }
}

@media screen and (min-width: #{breakpoint-min('sm')}) and (max-width: #{breakpoint-min('md')}) {
    .pl-sm-32 {
        padding-left: rem(32px);
    }

    .pt-sm-60 {
        padding-top: rem(60px);
    }

    .pt-sm-64 {
        padding-top: rem(64px);
    }
    .mb-md-80 {
        margin-bottom: rem(80px);
    }
}

@media screen and (min-width: #{breakpoint-min('md')}) and (max-width: #{breakpoint-min('lg')}) {
    .mt-md-32 {
        margin-top: rem(32px);
    }

    .mb-md-64 {
        margin-bottom: rem(64px);
    }

    .pt-md-60 {
        padding-top: rem(60px);
    }

    .pt-md-64 {
        padding-top: rem(64px);
    }

    .pt-md-94 {
        padding-top: rem(94px);
    }

    .pl-md-32 {
        padding-left: rem(32px);
    }

    .mt-md-40 {
        margin-top: rem(40px);
    }

    .mt-md-48 {
        margin-top: rem(48px);
    }

    .mt-md-64 {
        margin-top: rem(64px);
    }

    .mb-md-16 {
        margin-bottom: 1rem;
    }

    .mb-md-20 {
        margin-bottom: rem(20px);
    }

    .mb-md-24 {
        margin-bottom: 1.5rem;
    }

    .mb-md-28 {
        margin-bottom: rem(28px);
    }

    .mb-md-32 {
        margin-bottom: rem(32px);
    }

    .mb-md-48 {
        margin-bottom: rem(48px);
    }

    .mb-md-64 {
        margin-bottom: rem(64px);
    }

    .mb-md-80 {
        margin-bottom: rem(80px);
    }

    .mb-md-88 {
        margin-bottom: rem(88px);
    }

    .mb-md-96 {
        margin-bottom: rem(96px);
    }

    .mb-md-120 {
        margin-bottom: rem(120px);
    }

    .pt-md-60 {
        padding-top: rem(60px);
    }

    .pl-md-32 {
        padding-left: rem(32px);
    }

    .pr-md-80 {
        padding-right: rem(80px);
    }
}

@media screen and (min-width: #{breakpoint-min('md')}) and (max-width: #{breakpoint-min('xl')}) {
    .pb-md-0--custom {
        padding-bottom: 0 !important;
    }

    .pb-md-5--custom {
        padding-bottom: 5rem !important;
    }
}

@media screen and (min-width: #{breakpoint-min('md')}) {
    .mb-md-200 {
        margin-bottom: rem(200px);
    }
}

@media screen and (min-width: #{breakpoint-min('lg')}) and (max-width: #{breakpoint-min('xl')}) {
    .mt-lg-48 {
        margin-top: rem(48px);
    }

    .pt-lg-64 {
        padding-top: rem(64px);
    }

    .pt-lg-60 {
        padding-top: rem(60px);
    }

    .pl-lg-32 {
        padding-left: rem(32px);
    }

    .pb-lg-60 {
        padding-bottom: rem(60px);
    }

    .mb-lg-24 {
        margin-bottom: rem(24px);
    }
}

@media screen and (min-width: #{breakpoint-min('xl')}) {
    .pt-xl-60 {
        padding-top: rem(60px);
    }

    .pt-xl-64 {
        padding-top: rem(64px);
    }

    .pt-xl-120 {
        padding-top: rem(120px);
    }

    .pl-xl-32 {
        padding-left: rem(32px);
    }

    .mb-xl-24 {
        margin-bottom: rem(24px);
    }

    .mb-xl-32 {
        margin-bottom: rem(32px);
    }

    .mb-xl-40 {
        margin-bottom: rem(40px);
    }

    .mb-xl-48 {
        margin-bottom: rem(48px);

        &--important {
            margin-bottom: rem(48px) !important;
        }
    }

    .mb-xl-64 {
        margin-bottom: rem(64px);
    }

    .mb-xl-80 {
        margin-bottom: rem(80px);
    }

    .mb-xl-82 {
        margin-bottom: rem(82px);
    }

    .mb-xl-120 {
        margin-bottom: rem(120px);
    }

    .mb-xl-125 {
        margin-bottom: rem(125px);
    }

    .mt-xl-96 {
        margin-top: rem(96px);
    }

    .pt-xl-60 {
        padding-top: rem(60px);
    }

    .pl-xl-32 {
        padding-left: rem(32px);
    }

    .pr-xl-40 {
        padding-right: rem(40px);
    }

    .pr-xl-64 {
        padding-right: rem(64px);
    }

    .mt-xl-48 {
        margin-top: rem(48px);
    }

    .mt-xl-32 {
        margin-top: rem(32px);
    }

    .mt-xl-0 {
        margin-top: rem(0px);
    }
}

@media screen and (min-width: #{breakpoint-min('md')}) {
    .pr-md-96 {
        padding-right: rem(96px);
    }

    .mb-md-24 {
        margin-bottom: rem(24px);
    }

    .mt-md-24 {
        margin-top: rem(24px);
    }

    .mt-md-64 {
        margin-top: rem(64px);
    }

    .mt-md-98 {
        margin-top: rem(98px);
    }
}
