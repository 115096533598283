.select2-container--default {
    &.select--transparent {
        .select2-selection--single {
            border: none;
        }

        .select2-results__option {
            &:last-child {
                border-top: rem(1px) solid $wildSand;
                line-height: rem(42px);
            }

            &:nth-last-child(2) {
                padding-bottom: rem(15px);
            }

            .html--header-search-opened & {
                &:last-child {
                    border-top: none;
                    line-height: rem(28px);
                }

                &:nth-last-child(2) {
                    padding-bottom: rem(6px);
                }
            }
        }
    }

    &.select--calendar-tab {
        .select2-selection--single {
            border: none;
        }

        .select2-dropdown {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                min-width: rem(160px);
            }
        }

        .select2-results__option {
            &:last-child {
                border-top: none;
                line-height: rem(28px);
            }

            &:nth-last-child(2) {
                padding-bottom: rem(6px);
            }
        }
    }

    &.select--calendar-tab {
        .select2-selection--single {
            border: none;
        }

        .select2-dropdown {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                min-width: rem(168px);
            }
        }

        .select2-results__option {
            &:last-child {
                border-top: none;
                line-height: rem(28px);
            }

            &:nth-last-child(2) {
                padding-bottom: rem(6px);
            }
        }
    }

    &.select--calendar-add {
        .select2-selection--single {
            border: none;
            display: none;
        }

        .select2-dropdown {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                min-width: rem(160px);
            }
        }

        .select2-results__option {
            &[aria-selected='true'] {
                color: $silverSand;
            }

            &--highlighted[aria-selected] {
                color: $firefly;
            }

            &:last-child {
                border-top: none;
                line-height: rem(28px);
            }

            &:nth-last-child(2) {
                padding-bottom: rem(6px);
            }
        }
    }

    &.select--phone-prefix {
        .select2-selection--single {
            border: none;

            .select2-selection__arrow {
                b {
                    &:after {
                        border-color: $iron;
                    }
                }
            }
        }

        .select2-dropdown {
            min-width: rem(255px);
            //margin-left: rem(-204px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-left: 0;
            }
        }

        .select2-results__option {
            padding-top: rem(13px);
            padding-bottom: rem(13px);
            line-height: rem(24px);

            &:last-child {
                border-top: none;
                line-height: rem(24px);
            }

            &:nth-last-child(2) {
                padding-bottom: rem(13px);
            }
        }
    }

    &.select--full {
        width: 100%;
    }

    &.select2-container--open {
        &.select2-container--below {
            .select2-selection--single {
                border-radius: rem(20px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    border-radius: rem(32px);
                }
            }
        }

        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    &:after {
                        top: 0;
                        transform: rotate(135deg);
                    }
                }
            }
        }

        .select2-dropdown--below,
        .select2-dropdown--above {
            border: none;
            box-shadow: rem(10px) rem(10px) rem(45px) 0 rgba(0, 0, 0, 0.07);
            margin-top: rem(13px);
        }
    }

    .select2-selection--single {
        border-radius: rem(20px);
        min-height: rem(40px);
        outline: none;
        position: relative;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            border-radius: rem(32px);
            min-height: rem(62px);
        }

        .select2-selection__rendered {
            line-height: rem(38px);
            padding-left: rem(28px);
            padding-right: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(20px);
                line-height: rem(60px);
            }
        }

        .select2-selection__arrow {
            min-height: rem(38px);
            top: 50%;
            transform: translate(0, -50%);

            b {
                border: none;
                left: auto;
                right: rem(20px);

                &:after {
                    border-left: rem(2px) solid $congressBlue;
                    border-bottom: rem(2px) solid $congressBlue;
                    content: '';
                    display: block;
                    position: relative;
                    top: rem(-3px);
                    height: rem(7px);
                    width: rem(7px);
                    transform: rotate(-45deg);
                    transition: all linear 0.2s;
                }
            }
        }
    }

    .select2-results__option {
        padding-left: rem(20px);
        padding-right: rem(20px);
        line-height: rem(28px);
    }

    .select2-results {
        > {
            .select2-results__options {
                max-height: rem(275px);
            }
        }
    }

    .select2-results__option[aria-selected='true'] {
        background-color: $white;
        color: $firefly;
    }

    .select2-results__option[aria-selected='false'] {
        color: $silverSand;
    }

    .select2-results__option--highlighted[aria-selected] {
        background-color: $wildSand;
        color: $firefly;
    }
}

.select {
    width: rem(288px);
}

.select--transparent {
    width: rem(160px);

    .selection {
        display: flex;
    }

    .select2-selection--single {
        .select2-selection__rendered {
            padding-left: 0;
            padding-right: rem(30px);
        }

        .select2-selection__arrow {
            b {
                left: 50%;
            }
        }
    }
}

.select--alternative {
    .select2-selection {
        border: none;
    }

    .select2-selection__rendered {
        display: block;
        width: 100%;
        padding-top: 0;
        padding-left: 0 !important;
        padding-bottom: rem(15px);
        font-size: rem(16px);
        font-weight: bold;
        line-height: (24 / 16) !important;
        text-align: left;
        color: $firefly !important;
        border-bottom: rem(1px) solid $silverSand;
    }

    .select2-selection__arrow {
        b::after {
            top: rem(-8px) !important;
            border-color: $silverSand !important;
        }
    }
}

.select--calendar-tab {
    min-width: rem(149px);
}

.select--phone-prefix {
    width: auto !important;
}

.select--calendar-add {
    position: absolute;
    right: 0;
    top: rem(5px) !important;
}

.select.select--full {
    width: 100%;
}

.select2-hidden-accessible {
    left: -9999px;
    clip-path: none !important;
}

.select {
    &__flag {
        margin-top: rem(5px);
        min-width: rem(20px);
        height: rem(14px);
        margin-right: rem(16px);
    }

    &__phone-prefix {
        display: inline-block;
        margin-left: auto;
    }
}
@media (max-width: #{breakpoint-min('sm')}) {
    span.select2-container.select2-container--default.select--transparent.select2-container--open {
        left: 0 !important;
        right: 0 !important;
        width: auto !important;
    }
    span.select2-container.select2-container--default.select--alternative.select2-container--open {
        left: 0 !important;
        right: 0 !important;
    }
    span.select2-container.select2-container--default.select--transparent.select--calendar-tab.select2-container--open {
        left: 0 !important;
        right: 0 !important;
        width: auto !important;
    }
}

span.select2-dropdown.select2-dropdown--below,
span.select2-dropdown.select2-dropdown--above {
    margin-top: 0 !important;
    width: 100% !important;
}

.select--transparent-field {
    &.select2-container {
        width: 100% !important;
    }

    .select2-selection {
        border: none;
    }

    .select2-selection__rendered {
        display: block;
        width: 100%;
        padding-top: 0;
        padding-left: 0 !important;
        padding-bottom: rem(15px);
        font-size: rem(16px);
        font-weight: bold;
        line-height: (24 / 16) !important;
        text-align: left;
        color: $firefly !important;
        border-bottom: rem(1px) solid $silverSand;
    }

    .select2-selection__arrow {
        b::after {
            border-color: $silverSand !important;
        }
    }

    .select2-selection__placeholder {
        color: $silverSand !important;
        font-size: rem(16px);
    }
}
