.radio {
    &__label {
        position: relative;
        padding-left: rem(40px);
    }

    &__wrap {
        width: 48%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 30%;
        }
    }

    & + &__label::before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        width: rem(24px);
        height: rem(24px);
        border: rem(1px) solid $iron;
        border-radius: 50%;
    }

    & + &__label::after {
        display: none;
        position: absolute;
        content: '';
        top: rem(6px);
        left: (6px);
        background-color: $eucalyptus;
        width: rem(12px);
        height: rem(12px);
        border-radius: 50%;
    }

    &:checked + &__label::before {
        border: rem(1px) solid $eucalyptus;
    }

    &:checked + &__label::after {
        display: block;
    }
}
