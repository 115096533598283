.integrated-report {
    $parent: &;

    &__list {
        margin-bottom: 3rem;

        @include breakpoint(mobile) {
            margin-bottom: 1rem;
        }
    }

    &__link {
        max-width: 630px;

        @include breakpoint(v-mobile) {
            display: flex !important; 
            flex-direction: column;
            flex-wrap: nowrap;
            margin-left: auto;
            margin-right: auto;
        }

        @include breakpoint(mobile) {
            max-width: none;
            height: 100%;
            justify-content: flex-end;
            margin-left: 0;
            margin-right: 0;
        }

        @include breakpoint(tablet) {
            justify-content: flex-start;
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__figure {
        @include breakpoint(tablet) {
            height: 73%;
        }
    }

    &__img {
        @include breakpoint(tablet) {
            height: 100%;
            object-fit: contain;
            object-position: bottom;
        }
    }

    &__text-wrap {
        min-height: auto;
        text-align: center;
        text-transform: uppercase;

        @include breakpoint(mobile) {
            min-height: 56px;
        }

        @include breakpoint(tablet) {
            min-height: auto;
            text-align: left;
        }
    }
}