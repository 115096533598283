.subscribe-page {
    &__title {
        margin: 0;
        margin-right: rem(8px);
    }

    &__title-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.js-pres-open-modal-button {
            cursor: pointer;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            width: 100%;
            max-width: rem(630px);
            margin: 0 auto;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            max-width: rem(775px);
        }
    }

    &__button {
        margin: 0 auto;
        margin-top: rem(56px);
        padding-top: rem(16px);
        padding-bottom: rem(16px);
        width: 100%;
        max-width: rem(320px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: none;
        }
    }

    &__card-footer {
        margin-top: 0;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(64px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-top: 0;
        }
    }
}
