.show-more {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: rem(64px);

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        margin-bottom: rem(80px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            z-index: -1;
            margin-top: rem(-0.5px);
            border: rem(1px) solid rgba($grayChateau, 0.5);
        }
    }

    &__button {
        position: relative;
        z-index: 1;
        width: 100%;
        max-width: rem(296px);
        border: rem(1px) solid $grayChateau;
    }
}
