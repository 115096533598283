.site-selector {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 3rem;

    &__item {
        width: 50%;
        margin-bottom: 0.9rem;
        position: relative;
        font-size: 1.25rem;
        line-height: 2.1rem;
        color: rgba($firefly, 0.5);
        transition: color 0.3s;
        display: flex;

        @media screen and (min-width: #{breakpoint-min('md')}),
        screen and (min-width: #{breakpoint-min('lg')}) {
            width: 32%
        }

        &:hover {
            color: $firefly;
        }

        &.active {
            .site-selector__active {
                border: 1px solid $eucalyptus;
            }
        }
    }

    &__icon {
        display: block;
        width: 1.125rem;
        height: .75rem;
        z-index: 1;
        background-repeat: no-repeat;
        margin-right: 16px;
    }

    &__icon--round {
        display: block;
        width: 18px;
        height: 18px;
        z-index: 1;
        background-repeat: no-repeat;
        margin-left: rem(11px);
        border-radius: 50%;
    }
    
    &__icon--word {
        display: none;
        width: 18px;
        height: 18px;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center;
        margin-left: rem(11px);
        border-radius: 50%;
        background-image: url(/local/templates/.default/img/world-white.svg);

        .header--fixed & {
            background-image: url(/local/templates/.default/img/world-black.svg);
        }
    }

    &__active {
        display: flex;
        align-items: baseline;
        padding: 0 rem(10px);
        border-radius: 32px;
    }

}

.html--header-menu-opened,
.html--header-search-opened {

    .header--main,
    .header--inner {

        .site-selector__icon--word {
            background-image: url(/local/templates/.default/img/world-black.svg);
        }
    }
}

.header--without-banner .site-selector__icon--word {
    background-image: url(/local/templates/.default/img/world-black.svg);
}
