.teaser-card {
    display: flex;
    text-decoration: none;
    // min-height: 100%;

    &:not(#{&}--inactive) & {
        &__inner {
            border-color: $eucalyptus;

            &:hover {
                box-shadow: 0.625rem 0.625rem 2.8125rem rgba($black, 0.07);
                border-color: transparent;
                transform: translateY(-10px);
                cursor: pointer;

                .teaser-card__title {
                    background-size: 100% 1px;
                }
            }
        }
    }

    &--polska:not(.teaser-card--inactive) {
        height: 100%;

        .teaser-card {
            &__inner {
                width: 100%;
                min-height: rem(400px);
                border: 0.0625rem solid rgba(14, 33, 47, 0.2);

                &:hover {
                    border-color: #2daa55;

                    .teaser-card__figure {
                        border: 0.0625rem solid #2daa55;
                    }

                    .teaser-card__title {
                        color: #2daa55;
                    }

                    .teaser-card__header {
                        span {
                            opacity: 1;
                        }
                    }
                }
            }

            &__header {
                align-items: center;

                span {
                    display: inline-block;
                    opacity: 0;
                    color: #2daa55;
                    transition: all 0.3s ease-in-out;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0.805px;
                    text-transform: uppercase;

                    img {
                        height: 12px;
                        width: 12px;
                        margin-bottom: 2px;
                    }
                }
            }

            &__figure {
                background-color: white;
                border: 0.0625rem solid rgba(14, 33, 47, 0.2);
                transition: all 0.3s ease-in-out;
            }

            &__body {
                margin-bottom: rem(82px);
                margin-top: rem(42px);
            }

            &__title {
                font-family: Foco;
                font-style: normal;
                font-weight: bold;
                font-size: rem(24px);
                color: #0e212f;
                margin-bottom: rem(18px);
                transition: all 0.3s ease-in-out;
            }
        }
    }

    &__inner {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-height: rem(272px);
        padding: rem(23px) rem(23px) rem(31px) rem(23px);
        border: rem(1px) solid rgba($firefly, 0.2);
        border-radius: rem(3px);
        transition: all 300ms ease-in-out;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: rem(16px);
    }

    &__body {
        margin-top: auto;
    }

    &__figure {
        padding: rem(16px);
        border-radius: 50%;
        background-color: $alto;
    }

    &__icon {
        color: $white;
    }

    &__tag {
        padding-left: rem(8px);
        padding-right: rem(8px);
        font-size: rem(12px);
        line-height: (18 / 12);
        letter-spacing: rem(0.69px);
        text-transform: uppercase;
        color: $white;
        border-radius: rem(3px);

        &--green {
            background-color: $eucalyptus;
        }
    }

    &__label {
        display: inline-block;
        margin-bottom: rem(16px);
        font-size: rem(14px);
        line-height: (22 / 14);
        letter-spacing: rem(0.805);
        text-transform: uppercase;
        color: rgba($firefly, 0.6);
    }

    &__title {
        font-size: rem(20px);
        line-height: (32 / 20);
        font-weight: normal;
    }
}

.teaser-card--full-height {
    height: 100%;
}

.teaser-card--min-height {
    .teaser-card__inner {
        min-height: auto;
    }
}

.teaser-card--theme-gray {
    .teaser-card__inner {
        background: #F3F3F5;
        border-radius: 4px;
        padding: 24px;
        border: none;
        transition: background 0.2s ease-in;
        border: 1px solid transparent !important;

        &:hover {
            background: #fff;
            color: inherit;
            border: 1px solid $eucalyptus !important;
            box-shadow: none;
        }
    }

    .teaser-card__body {
        margin: 0;
    }

    .teaser-card__header {
        margin-bottom: 16px;
    }

    .teaser-card__title {
        font-weight: 800;
        font-family: $foco;
        margin-bottom: 8px;
        line-height: 1.7;
        font-size: 18px;
        color: $firefly;
    }

    .teaser-card__text {
        color: rgba($firefly, 0.6);
    }
}
