.picture-list {
    @include list-reset();

    @media screen and (min-width: #{breakpoint-min('md')}) {
        column-count: 2;
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        column-count: 3;
    }

    &__item {
        font-size: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 45%;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            font-size: rem(20px);
        }

        &-figure {
            margin-bottom: rem(20px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(30px);
            }
        }

        &-info {
            margin-bottom: rem(45px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(60px);
            }

            &--small {
                width: rem(200px);
            }
        }
    }
}
