.header {
    $parent: &;
    $headerWithoutBanner: 6.75rem;
    $headerWithoutBannerMD: 6.625rem;
    $headerWithoutBannerLG: 8.55rem;
    $headerWithoutBannerXL: 10rem;

    position: relative;
    background-color: $silverSand;

    &--main,
    &--inner {
        color: $white;

        &.header--fixed {
            .header__nav-link,
            .link--header {
                color: black;

                &.current {
                    color: #2daa55;
                }
            }
        }
    }

    &--main {
        height: rem(660px);

        @include breakpoint(mobile) {
            height: rem(700px);
        }

        @include breakpoint(tablet) {
            height: rem(755px);
        }
    }

    &--inner {
        height: rem(400px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            height: rem(472px);
        }

        @media screen and (max-width: #{breakpoint-min('sm')}) {
            height: min-content;
        }
    }

    &--without-banner,
    &--white-banner {
        color: $firefly;
        background-color: $white;

        .header__nav-link,
        .link--header,
        .top-right-menu__list-link {
            color: #000000;
        }
        .link__selector:after {
            background-image: url(/local/templates/.default/img/arrow-down-black.svg);
        }
    }

    &--without-banner {
        height: $headerWithoutBanner;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            height: $headerWithoutBannerMD;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            height: $headerWithoutBannerLG;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            height: $headerWithoutBannerXL;
        }
    }

    &--white-banner {
        height: auto;
        background-color: rgba(183, 188, 193, 0.1);

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            border-bottom: none;
        }
    }

    &__top {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 39;
        min-height: 4.5rem;

        .header--fixed & {
            position: fixed;
            background: $white;
            color: $firefly;
            animation: headerSlideIn 0.3s ease-in-out 1;
            z-index: 101;


            @media screen and (min-width: #{breakpoint-min('md')}) {
                min-height: rem(72px);
                display: flex;
                align-items: center;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                align-items: flex-end;
            }

            @keyframes headerSlideIn {
                from {
                    transform: translateY(-100%);
                }

                to {
                    transform: translateY(0);
                }
            }

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                height: rem(20px);
                left: 0;
                right: 0;
                border-bottom: rem(1px) solid $silverSand;
            }
        }

        .html--header-menu-opened &,
        .html--header-search-opened & {
            background: $white;
            color: $table-line-grey;

            &:after {
                content: '';
                position: absolute;
                bottom: 0;
                height: rem(20px);
                left: 0;
                right: 0;
                border-bottom: rem(1px) solid $silverSand;
            }
        }

        .html--header-search-opened & {
            @media screen and (min-width: #{breakpoint-min("xl")}) {
                &:after {
                    display: none;
                }
            }
        }

        &-inner {
            display: flex;
            flex-wrap: nowrap;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: space-between;
            padding-top: rem(24px);

            @media screen and (min-width: #{breakpoint-min('sm')}) {
                flex-wrap: wrap;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(16px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(24px);
            }

            .header--fixed & {

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    padding-top: 0;
                    display: flex;
                    align-items: start;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    flex-wrap: nowrap;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    padding-top: rem(24px);
                    align-items: flex-start;
                }
            }
        }
    }

    &__logo {
        display: flex;
        flex-grow: 1;
        margin-bottom: 15px;

        @media screen and (min-width: #{breakpoint-min('md')}) and (max-width: #{breakpoint-min('lg')}) {
            margin-bottom: 10px;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            max-width: 16.75%;
            flex: 0 0 16.75%;
            margin-right: 25px;
        }

        .header--fixed & {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: 15px;
            }
        }

        &-img {
            width: 145px;

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                width: 210px;
            }

            &--coloured {
                display: none;
            }

            &--fixed {
                display: none;
            }

            .html--header-menu-opened &,
            .html--header-search-opened & {
                display: none;

                &--coloured {
                    display: block;
                }

                &--fixed {
                    display: block;
                }
            }
            .header--white-banner &,
            .header--without-banner & {
                display: none;

                &--coloured {
                    display: block;
                }

                &--fixed {
                    display: none;
                }
            }

            .header--fixed & {
                display: none;
                width: 150px;

                &--coloured {
                    display: none;
                }

                &--fixed {
                    display: block;
                }
            }
        }
    }

    &__nav {
        display: none;
        position: relative;
        z-index: 1;
        width: auto;
        order: 3;
        margin-bottom: rem(-1px);

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            display: block;
            width: 100%;
        }

        .header--fixed & {
            display: none;

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                position: unset;
                display: block;
                margin-top: rem(3px);
                width: auto;
                flex-grow: 1;
                padding-left: 0;
                margin-left: rem(24px);
            }
        }


        &-list {
            margin-bottom: 0;
            padding-left: 0;
            display: flex;

            &--first-level {
                justify-content: space-between;
                display: none;

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    display: flex;
                }

                .header--fixed & {
                    @media screen and (min-width: #{breakpoint-min('lg')}) {
                        &:not(.header__nav-list--first-level) {
                            justify-content: flex-start;
                        }
                    }
                }

                .header--fixed .header__nav--has-second-level & {
                    display: none;
                }
            }

            &--second-level {
                display: none;

                .header--fixed .header__nav--has-second-level & {
                    display: flex;
                }
            }

            &-item {
                list-style: none;
                flex-shrink: 0;

                &:not(:last-of-type) {
                    margin-right: rem(16px);
                }

                .header--fixed & {

                    &:last-of-type {
                        padding-right: rem(16px);
                    }

                    @media screen and (min-width: #{breakpoint-min('md')}) {


                        &:last-of-type {
                            padding-right: rem(24px);
                        }
                    }
                }
            }
        }

        &-link {
            position: relative;
            padding-bottom: 23px;
            @include typo-level(M);
            font-weight: 700;
            color: $white;
            transition: opacity .3s ease-in-out, color .3s ease-in-out;
            cursor: pointer;

            &:not(.active):not(.current):hover {
                color: inherit;
                opacity: 0.8;
            }

            &:not(.active):not(.current):active {
                opacity: 1;
                color: #2daa55;
            }

            &.active,
            &.current {
                font-weight: bold;

                &:after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: rem(3px);
                    background: $eucalyptus;
                    border-radius: rem(2px);
                }
            }

            &.is-active:after {
                background:  #2daa55;
            }

            &.current {
                color: $eucalyptus;
                pointer-events: none;

                & .menu-arrow{
                    background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.0411 0.654859C10.3494 0.984765 10.3494 1.49716 10.0411 1.82706L6.51643 5.5983C6.17716 5.96131 5.60149 5.96131 5.26222 5.5983C4.95388 5.26839 4.95388 4.756 5.26222 4.4261L8.78687 0.654859C9.12614 0.291853 9.70181 0.291853 10.0411 0.654859Z' fill='%232daa55'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.71087 0.654859C1.40253 0.984765 1.40253 1.49716 1.71087 1.82706L5.23552 5.5983C5.5748 5.96131 6.15046 5.96131 6.48973 5.5983C6.79807 5.26839 6.79807 4.756 6.48973 4.4261L2.96508 0.654859C2.62581 0.291853 2.05014 0.291853 1.71087 0.654859Z' fill='%232daa55'/%3E%3C/svg%3E");
                    transform: rotate(180deg);
                }
            }

            .header--fixed & {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    padding-bottom: 24px;
                }
                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    font-size: 16px;
                }
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: 18px;
                }
            }

            &--popup {
                display: flex;
                align-items: center;
                gap: 8px;

                & .menu-arrow{
                    background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.0411 0.654859C10.3494 0.984765 10.3494 1.49716 10.0411 1.82706L6.51643 5.5983C6.17716 5.96131 5.60149 5.96131 5.26222 5.5983C4.95388 5.26839 4.95388 4.756 5.26222 4.4261L8.78687 0.654859C9.12614 0.291853 9.70181 0.291853 10.0411 0.654859Z' fill='%23ffffff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.71087 0.654859C1.40253 0.984765 1.40253 1.49716 1.71087 1.82706L5.23552 5.5983C5.5748 5.96131 6.15046 5.96131 6.48973 5.5983C6.79807 5.26839 6.79807 4.756 6.48973 4.4261L2.96508 0.654859C2.62581 0.291853 2.05014 0.291853 1.71087 0.654859Z' fill='%23ffffff'/%3E%3C/svg%3E");

                }
            }
        }

        .html--header-search-opened & {
            display: none;
        }
    }

    &__controls {
        display: flex;
        align-items: center;

        .html--header-search-opened & {
            .link--header {
                color: $eucalyptus;
            }
        }

        &--header {
            order: 2;
            margin-top: rem(3px);
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                min-height: rem(40px);
                margin-top: 0;
                margin-bottom: rem(16px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                flex-grow: 1;
                margin-bottom: rem(24px);
                position: relative;
            }

            .header--fixed & {
                min-height: 0;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: 0;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    margin-top: rem(8px);
                    margin-left: rem(40px);
                    display: none;
                    order: 3;
                    flex-grow: 0;
                    min-height: 0;
                }
            }


        }

        &--menu {
            @media screen and (min-width: #{breakpoint-min('md')}) {}

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                display: none;

                .header--fixed & {
                    display: flex;
                }
            }

            &-inner {
                display: none;
                justify-content: flex-end;
                margin-top: rem(42px);

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    display: flex;
                }
            }
        }

        &--contacts {
            display: flex;
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(39px);
            }
        }

        &-menu {
            order: 2;
            margin-left: .8rem;

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                display: none;
            }

            .header--fixed & {
                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    display: block;
                }
            }

            &--inner {
                display: none;

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    display: block;

                    .header--fixed & {
                        display: none;
                    }
                }
            }

            &-toggler {
                display: flex;

                &--open {
                    .html--header-menu-opened & {
                        display: none;
                    }
                }

                &--close {
                    display: none;

                    .html--header-menu-opened & {
                        display: flex;
                    }
                }
            }
        }

        .header--fixed & {
            &-menu {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: none;
                }
            }
        }
    }

    &__city {
        &--header {
            margin-right: rem(40px);
            display: none;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: block;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                margin-right: auto;
            }

            .header--fixed & {
                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    display: none;
                }
            }
        }

        &--menu {
            @media screen and (min-width: #{breakpoint-min('lg')}) {
                margin-right: auto;
            }
        }

        &-toggler {
            display: inline-flex;
            align-items: center;

            font-weight: bold;

            &-icon {
                font-size: rem(11px);
            }

            &-text {
                display: none;
                margin-left: rem(8px);

                @media screen and (min-width: 1023px) {
                    display: inline-block;
                }
            }
        }
    }

    &__search {
        position: relative;

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            height: rem(40px);
        }

        &--header {
            order: 2;
            margin-right: 0.2rem;
            align-items: center;
            display: flex;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-right: rem(40px);
            }

            @media screen and (max-width: #{breakpoint-max('xs')}) {
                margin-right: 14px;
            }

            .header--fixed & {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: none;
                }
            }
        }

        &--menu {
            margin-right: rem(40px);
            display: none;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: block;
            }
        }

        &-btn {
            display: none;
            position: absolute;
            top: 23%;
            right: 22%;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                .html--header-search-opened & {
                    display: block;
                }
            }
        }

        &-toggler {
            display: inline-flex;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                .html--header-search-opened & {
                    display: none;
                }
            }
        }

        &-input {
            display: none;
            width: rem(624px);
            height: 100%;
            padding: rem(8px) rem(24px) rem(8px) rem(14px);
            border-radius: rem(25px);
            border: rem(1px) solid currentColor;
            color: currentColor;

            background: transparent;

            .html--header-search-opened & {
                display: block;
            }
        }

        &-results {
            position: absolute;
            left: 0;
            right: 0;
            top: calc(100% + #{rem(12px)});
            color: black;
            background: $white;
            padding: rem(4px) 0;
            box-shadow: rem(10px) rem(10px) rem(45px) rgba(0, 0, 0, 0.07);
            border-radius: rem(3px);
            max-height: rem(300px);
            overflow: auto;
            z-index: 10;

            &-list {
                margin: 0;
                padding: 0;

                &-item {
                    list-style: none;
                    padding: 0;
                }
            }

            &-result {
                padding: rem(8px) rem(21px);
                width: 100%;
            }
        }

        &-filter {
            display: flex;
            flex-direction: column;
            padding-top: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(38px);
            }

            &-list {
                display: inline-flex;
                flex-wrap: wrap;
                list-style: none;
                padding-left: 0;

                li {
                    padding-right: rem(24px);
                    padding-top: rem(16px);
                    font-size: rem(16px);
                    line-height: rem(24px);
                    color: $firefly;
                    cursor: pointer;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        padding-right: rem(40px);
                    }
                }
            }

            &-heading {
                font-size: rem(16px);
                line-height: rem(22px);
                color: $grayChateau;
            }
        }
    }

    &__site-link {
        display: none;
        order: 2;
        margin-right: rem(40px);

        @media (min-width: #{breakpoint-min('xl')}) {
            margin-right: rem(88px);
        }

        @media (min-width: #{breakpoint-min('md')}) {
            display: block;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            .html--header-search-opened & {
                display: none;
            }
        }

        .header--fixed & {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: none;
            }
        }

        .link {
            font-weight: bold;
            font-size: 16px;
            line-height: 120%;

            &__icon {
                width: 8px;
                height: 8px;
            }
        }
    }

    &__beta {
        position: relative;
        display: inline-flex;
        align-items: center;
        font-weight: bold;

        // решает проблему шапки с плывущими иконками
        @media (min-width: 320px) and (max-width: 335px) {
            &-icon {
                display: none !important;
            }
        }

        &--header {
            order: 2;
            margin-right: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-right: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                order: 3;
                margin-right: 0;
            }

            .header--fixed & {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: none;
                }
            }
        }

        &--menu {
            margin-right: rem(40px);
            display: none;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: block;
            }
        }

        &-text {
            margin-left: rem(8px);
        }

        .html--header-search-opened &,
        .html--header-menu-opened & {
            display: none !important;
        }
    }

    &__lang {
        font-weight: bold;
        z-index: 5;

        &--header {
            order: 2;
            margin-right: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-right: rem(48px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                .html--header-search-opened & {
                    display: none;
                }
            }

            .header--fixed & {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: none;
                }
            }
        }

        &--menu {
            margin-right: 0;
            display: none;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: block;
            }
        }

        &--container {
            position: relative;
            margin-right: 0;
            display: flex;
            align-items: center;

            .icon--arrow {
                width: 0.75rem;
                height: 0.5rem;
                margin-bottom: 2px;
                margin-left: 8px;
            }

            .icon--arrow-reverse {
                transform: rotate(180deg);
            }
        }

        &--list {
            position: absolute;
            top: 40px;
            right: 0;
            display: flex;
            flex-direction: column;
            background-color: $white;
            box-shadow: 10px 10px 45px rgba(0, 0, 0, 0.07);
            border-radius: 3px;

            .link--header-lang {
                color: $gray;
                font-weight: 400;
                padding: 8px 24px;
                border-radius: 3px;

                &:hover {
                    color: $black;
                    background-color: $wildSand;
                }

                &.active {
                    color: $black;
                }
            }
        }
    }

    &__order-button {
        padding: rem(8.5px) rem(32px);
        display: none;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: rem(8.5px) rem(24px);
            display: block;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding: rem(8.5px) rem(32px);
            margin-right: 0;
            display: block;
        }

        &--header {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                order: 2;
                margin-left: rem(40px);

                .html--header-search-opened & {
                    display: block;
                }
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                order: 3;
            }

            .header--fixed & {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: none;

                    .html--header-search-opened & {
                        display: block;
                    }
                }
            }
        }
    }

    &__search-block {
        display: none;
        position: absolute;
        background: $white;
        color: $firefly;
        left: 0;
        right: 0;
        top: 72px;
        bottom: 0;
        z-index: 100;
        height: calc(100vh - #{rem(73px)});
        overflow: auto;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            top: rem(56px);
            height: calc(100vh - #{rem(56px)});
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            top: rem(80px);
            height: calc(100vh - #{rem(80px)});
        }

        .header--fixed & {
            position: fixed;
            max-height: none;
            height: auto;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                top: rem(80px);
            }
        }

        .html--header-search-opened & {
            display: block;
        }

        &-inner {
            padding-top: rem(32px);
            padding-bottom: rem(120px);
            overflow: hidden;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(32px);
            }
        }

        &-wrapper {
            position: relative;
        }

        &-select {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: rem(24px);

            [class^=select] {
                width: initial !important;
            }

            label {
                font-size: rem(16px);
                line-height: rem(24px);
                color: $firefly06;
                margin-bottom: rem(4px);
            }

            .selection {
                justify-content: flex-end;
            }

            .select2-selection--single {
                background-color: transparent !important;
            }

            .select2-selection__rendered {
                padding-right: rem(46px) !important;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                justify-content: flex-start;

                .selection {
                    justify-content: flex-start;
                    padding-left: rem(16px);
                }
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                position: absolute;
                right: 0;
                top: -10px;

                .select2-container {
                    display: flex;
                    justify-content: flex-end;
                }
            }
        }

        &-link {
            display: inline-flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .button {
                display: none;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                .button {
                    display: block;
                }
            }

            &--products {
                padding-top: rem(64px);
            }

            &--company {
                padding-top: rem(54px);
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(80px);
            }
        }

        &-cards {
            padding-top: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(55px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(40px);
            }
        }

        &-all-btn {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: none;
            }
        }
    }

    &__menu {
        display: none;
        position: absolute;
        background: $white;
        color: $firefly;
        left: 0;
        right: 0;
        top: 72px;
        bottom: 0;
        z-index: 100;
        max-height: 100vh;
        height: calc(100vh - 72px);
        height: calc(100dvh - 72px); /* for ios */
        overflow: auto;

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            top: rem(108px);
            max-height: calc(100vh - #{rem(108px)});
            height: calc(100vh - #{rem(108px)});
        }

        .header--fixed & {
            position: fixed;
            max-height: none;
            height: auto;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                top: rem(72px);
            }
        }

        .header--fixed.header--has-second-level-nav & {
            top: rem(75px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                top: rem(72px);
            }
        }

        .html--header-menu-opened & {
            display: block;
        }

        &-inner {
            padding-bottom: rem(70px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(25px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(32px);
            }

            .header--fixed & {
                padding-bottom: rem(24px);
            }
        }

        &-aside {
            @media screen and (min-width: #{breakpoint-min('md')}) {}

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(8px);
            }
        }

        &-content {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(32px);
            }

            &--main {
                margin-top: rem(32px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(10px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: rem(32px);
                }
            }

            &--products {
                margin-bottom: rem(40px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: 0;
                }
            }

            &--press,
            &--vacancy {
                margin-bottom: rem(56px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: 0;
                }
            }

            &-title {
                margin-bottom: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(40px);
                }
            }
        }

        &-nav {
            &--main {
                margin-top: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(40px);
                }
            }

            &-list {
                margin: 0;
                padding: 0;

                &-item {
                    list-style: none;

                    margin-bottom: rem(16px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-bottom: rem(24px);
                    }

                    &--aside {
                        margin-bottom: rem(24px);

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            margin-bottom: rem(32px);
                        }

                        .link.active {
                            color: $eucalyptus;
                            border-bottom: 2px solid $eucalyptus;
                            pointer-events: none;
                        }

                        .link.current {
                            pointer-events: none;
                        }
                    }

                    &--international-first-row {
                        margin-bottom: rem(32px);

                        &:last-of-type {
                            margin-bottom: rem(32px);
                        }

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            min-height: rem(139px);
                            margin-bottom: rem(40px);

                            &:last-of-type {
                                margin-bottom: rem(40px);
                            }
                        }

                        @media screen and (min-width: #{breakpoint-min('xl')}) {
                            min-height: rem(168px);
                        }
                    }
                }
            }

            &-sublist {
                margin: rem(16px) 0 rem(32px) 0;
                padding: 0;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(24px);
                    margin-bottom: rem(40px);
                }

                &--products {
                    margin-bottom: rem(40px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-bottom: rem(64px);
                    }
                }

                &-item {
                    list-style: none;

                    &:not(:last-of-type) {
                        margin-bottom: rem(16px);
                    }

                    &-link {
                        font-size: rem(14px);

                        @media screen and (min-width: #{breakpoint-min('xl')}) {
                            font-size: rem(16px);
                        }

                        &.current {
                            color: #2daa55;
                            pointer-events: none;
                        }
                    }
                }
            }

            &-arrowed-link {
                display: flex;
                align-items: center;
                justify-content: space-between;

                width: 100%;

                &-icon {
                    color: $silverSand;
                    font-size: rem(10px);
                }

                &.current {
                    pointer-events: none;
                }
            }

            &-link {
                font-weight: bold;
                font-family: $foco;
            }
        }

        &-info-link {
            display: flex;
            align-items: center;
            margin-right: auto;
            font-family: $foco;
            font-weight: bold;
            font-size: rem(16px);
            margin-bottom: rem(32px);
            margin-top: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(20px);
                margin-bottom: rem(40px);
                margin-top: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: 0;
                margin-left: rem(-27px);
                margin-top: 0;
            }

            &-icon {
                margin-right: rem(16px);
                font-size: rem(11px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-right: rem(20px);
                }
            }
        }

        &-arrowed-link {
            font-family: $foco;
            font-weight: bold;
            display: inline-flex;
            align-items: center;
            margin-top: auto;

            &--products {
                font-size: rem(16px);
                width: 100%;
                justify-content: space-between;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: auto;
                    font-size: rem(20px);
                }
            }

            &-icon {
                position: relative;
                top: rem(1px);
                margin-left: rem(16px);
                color: $firefly;
                font-size: rem(11px);

                .bx-ie & {
                    position: relative;
                    top: rem(-2px);
                }
            }
        }
    }

    &__press-contacts {
        margin-top: auto;

        &--products {
            margin-top: rem(24px);
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(28px);
            }
        }

        &-name {
            font-family: $foco;
            font-weight: bold;
            font-size: rem(20px);
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(16px);
            }

            &--products {
                font-family: $calibry;
                font-weight: normal;
                font-size: rem(16px);
                margin-bottom: rem(8px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(20px);
                }
            }

            &--mb-24px {
                margin-bottom: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(24px);
                }
            }

            &--small {
                font-size: rem(16px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(20px);
                }
            }
        }

        &-contact {
            &:not(:last-of-type) {
                margin-bottom: rem(12px);
            }

            &-link {
                display: flex;

                &-icon {
                    margin-top: rem(5px);
                    flex-shrink: 0;
                    margin-right: rem(12px);
                    color: $firefly;
                }
            }
        }
    }

    &__vacancy {
        display: block;
        margin-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(48px);
        }

        &-text {
            font-family: $foco;
            font-weight: bold;
            line-height: (24/16);
            font-size: rem(16px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
            }
        }
    }

    &__products {
        margin-bottom: rem(32px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(40px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(48px);
        }

        &-row {
            margin-bottom: rem(-40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(-32px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(-40px);
            }
        }

        &-col {
            padding-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(32px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-bottom: rem(40px);
            }
        }

        &-card {
            &-figure {
                height: rem(153px);

                &-img {
                    height: 100%;
                }
            }

            &-title {
                margin-top: rem(16px);
                font-family: $foco;
                font-size: rem(16px);
                font-weight: bold;
            }

            &-subtitle {
                margin-top: rem(8px);
                font-family: $calibry;
                font-size: rem(14px);
                font-weight: bold;
                letter-spacing: rem(0.8px);
            }
        }
    }

    &__banner {
        height: 100%;
        padding-top: rem(134px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(164px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(185px);
        }

        &-status {
            display: flex;
            flex-direction: column;
            margin-top: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                flex-direction: row;
                margin-top: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(24px);
            }

            .header__banner-header+& {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: rem(-16px);
                }
            }

            &-item {
                font-weight: bold;
                margin-bottom: rem(12px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-right: rem(40px);
                }
            }
        }
    }

    &__banner-header {
        margin-bottom: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(20px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(40px);
        }

        .header--inner & {
            margin-top: rem(50px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(40px);
            }
        }
    }

    &__banner-header--eco {

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-left: 6rem;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-left: 6rem;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-left: 0;
        }
    }

    *+&__banner-subheader {
        margin-bottom: rem(16px);

        .link {
            font-size: rem(18px);
            line-height: 1.1;
            font-weight: 700;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(22px);
            }
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(24px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-top: rem(-20px);
            margin-bottom: rem(40px);
        }
    }

    &__background {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        overflow: hidden;
        display: flex;

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            overflow: hidden;
            display: flex;
            background: linear-gradient(180deg,
                    rgba(0, 0, 0, 0.0001) 0%,
                    rgba(0, 0, 0, 0.457195) 99.01%);
        }

        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            font-family: 'object-fit: cover;';
            object-position: bottom;
        }

        &-video {
            transform: translate(-50%, -50%);
            position: absolute;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            top: 50%;
            left: 50%;
        }

        &--no-overlay {
            &:after {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    display: none;
                }
            }
        }
    }

    &__link {
        color: #fff;
        margin-bottom: 36px;

        span {
            text-decoration: underline;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-left: 6rem;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-left: 6rem;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-left: 0;
        }
    }

    &__top-inner {
        @media screen and (min-width: #{breakpoint-min('md')}) and (max-width: #{breakpoint-min('lg')}) {
            align-items: center;
        }
    }
}

.bx-mac,
.bx-ios {
    .header__background+.container {
        z-index: 2;
    }
}

.inner-controls {

    @media screen and (min-width: #{breakpoint-min('md')}) {
        display: none;
    }

    &__wrapper {
        display: flex;
        padding: 16px 0;
        justify-content: center;
    }

    .header__city--header {
        border-radius: 2rem;
        background-color: #fff;
        padding: 8px 16px 8px 8px;
        margin-right: 16px;
        display: flex;
        align-items: center;
    }

    .header__city-toggler {
        color: #56A75E !important;

        &-text {
            display: block;
        }

        &-icon {
            flex-shrink: 0;
        }
    }

    .header__order-button--header {
        display: block;
        padding: 8px 16px;
        min-width: auto;
        width: 100%;
        max-width: 360px;
        white-space: normal;
    }
}

.white-header {
    .header {

        &__background {
            &::after {
                content: none;
            }

            .w-100 {
                display: none;
            }
        }

        .header__nav-link,
        .link--header,
        .breadcrumbs__breadcrumb--header {
            color: #000;
        }
    }

    .tabs.js-nav-sticky {
        display: none;
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        .tabs.js-nav-sticky {
            display: flex;
        }
    }
}

// вернул удаленные стили, они используются на страницах типо /production/eco/greenone/ (логотип в хероблоке)
.header {
    &__slide {
        &-image {
            position: absolute;
            top: -44px;
            left: -218px;
            max-width: rem(200px);
            width: 100%;
        
            @media screen and (max-width: #{breakpoint-min('xl')}) {
                position: static;
                flex: 0 0 25%;
                min-width: rem(200px);
                margin-right: rem(48px);
                margin-left: 0;
                margin-bottom: rem(32px);
            }
        
            @media screen and (max-width: #{breakpoint-min('md')}) {
                min-width: rem(132px);
                margin-left: 0;
                margin-right: rem(32px);
            }
        
            @media screen and (max-width: #{breakpoint-min('sm')}) {
                max-height: rem(148px);
        
                >img {
                    width: 100%;
                    height: 100%;
                }
            }
        
            &.header__slide-image--eco {
                position: static;
                min-width: rem(200px);
                margin: -40px 0 40px 0;
        
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    position: absolute;
                    left: 18px;
                    top: 75px;
        
                    &+*+.header__banner-header {
                        padding-left: 6rem;
                    }
                }
        
                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    position: absolute;
                    left: 18px;
                    top: 75px;
                }
        
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    position: absolute;
                    left: -200px;
                    top: 40px;
        
                    &+*+.header__banner-header {
                        padding-left: 0;
                    }
                }
        
                & img {
                    width: 85px;
        
                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        width: 100%;
                        max-width: 172px;
                    }
                }
            }
        }
    }
}

