.sustainability {
    &__title {
        font-size: 1rem;
        margin-bottom: 16px;
        font-weight: bold;

        @include breakpoint (mobile) {
            font-size: 1.375rem;
            margin-bottom: 32px;
        }
    }

    &-block-icon {
        @media screen and (min-width: #{breakpoint-min('sm')}) {
            display: flex;
        }

        svg {
            margin-bottom: 8px;

            @media screen and (min-width: #{breakpoint-min('sm')}) {
                flex-shrink: 0;
                margin-bottom: 0;
            }
        }
    }

    &__link {
        font-weight: bold !important;
    }
}

.sustainability-page {
    padding-left: 0;
    padding-right: 0;

    @include breakpoint(v-mobile) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @include breakpoint(tablet) {
        padding-left: 0;
        padding-right: 0;
    }

    .content__circles-outer {
        padding-top: 1.125rem;
        padding-bottom: 1.125rem;
        margin-top: 2.5rem;
        margin-left: auto;
        margin-right: auto;

        @include breakpoint(mobile) {
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;
            margin-top: 0;
            margin-left: auto;
            margin-right: 0;
        }

        @include breakpoint(desktop) {
            padding-top: 2rem;
            padding-bottom: 2rem;
            margin-left: 0;
            margin-right: 0;
        }
    }

    .course-banner {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        @include breakpoint(desktop) {
            height: 100%;
        }
    }

    &__slider {
        margin-left: auto;
        margin-right: auto;
        max-width: 888px;

        @include breakpoint(tablet) {
            max-width: 1296px;
        }

        .circle {
            top: 6.75rem;
            right: 12.5625rem;
            height: 14.875rem;
            width: 14.875rem;

            @include breakpoint(desktop) {
                top: 12.5rem;
                right: 12.8125rem;
                height: 28.625rem;
                width: 28.625rem;
            }
        }
    }

    .rating-card-wrapper {
        max-width: none;

        @include breakpoint(desktop) {
            max-width: 432px;
        }
    }
}

.card-grey {
    background-color: #F3F3F5;
    border-radius: 16px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-icon {
        margin-bottom: 16px;

        svg {
            width: 64px;
            height: 64px;
        }

        &__text {
            color: #2DAA55;
            font-size: 27px;
            font-weight: 700;
            margin-top: 16px;
            line-height: 1.4;
        }
    }

    &__title {
        font-weight: 700;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 1.3;
        max-width: 400px;
    }

    &__text-detail {
        font-size: 14px;
        color: rgba(14, 33, 47, 0.6);
        line-height: 1.7;
    }

    &--row {
        @include breakpoint (mobile) {
            flex-direction: row;
        }

        .card-grey {
            &-icon {
                @include breakpoint (mobile) {
                    margin-right: 24px;
                    margin-bottom: 0;
                }
            }
        }
    }
}

.sustainability-card {
    padding: 24px;
    border: 1px solid #d0d3da;
    border-radius: 3px;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__title {
        color: #2DAA55;
        font-size: 27px;
        font-weight: 700;
        margin-top: 16px;
        line-height: 1.4;
        margin-bottom: 16px;
    }

    &__text {
        font-weight: 700;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 1.3;
    }

    &__text-detail {
        font-size: 14px;
        color: rgba(14, 33, 47, 0.6);
        line-height: 1.7;        
        @include breakpoint (mobile) {
            max-width: 500px;
        }
    }

    &--row {
        @include breakpoint (mobile) {
            flex-direction: row;
        }
    }

    .sustainability-card-icon {
        margin-bottom: 24px;
        @include breakpoint (mobile) {
            margin-right: 24px;
            margin-bottom: 0;
        }
    }
}

.social-responce {
    .border-bottom--none {
        border-bottom: none !important;
    }
    .z-table--theme-green .z-table-col--head {
        font-size: 16px;
    }
}