.bonds {
    $parent: &;

    margin-top: rem(40px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-top: rem(64px);
    }

    &__subnavigation-dropdown {
        margin-bottom: rem(24px);
        display: block;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(64px);
        }
    }

    &__title {
        margin-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(40px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(48px);
        }
    }

    &__text {
        margin-bottom: rem(20px);

        &:last-child {
            margin-bottom: rem(32px);
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(28px);

            &:last-child {
                margin-bottom: rem(48px);
            }
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(32px);
        }
    }

    &__dropdown-wrapper {
        position: relative;
        margin-bottom: rem(64px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(96px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(120px);
        }
    }

    &__subnavigation-outer {
        @media screen and (min-width: #{breakpoint-min('xl')}) {
            position: absolute;
            z-index: 1;
            top: 100%;
            left: 0;
            width: 100%;
            margin-top: rem(25px);
            border-radius: rem(4px);
            box-shadow: rem(15px) rem(15px) rem(45px) rgba(8, 37, 98, 0.1);
            background: $white;
        }
    }

    &__subnavigation-inner {
        padding: rem(24px);
        @media screen and (min-width: #{breakpoint-min('xl')}) {
            display: block;
        }
    }

    &__subnavigation-list {
        max-height: 390px;
        overflow-x: auto;
    }

    &__content-title {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(96px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(120px);
        }
    }

    &__documents {
        padding: 0;
    }

    &__content {
        &--form {
            display: block;
        }

        &--files {
            //display: none;
        }

        .h3 {
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(32px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(40px);
            }
        }

        .h4 {
            margin-bottom: rem(48px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(64px);
            }
        }
    }

    &__email-link {
        color: $eucalyptus;
    }

    &__form-wrapper {
        margin: 0 auto;
        margin-bottom: rem(64px);
        max-width: rem(624px);
        width: 100%;

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-left: 0;
        }
    }

    &__form {
        width: 100%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(64px);
            padding-bottom: rem(80px);
            border: 1px solid $black01;
            border-radius: rem(16px);
        }
    }

    &__form-fieldset {
        display: block;
        margin: 0 auto;
        max-width: rem(400px);
        width: 100%;
    }

    &__form-input {
        margin: 0;
        margin-bottom: rem(32px);
        padding: 0;
        padding-bottom: rem(16px);
        border: 0;
        font-family: $foco;
        font-weight: bold;
        font-size: rem(16px);
        line-height: rem(32px);
        color: $firefly;
        border-bottom: 1px solid $iron;
        width: 100%;

        &::placeholder {
            font-family: $foco;
            font-weight: bold;
            font-size: rem(16px);
            line-height: rem(32px);
            color: $firefly03;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: rem(20px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
        }

        .button {
            margin-bottom: rem(16px);
            max-width: rem(288px);
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                max-width: rem(250px);
                margin-bottom: 0;

                &:last-child {
                    margin-left: rem(20px);
                }
            }
        }
    }

    &-investors {
        #{$parent}__dropdown-wrapper {
            margin-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(48px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(60px);
            }
        }

        .accordion {
            &__button {
                padding-left: rem(23px);

                &::after {
                    right: rem(32px);
                }
            }

            &__article {
                padding: 1.2rem 0;
            }
        }
    }

    table {
        width: 100%;

        thead {
            background-color: #FBF7E5;
        }

        th,
        td {
            vertical-align: top;
            padding-left: 1rem;
            padding-right: 1rem;
            border: 1px solid $iron;

            &:not(:first-of-type) {
                padding-left: 1rem;
            }

            &:not(:last-of-type) {
                padding-right: 1rem;
            }

            pointer-events: none;
        }

        th {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }

        td {
            min-width: 5.25rem;
            padding-top: 1rem;
            padding-bottom: 1rem;

            &:first-child {
                color: #0e212f;
            }
        }
    }
}
