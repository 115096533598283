.quotation {
    position: relative;
    background: $congressBlue01;
    border-radius: rem(3px);
    padding: rem(45px) rem(15px);
    font-size: rem(14px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding: rem(80px) rem(30px) rem(110px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding: rem(90px) rem(110px) rem(160px);
    }

    &__title {
        margin-bottom: rem(20px);
    }

    &__icon {
        position: absolute;
        top: rem(1px);
        transform: translateY(-50%);
        left: rem(16px);
        width: rem(30px);
        height: rem(27px);
    }

    &__wrap {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: flex;
            flex-direction: row;
        }
    }

    &__photo {
        width: rem(125px);
        height: rem(185px);

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            width: rem(170px);
            height: rem(255px);
        }

        &-wrap {
            position: relative;
            width: rem(125px);
            height: rem(185px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-right: rem(65px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: rem(170px);
                height: rem(255px);
            }
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    &__job {
        font-weight: bold;
        font-size: rem(14px);
        text-transform: uppercase;
        color: $firefly03;
    }
}
