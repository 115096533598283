.product-info-cards {
    margin-bottom: rem(48px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-bottom: rem(48px);
    }

    &__list {
        margin-top: rem(-32px);
        margin-bottom: rem(-32px);
        padding-left: 0;
        list-style: none;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(-40px);
            margin-bottom: rem(-40px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-top: rem(-64px);
            margin-bottom: rem(-64px);
        }
    }

    &__item {
        margin-top: rem(32px);
        margin-bottom: rem(32px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(40px);
            margin-bottom: rem(40px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-top: rem(40px);
            margin-bottom: rem(48px);
        }
    }
}