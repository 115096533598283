.greenlabel {
    &__icon-play {
        width: 40px;
        height: 40px;
        margin-right: 13px;
    }

    &__br {
        margin: 2rem 0 !important;
        height: 2px;
        width: 100%;
        background-color: $eucalyptus;

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin: 5rem 0 !important;
        }

        &--smaller {
            width: 90%;
            margin: 2rem auto !important;
            height: 1px;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin: 5rem auto !important;
            }
        }
    }

    &-info__card {
        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-right: 6rem;
        }
    }

    &__img-crl {
        width: 233px;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 332px;
        }
    }

    &__img-sm {
        width: 100%;
    }

    &__title {
        font-size: rem(12px);
        color: $firefly;
        opacity: 0.4;
        margin-bottom: rem(24px);
        text-transform: uppercase;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 26rem;
            text-align: left;
        }
    }

    &__info {
        margin-top: rem(69px);
    }

    &__figure {
        display: flex;
        justify-content: flex-end;
        flex-direction: row-reverse;
        flex: 0.4;
        order: 1;
        margin-bottom: 2rem;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-direction: column-reverse;
            justify-content: flex-end;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;

        &-text {
            order: 2;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                flex: 0.9;
            }
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-direction: row-reverse;
            justify-content: space-between;
        }
    }

    &__element {
        display: flex;
        justify-content: center;
        order: 1;
        width: 7.75rem;
        height: 7.75rem;
        align-items: center;
        border-radius: 50%;
        background-color: rgba($black, 0.07);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: rem(154px);
            height: rem(154px);
            order: 2;
            margin: 0 auto;
        }

        &-info {
            text-align: center;
        }

        &-name {
            font-size: rem(48px);
            font-weight: bold;
            line-height: 1.2;
        }

        &-numb {
            line-height: 0.3;
        }

        &-mass {
            padding-top: rem(9px);
        }
    }

    &__count {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        padding-left: 2rem;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-direction: row;
        }

        &-title {
            display: block;
            font-size: rem(12px);
            color: #979797;
        }

        &-amount {
            display: block;
            font-weight: bold;
            font-size: rem(14px);
        }
    }

    &__card-title {
        margin-bottom: 1.0625rem;
        font-size: 1.3rem;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: 2rem;
        }
    }

    &__info-text {
        font-family: Foco;
        font-weight: bold;
        font-style: normal;
        font-size: 0.85rem;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(20px);
        }
    }

    &__img-align {
        text-align: center;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            text-align: left;
        }
    }

    &__size {
        color: $grayChateau;
        font-size: rem(12px);
        font-weight: 400;
        text-transform: uppercase;
    }

    &__link {
        color: $eucalyptus;
        font-weight: 400;
    }

    &__label {
        font-family: Foco;
        font-style: normal;
        font-weight: 700;
        font-size: rem(20px);
        margin-bottom: 1rem;
        line-height: 1.21429;
        letter-spacing: 0.05714em;
        color: $firefly;
        opacity: 0.4;
    }

    &__main-text {
        font-size: 1rem;
        font-weight: 700;
        font-style: normal;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: 1.25rem;
        }
    }

    &__fr-card {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-left: rem(112px);
        }
    }

    &-pb {
        @media screen and (max-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(40px);
        }
    }

    &-mt {
        margin-right: 0;

        @media screen and (max-width: #{breakpoint-min('md')}) {
            margin-top: 0;
        }
    }
}
