.product-info-card {
    $parent: &;

    display: block; // ie image overflow bugfix
    padding: rem(5px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        display: flex;
        flex-direction: row;
    }

    &:hover &__title {
        color: $eucalyptus;
        cursor: pointer;
    }

    &--heading {
        #{$parent}__figure {
            margin-left: 0;
            margin-right: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-right: rem(40px);
            }
        }

        #{$parent}__content {
            max-width: 357px;
        }
    }

    &--gray-color {
        background: rgba(47, 80, 147, 0.1);
        padding: 40px;
        border-radius: 10px;
        align-items: center;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            .product-info-card__figure {
                max-width: 50%;
            }
        }

    }

    &__figure {
        margin-left: auto;
        margin-right: auto;
        flex-shrink: 0;
        max-width: 40%;
        margin-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-left: 0;
            margin-right: rem(32px);
            max-width: rem(116px);
            margin-bottom: 0;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            max-width: rem(152px);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1; // ie-11 content overflow fix
    }

    &__header {
        margin-bottom: rem(16px);

        @media screen and (max-width: #{breakpoint-min('md')}) {
            margin-bottom: 0;
        }

        /*@media screen and (min-width: #{breakpoint-min('lg')}) {
      margin-bottom: rem(24px);
    }*/
    }

    &__footer {
        margin-top: auto;
    }

    &__image {
        max-width: 100%;
    }

    &__text {
        margin-bottom: rem(24px);
        font-weight: normal;

        @media screen and (max-width: #{breakpoint-min('md')}) {
            margin-bottom: 0;
        }
    }

    &__subtitle,
    &__type {
        font-size: rem(14px);
        font-weight: bold;
        letter-spacing: rem(0.8px);
        text-transform: uppercase;
        color: rgba($firefly, 0.4);
    }

    &__subtitle {
        color: $firefly;
        text-transform: none;

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            font-family: $foco;
            font-size: rem(20px);
            line-height: rem(32px);
        }
    }

    &--slider {
        min-height: 405px;
    }
}

.product-info-card--phosphogypsum {
    &__figure {
        max-width: 100%;

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            max-width: 40%;
        }
    }
}
