.spoiler {
    &__container {
        overflow: hidden;
        transition: height 400ms ease-in-out;
    }

    &__toggle {
        padding: 0;
        font-size: rem(16px);
        line-height: (20 / 16);
        color: $eucalyptus;
        border: 0;
        border-bottom: rem(1px) dashed currentColor;
        background-color: transparent;
        cursor: pointer;

        &:hover {
            color: $eucalyptus1;
        }
    }
}

.js-spoiler-container {
    overflow: hidden;
    transition: height 400ms ease-in-out;
}

.js-spoiler-toggle-icon {
    transition: transform 400ms ease-in-out;

    &.expanded  svg {
        transform: rotate(180deg)
    }
}