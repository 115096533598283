.accordion {
    font-family: Foco;
    &__section {
        &:last-child {
            border-bottom: 1px solid #eaecec;
        }
    }
    &__button {
        position: relative;
        display: block;
        padding: 24px 4rem 24px 24px;
        background-color: white;
        color: #9fa6ac;
        font-size: 1.125rem;
        font-weight: 300;
        text-decoration: none;
        line-height: 1.72;
        border-top: 1px solid #eaecec;

        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.25px;
        font-weight: bold;

        &:after {
            // @include svg(100%, 100%, "icon_link-arrow", $gpn-silver);
            display: block;
            margin: auto;
            position: absolute;
            top: 50%;
            transform: translateY(-11px);
            right: 3rem;
            bottom: 0;
            content: url(/local/templates/.default/img/icons/plu-open-accordeon.svg);

            @media (max-width: 768px) {
                right: 1rem;
            }
        }

        &:hover {
            color: #0e212f;
            &:after {
                content: url(/assets/icon-font/svg/plu-open.svg);
            }
        }

        &.is-expand {
            color: #0e212f;

            &:after {
                // @include svg(100%, 100%, "icon_link-arrow", $gpn-grey);
                content: url(/assets/icon-font/svg/min-close.svg);
                right: calc(3rem + 3px);
                transform: translateY(-20px);

                @media (max-width: 768px) {
                    right: calc(1rem + 3px);
                }
            }
        }
    }

    &__article {
        display: none;
        padding: 1.2rem;
        padding-left: 0;

        font-size: 16px;
        line-height: 24px;

        &.is-expand {
            display: block;
            overflow: auto;

            .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
}

.tabs {
    display: flex;
    flex-flow: column nowrap;

    &__print-title {
        display: none;
    }

    &__container {
        overflow: hidden;
        overflow-x: auto;
        display: flex;
        z-index: 1;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex: 1 1 100%;
        flex-flow: row nowrap;
        border-bottom: 1px solid palevioletred;
    }

    &__button {
        display: block;
        padding: 0.5rem 1rem;
        position: relative;
        color: silver;
        font-size: 1rem;
        font-weight: 500;
        text-decoration: none;
        line-height: 1.4;
        transition: color 0.3s;

        @media only screen and (max-width: 768px) {
            white-space: nowrap;
        }

        &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: -1.5px;
            display: block;
            width: 0;
            height: 2px;
            background-color: skyblue;
            margin: auto;
            content: '';
            transition: width 0.4s;
        }

        &:not(:last-of-type) {
            margin: 0 1rem 0 0;
        }

        &:hover {
            color: grey;
        }

        &.is-expand {
            color: black;
        }

        &.is-expand {
            &:after {
                width: 100%;
            }
        }

        &--right {
            margin-left: auto;
        }
    }

    &__article {
        display: none;
        padding: 1.5rem;

        &.is-expand {
            display: block;
        }
    }
}

@media only screen and (max-width: 768px) {
    .accordion {
        &__button {
            font-size: 1rem;
            padding: 0.6875rem 2.5rem 1rem 0.875rem;
        }

        &__article {
            padding: 1rem;
        }
    }
}
.navigation--fixed {
    @media screen and (max-width: 1391px) {
            position: fixed;
            top: 72px;
            left: 0;
            right: 0;
            z-index: 99;
            animation: headerSlideIn .3s ease-in-out 1;
    }
    @keyframes headerSlideIn {
        from {
            transform: translateY(-100%);
        }
        to {
            transform: translateY(0);
        }
    }
}