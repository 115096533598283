.news {
    &-card {
        display: block;

        &--header {
            margin-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
            }
        }

        &--gallery {
            flex-basis: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: rem(48px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(54px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(80px);
            }
        }

        &--press-first-row {
            /*margin-bottom: rem(32px);

      &:last-of-type {
        margin-bottom: rem(32px);
      }*/

            @media screen and (min-width: #{breakpoint-min('md')}) {
                min-height: rem(216px);
                margin-bottom: rem(40px);

                &:last-of-type {
                    margin-bottom: rem(40px);
                }
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                min-height: rem(224px);
            }
        }

        &__photo {
            width: 100%;
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                height: rem(192px);
            }

            &-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                font-family: 'object-fit: cover;';
            }
        }

        &__title {
            margin-bottom: rem(16px);
        }

        &__news {
            font-family: $foco;
            font-weight: bold;
            line-height: (24/16);
            font-size: rem(16px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
            }
        }

        &__date {
            font-family: $foco;
            font-weight: bold;
            font-size: rem(16px);
            color: $silverSand;
            margin-top: rem(16px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
            }
        }

        &__info {
            margin-top: rem(32px);
            font-size: rem(14px);
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
            color: $silverSand;
        }
    }
}
