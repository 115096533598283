.calendar {
    margin: 0 auto;

    padding-bottom: rem(100px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding-bottom: rem(110px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding-bottom: rem(190px);
    }

    &--products-page {
        padding-bottom: rem(64px);

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-bottom: rem(100px);
        }
    }

    &--products-page &__calendar-title {
        margin-bottom: rem(48px);
    }

    &--products-carousel {
        margin-bottom: rem(64px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(96px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-bottom: rem(120px);
        }
    }

    &--products-other {
        .owl-carousel .owl-stage-outer {
            overflow: visible;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            .product-carousel {
                padding: 16px 52px 16px 16px;
                margin: -16px -52px 5rem -16px;
            }
        }
    }

    &__calendar {
        display: flex;
        flex-direction: column;

        margin-bottom: rem(90px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: 0;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(60px);
        }

        &--padding-top-none {
            padding-top: 0;
        }

        &-title {
            font-size: rem(14px);
            font-weight: bold;
            line-height: 1.71;
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
            margin-bottom: rem(48px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(64px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(80px);
            }
        }

        &-slider {
            margin-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(64px);
            }

            margin-left: rem(-24px);
            margin-right: rem(-24px);

            width: auto;
        }

        &-slide {
            padding-left: rem(24px);
            padding-right: rem(72px);

            font-family: $foco;
            font-size: rem(20px);
            font-weight: bold;

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-top: rem(20px);
                padding-bottom: rem(25px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-right: rem(24px);
            }
        }

        &--invest {
            .calendar-page__card-label--invest,
            .calendar-page__card-inner-text,
            .calendar-page__card-date-text {
                font-weight: normal;
            }
        }

        &-date {
            font-size: rem(14px);
            color: $silverSand;
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding: rem(20px) 0 0 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
            }
        }

        &-event {
            font-size: rem(16px);
            line-height: (28/16);

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding: 0 0 rem(20px) 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
                line-height: (32/20);
            }
        }

        &-controls {
            display: flex;
            flex-direction: column-reverse;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }

            &-slider {
                display: flex;
                align-items: center;

                margin-bottom: rem(50px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(70px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: 0;
                    width: 47%;
                }

                &--full-tablet-down {
                    width: 100%;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-left: rem(48px);
                    }

                    @media screen and (min-width: #{breakpoint-min('lg')}) {
                        width: auto;
                    }
                }
            }

            &-num {
                &-container {
                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        display: none;
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        display: block;
                    }
                }
            }
        }

        &-button {
            min-width: rem(288px);
            width: 100%;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: auto;
            }
        }

        &-timelapse {
            margin-left: rem(24px);
            margin-right: rem(24px);
            flex-grow: 1;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: none;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-right: rem(48px);
                display: block;
            }

            &--size-fixed {
                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    width: rem(150px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: rem(250px);
                }
            }
        }

        &-arrows {
            display: flex;

            &.disabled {
                display: none;

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: block;
                }
            }
        }
    }

    &__report {
        height: rem(400px);
        width: rem(400px);
        position: relative;
        flex-shrink: 0;
        left: 50%;
        transform: translateX(-50%);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            height: rem(400px);
            width: 100%;
            left: 0;
            transform: none;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            height: rem(512px);
        }

        &-inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;

            position: relative;
            z-index: 2;
            border-radius: 50%;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(400px);
                left: rem(24px);

                position: absolute;

                &:hover {
                    box-shadow: 2rem 2rem 3rem 20px rgba(8, 37, 98, 0.1);
                    transition: 0.4s;
                }
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: 100%;

                position: relative;
                left: rem(24px);
            }
        }

        &-title {
            font-size: rem(14px);
            font-weight: bold;
            line-height: 1.71;
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
            margin-bottom: rem(20px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(72px);
            }
        }

        &-year {
            font-family: $foco;
            font-size: rem(104px);
            font-weight: bold;
            line-height: 1;
            margin-bottom: rem(20px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(136px);
                margin-bottom: rem(62px);
            }
        }

        &-type {
            font-size: rem(14px);
            font-weight: bold;
            line-height: 1.71;
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
        }

        &-circle {
            position: absolute;

            &--main,
            &--first,
            &--second,
            &--third {
                top: 0;
                left: 0;

                height: rem(400px);
                width: rem(400px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    left: rem(24px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    height: rem(512px);
                    width: rem(512px);
                }
            }

            &--first,
            &--second,
            &--third {
                background-color: $glacier;
            }
        }
    }
}
