.exchange-rate {
    &__main-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__top {
        display: flex;
        align-items: baseline;
    }

    &__date {
        font-size: 12px;
        color: #858C92;
        font-weight: 400;
        margin-left: 67px;
    }

    &__line {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-bottom: rem(32px);
        font-family: 'Foco';

        &--links {
            margin-top: -1em;
            padding-bottom: 0;

            .exchange-rate__link-block:first-child {
                display: none;
            }
        }
    }

    &__rate {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &-whole {
            font-size: rem(64px);
            line-height: rem(72px);
            font-weight: bold;
            padding-right: rem(16px);
        }
    }

    &__persentage {
        font-size: rem(16px);
        line-height: rem(24px);
        letter-spacing: rem(0.25px);
        font-weight: bold;

        &--green {
            color: $eucalyptus;
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
    }

    &__name {
        font-size: rem(20px);
        line-height: rem(32px);
        letter-spacing: rem(0.25px);
        font-weight: bold;
    }

    &__link {
        font-size: rem(20px);
        line-height: rem(25px);
        color: $eucalyptus;
        font-weight: bold;
        display: block;
    }

    &__image {
        max-width: rem(180px);

        &-wrapper {
            display: none;
        }
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        &__line {
            padding-bottom: rem(40px);

            &-wrapper {
                display: flex;
                .exchange-rate__line {
                    width: 50%;
                }
            }

            &--links {
                margin-top: 0;
                padding-bottom: 0;

                .exchange-rate__links {
                    display: flex;
                    flex-direction: row-reverse;
                    width: 100%;
                    align-items: center;
                }

                .exchange-rate__link-block:first-child {
                    display: block;
                }
            }
        }

        &__link {
            &-block {
                width: 50%;
            }
        }

        &__image {
            &-wrapper {
                display: block;
            }
        }
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        &__line {
            padding-bottom: 0;
        }

        &__links {
            padding-top: 0;
        }

        &__main-wrapper {
            flex-direction: row;

            .exchange-rate__line-wrapper {
                width: 76%;

                &--stock {
                    width: 100%;
                }
            }

            .exchange-rate__line--links {
                width: 24%;
            }
        }

        &__name {
            margin-bottom: rem(16px);
        }

        &__line {
            &--links {
                .exchange-rate__links {
                    display: flex;
                    flex-direction: column;
                    height: 100%;
                    justify-content: space-around;

                    .exchange-rate__link-block {
                        width: auto;
                    }
                }
            }
        }
    }
}
