.product-details-pills {
    &__group {
        display: flex;
        flex-wrap: wrap;
        margin: rem(-8px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin: rem(-12px);
        }
    }

    &__subgroup {
        display: flex;
        flex-direction: column;
        margin-right: calc(64px - 0.75rem);

        &:last-child {
        margin-right: 0;
        }
    }

    &__img {
        max-width: 42px;
        margin: 0.75rem;
    }

    &__item {
        display: inline-flex;
        flex-shrink: 0;
        margin: rem(8px);
        padding: rem(16px) rem(8px);
        border-radius: rem(8px);
        background-color: rgba($congressBlue, 0.1);
        min-width: rem(39px);
        transition: background-color 0.3s ease, color 0.3s ease;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin: rem(12px);
            padding: rem(16px);
        }

        &:hover,
        &:focus,
        &:active {
            background-color: rgba($congressBlue, 0.4);
            color: $firefly;
            box-shadow: 0 rem(5px) rem(15px) rgba(0, 0, 0, 0.1);
        }

        &:last-child {
        margin-right: 0;
        }

        &[href$='#'] {
            pointer-events: none;
        }

        &-header,
        &-content {
            display: block;
        }

        &-header {
            margin-bottom: rem(16px);
            font-family: $foco;
            font-size: rem(20px);
            font-weight: bold;
            line-height: (24 / 16);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(24px);
            }
        }

        &-content {
            font-size: rem(14px);
            line-height: (16 / 14);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(16px);
                letter-spacing: rem(0.2px);
            }
        }
    }

    &__subitem {
        & + & {
            margin-left: rem(12px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-left: rem(24px);
            }
        }
    }
}
