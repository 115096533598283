.top-news {
    max-width: rem(1472px);
    margin: 0 auto;
    padding-top: rem(50px);
    padding-bottom: rem(132px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding-top: rem(64px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding-top: rem(80px);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: rem(48px);

        &-title {
            font-size: rem(14px);
            font-weight: bold;
            line-height: 1.71;
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
        }
    }

    &__cards {
        &-col {
            padding-top: rem(40px);
            padding-bottom: rem(40px);
            transition: 0.3s ease box-shadow;

            &:last-of-type {
                .top-news__card {
                    border-right: none;

                    &:before {
                        right: rem(-16px);
                        @media screen and (min-width: #{breakpoint-min('xl')}) {
                            right: rem(-48px);
                        }
                    }
                }
            }

            &--second {
                .top-news__card {
                    border-right: none;

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        &:before {
                            right: rem(-48px);
                        }
                    }
                    // &:before {
                    //     right: rem(-48px);
                    // }
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    .top-news__card {
                        border-right: rem(1px) solid $silverSand;

                        &:before {
                            right: 0;
                        }
                    }
                }
            }

            &--main {
            }

            &:hover {
                .top-news__card {
                    border-right-color: transparent;

                    &:before {
                        box-shadow: rem(15px) rem(15px) rem(45px) 0
                            rgba(8, 37, 98, 0.1);
                        opacity: 1;
                        transform: scale(1.01);
                    }
                }
            }
        }
    }

    &__card {
        height: 100%;
        min-height: rem(290px);
        border-right: rem(1px) solid $silverSand;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            top: rem(-40px);
            bottom: rem(-40px);
            left: rem(-32px);
            right: 0;
            background: $white;
            transition: all 0.3s ease;
            opacity: 0;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                left: rem(-48px);
            }
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            min-height: rem(420px);
        }

        &-inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            word-break: break-word;
            max-width: rem(288px);
            position: relative;
            z-index: 1;

            &--main {
                max-width: rem(288px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    max-width: rem(336px);
                }
            }
        }

        &-date {
            font-family: $foco;
            font-size: rem(16px);
            font-weight: bold;
            line-height: 0.6;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
            }

            &-num {
                line-height: 1;

                &--big {
                    line-height: 1;
                    font-size: rem(60px);

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(136px);
                    }
                }
            }
        }

        &--video {
            &:after {
                content: '';
                display: block;
                width: 1.5rem;
                height: 1.5rem;
                position: absolute;
                right: 3rem;
                bottom: 0;
                z-index: 1;
                background-repeat: no-repeat;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iMTkuNSIgaGVpZ2h0PSIxNiIgc3Ryb2tlPSIjMEUyMTJGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48cGF0aCBkPSJNMjEuNSAyMS42MjVIMiIgc3Ryb2tlPSIjMEUyMTJGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPjxwYXRoIGQ9Ik0xNC4zNDggMTBMOS4xNTE4NiAxM0w5LjE1MTg2IDdMMTQuMzQ4IDEwWiIgc3Ryb2tlPSIjMEUyMTJGIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz48bWFzayBpZD0icGF0aC01LWluc2lkZS0xIiBmaWxsPSJ3aGl0ZSI+PHJlY3QgeD0iMy43NSIgeT0iMTkuNjg3NSIgd2lkdGg9IjMiIGhlaWdodD0iNCIgcng9IjEiLz48L21hc2s+PHJlY3QgeD0iMy43NSIgeT0iMTkuNjg3NSIgd2lkdGg9IjMiIGhlaWdodD0iNCIgcng9IjEiIHN0cm9rZT0iIzBFMjEyRiIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIG1hc2s9InVybCgjcGF0aC01LWluc2lkZS0xKSIvPjwvc3ZnPg==);

                @media only screen and (max-width: 1024px) {
                    right: 0;
                }
            }
        }
    }

    &__slider {
        margin-bottom: rem(48px);

        &-controls {
            display: flex;
            align-items: center;
            margin-bottom: rem(48px);
        }

        &-date {
            font-family: $foco;
            font-weight: bold;
        }

        &-arrows {
            display: flex;
        }
    }

    &__slide {
        &-title {
            margin-bottom: rem(24px);
        }
    }

    &__timelapse {
        flex-grow: 1;
        margin-left: rem(24px);
        margin-right: rem(24px);
    }
}
