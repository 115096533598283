.about-top {
    .tabs__container {
        &--scroll {
            display: none;
            width: 100%;
            position: relative;
            z-index: 1;
            
            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: flex;
            }
        }

        &--select {
            display: block;
            padding: 48px 16px 32px;

            @media screen and (min-width: #{breakpoint-min('sm')}) {
                padding: 48px 32px 32px;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: none;
            }
        }
    }
}