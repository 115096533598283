.phosphorus-101 {
    &__lead {
        font-weight: bold;
        font-size: 1.5rem;
        line-height: 2rem;
        color: #000000;
        max-width: 70rem;
    }

    &__card {
        min-height: 18rem;
        height: 18rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 4rem;

        &-info {
            height: 11rem;
            max-width: 25rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &-description {
            font-family: Foco;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.25px;
            color: #0e212f;
        }

        &-sub {
            font-family: Foco;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 32px;
            letter-spacing: 0.25px;
            color: #0e212f;
            opacity: 0.3;
        }
    }
}
