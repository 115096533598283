.invest {
    &__slider {
        .calendar__calendar-slide.link.link--black.calendar-page__card.calendar-page-detail__similar-slider-item {
            width: 99%;
            margin-left: rem(4px);
        }
        .owl-stage {
            padding-bottom: rem(20px);
        }
    }
}
