.product-documents {
    margin-bottom: rem(32px);

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        margin-bottom: rem(128px);
    }

    &__list {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    &__item {
        &:not(:last-of-type) {
            margin-bottom: rem(48px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(72px);
            }
        }
    }
}
