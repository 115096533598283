.ordered-list {
    counter-reset: myCounter;
    padding-left: rem(10px);

    li {
        margin-top: 1.5rem;

        &:first-child {
            margin-top: 0;
        }
    }

    &__item {
        list-style: none;
        position: relative;
        padding-left: rem(25px);

        &:before {
            counter-increment: myCounter;
            content: counter(myCounter) '.';
            color: $congressBlue;
            display: inline-block;
            font-weight: 700;
            text-align: center;
            position: absolute;
            left: rem(-10px);
        }
    }
}

.unordered-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: rem(10px);

    &--column & {
        &__item {
            width: 100%;
        }
    }

    &--bulleted & {
        &__item {
            padding-left: rem(8px);
            padding-right: 0;

            &::before {
                top: rem(8px);
                width: rem(8px);
                height: rem(8px);
                border-radius: 50%;
                background-color: $congressBlue08;
            }
        }
    }

    &--leaf {
        padding-left: 0;
    }

    &--leaf & {
        &__item {
            background-color: #EAF7EE;
            padding: 2rem 2rem 2rem 4.5rem;

            &:before {
                width: 1.5rem;
                height: 1.5rem;
                top: 2rem;
                left: 2rem;
                background-color: transparent;
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21.8311 2C21.8311 2 21.1204 7.65719 12.0998 6.10217C7.19846 5.25673 1.83975 7.60522 2.00367 16.5245C3.88023 13.7599 8.65275 8.06237 15.0633 10.0978C15.0633 10.0978 8.70357 8.94265 3.13008 18.2647C5.59987 20.359 11.7568 24.5853 16.7864 19.8197C23.4303 13.5223 21.8311 2 21.8311 2Z' fill='%23018FA1'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: contain;
            }

            + .unordered-list__item {
                padding-top: 0;

                &:before {
                    top: 0;
                }
            }
        }
    }

    &__item {
        list-style: none;
        position: relative;
        padding-left: rem(25px);
        padding-right: 5%;
        width: 45%;
        font-size: rem(14px);

        &:before {
            content: '';
            background-color: $deepCerulean;
            display: inline-block;
            position: absolute;
            top: rem(7px);
            left: rem(-10px);
            height: rem(14px);
            width: rem(14px);
        }
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        &__item {
            font-size: rem(16px);
        }
    }
}

.content-list {
    list-style-type: none;
    width: 100%;

    li {
        position: relative;
        margin: 1rem 0;
        color: #0e212f;

        &:first-child {
            margin-top: 0;
        }

        &::before {
            content: '';
            position: absolute;
            width: 0.6875em;
            height: 0.6875em;
            background-color: #018fa1;
            left: -2em;
            top: 0.35em;
        }
    }

    li.content-list__bullet-small::before {
        width: 0.5em;
        height: 0.5em;
    }
    
    &--ol {
        padding-inline-start: 1.25em;
    
        li:before {
            display: none;
        }
    
        li {
            list-style-type: decimal;
            padding-left: 18px;
    
            &::marker {
                font-size: 14px;
    
                @include breakpoint (mobile) {
                    font-size: 16px;
                }
            }
        }
    }
}