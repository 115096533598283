.main-page {
    .circles {
        position: absolute;
        right: auto;
        left: auto;

        @media screen and (max-width: rem(767px)) {
            position: relative;
            margin-top: rem(64px);
            margin-bottom: rem(64px);
        }

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            margin-left: auto;
            margin-right: auto;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            right: 2rem;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            right: 4rem;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            right: 10rem;
        }

        &__center {
            line-height: 1;
            font-size: 3rem;

            &:before {
                content: 'No.';
                color: $firefly;
                font-size: 1rem;
                font-weight: 700;
                line-height: 1.25rem;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: 6.5rem;
            }
        }

        &__center--rs {
            &:before {
                content: 'Br.';
            }
        }

        &__center--ro {
            &:before {
                content: 'Nr.';
            }
        }

        &__text {
            font-weight: 700;
            color: $eucalyptus;
        }

        &__content {
            align-items: center;

            @media screen and (max-width: #{breakpoint-min('sm')}) {
                padding: 0 2rem;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                align-items: flex-start;
                padding-left: 14rem;
                padding-right: 2rem;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-left: 32rem;
                padding-right: 2rem;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-left: 34rem;
                padding-right: 6rem;
            }
        }
    }

    .unordered-list {
        max-width: 100%;

        &__item {
            font-size: 0.75rem;
        }
    }

    &__benefits {
        &-image {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 6rem;
        }
    }
}

// For stocks hack iframe mainpage and investors

.stocks-wrapper {
    position: relative;
    overflow: hidden;
}

.stocks-wrapper--investors {
    width: 100%;
    height: 165px !important;

    @media (max-width: 1384px) {
        height: auto !important;
    }
}

.stocks-wrapper--mainpage {
    height: 310px;
    max-width: 350px;
    
    iframe {
        max-width: 100%;
        width: 100%;
        border: none;
    }

    .cbonds-iframe {
        width: 100%;
        height: 1300px;
        border: 0;
    }
}

@media (max-width: 1024px) {
    .stocks-wrapper--mainpage {
        width: 250px;
        height: 324px;
    }
}

@media (max-width: 1023px) {
    .stocks-wrapper--mainpage {
        margin-left: auto;
        margin-right: auto;
    }
}

