.subscribe {
    max-width: rem(704px);
    padding-bottom: rem(65px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding-bottom: rem(110px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding-bottom: rem(160px);
    }

    &--full-width {
        max-width: 100%;
    }

    &--press-detail {
        margin: 0;
        padding-bottom: rem(65px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(96px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(160px);
        }
    }

    &__title {
        margin-bottom: rem(30px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(45px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(60px);
        }
    }

    &__form {
        display: flex;
        align-items: center;

        &-input {
            font-family: $foco;
            font-size: rem(14px);
            font-weight: bold;

            border-left: none;
            border-right: none;
            border-top: none;
            border-bottom: rem(1px) solid $silverSand;

            padding-top: rem(10px);
            padding-bottom: rem(15px);
            margin-right: rem(25px);

            flex-grow: 1;
            flex-basis: 0%;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: 0;
                font-size: rem(20px);
            }

            &::placeholder {
                color: $silverSand;
            }

            &:focus {
                outline: none;
            }
        }

        &-submit {
            color: $white;
            border: none;
            font-size: rem(6px);

            background: $eucalyptus;
            border-radius: 50%;

            width: rem(48px);
            height: rem(48px);

            flex-shrink: 0;

            position: relative;
            top: 0;

            transition: all 0.15s ease-in-out;

            &:hover {
                top: rem(-5px);
            }

            &:active {
                background: $eucalyptus1;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(8px);

                width: rem(64px);
                height: rem(64px);
            }
        }
    }
}
