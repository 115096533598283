.concern {
    &-card {
        display: block;

        &-row {
            margin-top: rem(-20px);
            margin-bottom: rem(-20px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: rem(-24px);
                margin-bottom: rem(-24px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(-41px);
                margin-bottom: rem(-41px);
            }

            &--gallery {
                margin-top: rem(-24px);
                margin-bottom: rem(-24px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: rem(-40px);
                    margin-bottom: rem(-40px);
                }
            }
        }

        &-col {
            padding-top: rem(20px);
            padding-bottom: rem(20px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(24px);
                padding-bottom: rem(24px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(41px);
                padding-bottom: rem(41px);
            }

            &--gallery {
                padding-top: rem(24px);
                padding-bottom: rem(24px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    padding-top: rem(40px);
                    padding-bottom: rem(40px);
                }
            }
        }

        &--header {
            margin-top: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(56px);
            }
        }

        &__figure {
            width: 100%;
            margin-bottom: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(8px);
            }

            &--company {
                margin-bottom: rem(16px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(24px);
                }
            }

            &--gallery {
                margin-bottom: rem(16px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(32px);
                }
            }

            &-img {
                width: 100%;
            }
        }

        &__type {
            font-size: rem(12px);
            font-weight: bold;
            line-height: 2;
            letter-spacing: rem(0.6px);
            text-transform: uppercase;
            color: $silverSand;
            margin-bottom: rem(6px);

            &--company {
                margin-bottom: rem(8px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(14px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(16px);
                }
            }
        }

        &__name {
            font-size: rem(16px);
            font-weight: bold;
            line-height: (24/16);

            &--company {
                font-size: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(24px);
                    line-height: (32/24);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(32px);
                    line-height: (40/32);
                }
            }

            &--gallery {
                font-size: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(24px);
                    line-height: (32/24);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(32px);
                    line-height: (40/32);
                }
            }
        }
    }
}
