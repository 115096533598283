.products {
    &__benefits {
        &-image {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 6rem;
        }
    }

    &__block {
        padding: 2.5rem 2rem;
        color: $firefly;
        background-color: $white;
        transition: box-shadow 0.3s ease;

        &:hover {
            opacity: 1;
            color: $firefly;
            box-shadow: 0.9375rem 0.9375rem 2.8125rem 0 rgba(8, 37, 98, 0.1);
        }
    }

    &__background--hack {
        @media screen and (max-width: 768px) {
            background: white;
            z-index: 2;
        }
    }
}
