.oil-products {
    &__container {
        padding: rem(20px) 0 rem(65px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: rem(45px) 0 rem(80px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding: rem(80px) 0 rem(125px);
        }
    }

    &__subnavigation {
        margin-bottom: rem(50px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(80px);
        }
    }

    &__title {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(55px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(80px);
        }
    }

    &__select-wrap {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(55px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(80px);
            width: rem(510px);
        }
    }

    &__table {
        font-size: rem(14px);

        &-header {
            position: relative;
            padding-bottom: rem(15px);
            font-size: rem(12px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(20px);
                font-size: rem(14px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-bottom: rem(25px);
                font-size: rem(16px);
            }

            &:after {
                @include line();
                width: 94%;
                left: rem(8px);
                bottom: 0;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    left: rem(24px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: 95%;
                }
            }

            &-date {
                text-align: right;
            }
        }

        &-row {
            margin-top: rem(30px);
            margin-bottom: rem(30px);
            font-weight: bold;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(16px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
            }

            a:hover,
            a:focus {
                color: $eucalyptus;
                cursor: pointer;
            }
        }

        &-date {
            color: $grayChateau;
            font-weight: normal;
            text-align: right;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(14px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(16px);
            }
        }
    }

    .select2-container--default
        .select2-selection--single
        .select2-selection__rendered {
        font-weight: bold;
    }

    .header__background-img {
        object-position: 61%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            object-position: bottom;
        }
    }
}
