.products-accordion {

    &__group {

        &:not(:last-of-type) {
            padding-bottom: rem(40px);
        }

        .subnavigation__inner {
            padding: 0;
        }

        &--border-top {
            border-top: 1px solid rgb(44, 170, 86);
            padding-top: 2.5rem;
            border-radius: 0.125rem;
        }
    }

    &__header {
        font-family: $foco;
        font-size: rem(18px);
        font-weight: bold;
        line-height: (25 / 20);
        position: relative;
        width: 100%;
        cursor: pointer;

        &.collapsed {
            .subnavigation__arrow-wrapper {
                transform: translateY(-50%) rotate(-180deg);
            }
        }

        &-link {
            position: relative;
            width: 100%;
            padding-right: rem(28px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-right: rem(48px);
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                top: 50%;
                right: rem(24px);
                z-index: 1;
                width: rem(5px);
                height: rem(5px);
                margin-top: rem(-2.5px);
                border-left: rem(2px) solid rgba($congressBlue, 0.4);
                border-bottom: rem(2px) solid rgba($congressBlue, 0.4);
                transform: rotate(135deg);
                transition: transform 0.2s linear;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    right: rem(40px);
                }
            }

            &.collapsed::after {
                transform: rotate(-45deg);
            }
        }
    }

    &__header.active {

        &.products-accordion__link,
        .products-accordion__link {
            color: $eucalyptus;

            &:hover,
            &:active {
                color: $eucalyptus;
            }
        }
    }

    &__list {
        margin-bottom: 0;
        padding-top: rem(24px);
        padding-left: rem(20px);
        list-style: none;
    }

    &__item {

        &:not(:last-of-type) {
            margin-bottom: rem(16px);
        }
    }

    &__link {
        color: $firefly;
        transition: color .3s ease-in-out;
        width: 100%;

        &--trigger {
            width: 100%;
            display: block;
            width: 100%;
            position: relative;
            z-index: 3;
        }

        &:hover {
            color: $eucalyptus;
        }

        & > span {
            display: block;
            width: calc(100% - 40px);
        }


        &--with-icon {
            padding-left: 30px;
            position: relative;
        }
    }

    &__link-icon {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 0;
        top: 0.5em;
        transform: translateY(-50%);
        color: #2daa55;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
            width: 100%;
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }


    &__item.active {

        .products-accordion__link {
            color: $eucalyptus;

            &:hover,
            &:active {
                color: $eucalyptus;
            }
        }
    }
}
