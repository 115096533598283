.product-sidebar {
    margin-top: rem(32px);

    &--lg-side {
        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-top: rem(116px);
        }
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        margin-top: rem(68px);
        margin-bottom: rem(80px);
        width: 100%; // for IE
    }

    &__categories {
        margin-bottom: rem(80px);
    }

    &__group {
        .product-sidebar__list {
            margin-bottom: rem(48px);
        }

        &--spaceless-tablet-only {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: 0 !important;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                margin-bottom: rem(48px) !important;
            }
        }
    }

    &__wrapper {
        & + & {
            margin-top: 2.5rem;
        }
    }

    &__list {
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    &__item {
        &:not(:last-of-type) {
            margin-bottom: rem(24px);
        }
    }

    &__title {
        font-size: rem(16px);
        line-height: (22 / 16);
        margin-bottom: rem(24px);
        text-transform: uppercase;
        color: $firefly;
    }

    &__icon {
        &:before {
            content: '';
            position: relative;
            display: block;
            width: 1.5rem;
            height: 1.5rem;
            flex-shrink: 0;
            margin-right: .5rem;
            background-repeat: no-repeat;
        }

        &--experiments {
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='7' y='11' width='10' height='2' rx='1' fill='%232DAA55'/%3E%3Crect x='7' y='7' width='10' height='2' rx='1' fill='%232DAA55'/%3E%3Crect x='7' y='15' width='6' height='2' rx='1' fill='%232DAA55'/%3E%3C/svg%3E%0A");
            }
        }

        &--file {
            &:before {
                background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15 8H9C8.44772 8 8 8.44772 8 9V16C8 16.5523 8.44772 17 9 17H15C15.5523 17 16 16.5523 16 16V9C16 8.44772 15.5523 8 15 8ZM9 6C7.34315 6 6 7.34315 6 9V16C6 17.6569 7.34315 19 9 19H15C16.6569 19 18 17.6569 18 16V9C18 7.34315 16.6569 6 15 6H9ZM10 11C10 10.4477 10.4477 10 11 10H13C13.5523 10 14 10.4477 14 11C14 11.5523 13.5523 12 13 12H11C10.4477 12 10 11.5523 10 11ZM11 13C10.4477 13 10 13.4477 10 14C10 14.5523 10.4477 15 11 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H11Z' fill='%2331AA47'/%3E%3C/svg%3E%0A");
            }
        }
    }

    &__link {
        display: flex;

        &.active,
        &:active,
        &:hover {

            .product-sidebar__link-text {
                color: $eucalyptus;
            }
        }

        &-text {
            display: flex;
            align-items: center;
            font-size: rem(14px);
            line-height: rem(24px);
            color: $firefly06;
            position: relative;
            align-items: flex-start;
            transition: color .3s ease-in-out;

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                font-size: rem(16px);
                line-height: (20 / 16);
            }

            &:visited {
                color: $goblin;
            }
        }

        &-info {
            display: flex;
            align-items: center;
            flex-basis: 100%;
            max-width: 100%;
            font-size: rem(12px);
            line-height: (18 / 12);
            letter-spacing: 0.8px;
            text-transform: uppercase;
            color: $firefly06;
            margin-top: 0.5rem;

            > span {
                width: 0.25rem;
                height: 0.25rem;
                background-color: #C4C4C4;
                border-radius: 100%;
                margin-left: 0.5rem;
                margin-right: 0.5rem;
            }
        }

        &-icon {
            margin-top: 0.2em;
            flex-shrink: 0;
        }

        &--attention {
            color: $corn;

            .product-sidebar__link-text {
                color: $corn;
            }

            &:active {
                .product-sidebar__link-text {
                    border-color: $eucalyptus;
                    color: $eucalyptus;
                }
            }

            &.active {
                .product-sidebar__link-text {
                    border-color: $corn;
                }

                &:hover {
                    .product-sidebar__link-text {
                        border-color: $eucalyptus;
                    }
                }
            }

            &:hover {
                color: $corn;

                .product-sidebar__link-text {
                    color: $eucalyptus;
                }
            }
        }
    }
}
