.gallery {
    &-slider {
        .owl {
            &-prev {
                margin-right: rem(50px);
            }

            &-nav {
                margin-top: rem(50px);
                margin-left: rem(25px);
            }
        }

        &__item {
            &--video {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: calc(50% - #{rem(12px)});
                    left: calc(50% - #{rem(10px)});
                    z-index: 1;

                    width: 0;
                    height: 0;
                    border-top: rem(14px) solid transparent;
                    border-left: rem(20px) solid $white;
                    border-bottom: rem(14px) solid transparent;
                }
            }
        }
    }
}
