.legend {
    &__item {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        justify-content: flex-start;

        &:before {
            content: '';
            display: block;
            flex-shrink: 0;
            width: 1rem;
            height: 1rem;
            margin-right: 1rem;
        }

        &--map-blue {
            &:before {
                background-color: $mapBlue;
            }
        }
    }
}
