.timelapse {
    position: relative;
    height: rem(2px);
    color: $firefly;
    background-color: currentColor;

    &:after {
        position: absolute;
        content: '';
        top: rem(-1px);
        bottom: rem(-1px);
        border-radius: rem(1px);
        left: 0;
        right: 0;
        background-color: currentColor;
        transform: scaleX(0);
        transform-origin: left center;
    }

    &--transitioned {
        &:after {
            animation: timelapse 10s infinite linear;
        }
    }

    @keyframes timelapse {
        0% {
            transform: scaleX(0);
        }

        100% {
            transform: scaleX(1);
        }
    }

    &--white {
        color: $white;
    }

    &--silver {
        color: $grayChateau;
    }
}
