.product-filter {
    margin-bottom: rem(48px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-bottom: rem(64px);
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        margin-bottom: rem(80px);
    }

    &__list {
        display: none;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: flex;
            flex-wrap: wrap;
            margin: rem(-12px) rem(-20px);
            padding-left: 0;
            list-style: none;
        }
    }

    &__item {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-shrink: 0;
            margin: rem(12px) rem(20px);
        }
    }

    &__link {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-weight: bold;
            letter-spacing: rem(0.2px);

            &:not(&--active) {
                color: rgba($firefly, 0.4);
            }

            &:hover,
            &:visited {
                color: $firefly;
            }
        }
    }

    &__select {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: none;
        }
    }
}
