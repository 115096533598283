.date-picker {
    width: 170px;
    height: 25px;
    padding: 0;
    border: 0;
    line-height: 25px;
    padding-left: 10px;
    font-size: 12px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    cursor: pointer;
    color: #303030;
    position: relative;
    z-index: 2;
}

.date-picker-wrapper {
    position: absolute;
    z-index: 1100;
    box-shadow: rem(10px) rem(10px) rem(45px) rgba(0, 0, 0, 0.07);
    background-color: $white;
    padding: rem(24px) rem(8px) rem(8px + 134px) rem(8px);
    margin-top: rem(16px);
    min-height: rem(505px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding: rem(40px) rem(40px) rem(150px) rem(40px);
        min-height: 0;
    }
}

.dp-clearfix {
    clear: both;
    height: 0;
    font-size: 0;
}

.date-picker-wrapper.inline-wrapper {
    position: relative;
    -webkit-box-shadow: none;
    box-shadow: none;
    display: inline-block;
}

.date-picker-wrapper.single-date {
    width: auto;
}

.date-picker-wrapper.no-shortcuts {
    /*padding-bottom: 12px;*/
}

.date-picker-wrapper.no-topbar {
    padding-top: 12px;
}

.date-picker-wrapper .footer {
    font-size: 11px;
    padding-top: 3px;
    padding-bottom: 0;
    background: none;
}

.date-picker-wrapper b {
    color: #666;
    font-weight: 700;
}

.date-picker-wrapper a {
    color: #6bb4d6;
    text-decoration: underline;
}

.date-picker-wrapper .month-name {
    /*text-transform: uppercase;*/
}

.date-picker-wrapper .month-wrapper table th.month-name {
    font-family: $foco;
    padding-top: 0;
    padding-bottom: 0;
    font-size: rem(20px);
    line-height: rem(32px);
    letter-spacing: rem(0.25px);
}

.date-picker-wrapper .select-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
}

.date-picker-wrapper .select-wrapper:hover {
    text-decoration: underline;
}

.date-picker-wrapper .month-element {
    display: inline-block;
    vertical-align: middle;

    &::first-letter {
        text-transform: uppercase;
    }
}

.date-picker-wrapper .select-wrapper select {
    position: absolute;
    margin: 0;
    padding: 0;
    left: 0;
    top: -1px;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    text-transform: inherit;
    color: inherit;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent;
    border: 0;
    outline: 0;
    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=1)';
    filter: alpha(opacity=1);
    opacity: 0.01;
}

.date-picker-wrapper .month-wrapper {
    /*border: 1px solid #bfbfbf;
  border-radius: 3px;
  background-color: #fff;
  padding: 5px;*/
    cursor: default;
    position: relative;
    _overflow: hidden;
}

.date-picker-wrapper .month-wrapper table {
    width: 190px;
    float: left;
}

.date-picker-wrapper .month-wrapper table.month2 {
    width: 190px;
    float: left;
}

.date-picker-wrapper .month-wrapper table th,
.date-picker-wrapper .month-wrapper table td {
    vertical-align: middle;
    text-align: center;
    line-height: 14px;
    margin: 0px;
    padding: 0px;
}

.date-picker-wrapper .month-wrapper table tr {
    td {
        &.hovering,
        &.checked {
            .checked,
            .hovering {
                border-radius: 50%;
                position: relative;
                z-index: 2;
            }

            & + .hovering,
            & + .checked {
                position: relative;

                &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    right: 50%;
                    top: 0;
                    bottom: rem(8px);
                    background: $mossGreen;
                    z-index: 1;
                }

                &.nextMonth,
                &.lastMonth {
                    &:before {
                        display: none;
                    }
                }
            }

            &.lastMonth + .toMonth {
                &:before {
                    display: none;
                }
            }
        }
    }
}

.date-picker-wrapper .month-wrapper table .day {
    padding: rem(8px) 0;
    line-height: 1;
    margin-bottom: rem(8px);
    margin-left: rem(4px);
    margin-right: rem(4px);
    width: rem(32px);
    cursor: default;
}

.date-picker-wrapper .month-wrapper table div.day.lastMonth,
.date-picker-wrapper .month-wrapper table div.day.nextMonth {
    color: #999;
    cursor: default;
}

.date-picker-wrapper .month-wrapper table .day.checked {
    background-color: $mossGreen;
    color: $white;

    &.valid {
        background-color: $mossGreen;
        color: $white;
    }
}

.date-picker-wrapper .month-wrapper table .week-name {
    line-height: 24px;
    color: $firefly05;
}

.date-picker-wrapper .month-wrapper table .week-name th {
    padding-top: rem(24px);
    padding-bottom: rem(16px);
    font-weight: 400;
    line-height: 24px;

    &::first-letter {
        text-transform: uppercase;
    }
}

.date-picker-wrapper .month-wrapper table .day.has-tooltip {
    cursor: help !important;
}

.date-picker-wrapper .month-wrapper table .day.has-tooltip .tooltip {
    white-space: nowrap;
}

.date-picker-wrapper .time label {
    white-space: nowrap;
}

.date-picker-wrapper .month-wrapper table .day.toMonth.valid {
    color: $firefly;
    cursor: pointer;

    &.checked {
        color: $white;
    }
}

.date-picker-wrapper .month-wrapper table .day.toMonth.hovering {
    background-color: $mossGreen;
    color: $white;
}

.date-picker-wrapper .month-wrapper table .day.nextMonth,
.date-picker-wrapper .month-wrapper table .day.lastMonth {
    display: none;
}

.date-picker-wrapper .month-wrapper table .day.real-today {
    /*background-color: #ffe684;*/
}

.date-picker-wrapper .month-wrapper table .day.real-today.checked,
.date-picker-wrapper .month-wrapper table .day.real-today.hovering {
    background-color: $mossGreen;
    color: $white;
}

.date-picker-wrapper table .caption {
    height: auto;
}

.date-picker-wrapper table .caption > th:first-of-type,
.date-picker-wrapper table .caption > th:last-of-type {
    width: 27px;
}

.date-picker-wrapper table .caption .next,
.date-picker-wrapper table .caption .prev {
    /*padding: 0 5px;
  cursor: pointer;*/
    padding: 0;
    cursor: pointer;
    font-size: rem(12px);
    height: 100%;
    display: flex;
    align-items: center;
}

.date-picker-wrapper table .caption .prev {
    padding-left: rem(10px);
}

.date-picker-wrapper table .caption .next {
    padding-right: rem(10px);
    justify-content: flex-end;
}

.date-picker-wrapper table .caption .next:hover,
.date-picker-wrapper table .caption .prev:hover {
    /*background-color: #ccc;
  color: white;*/
}

.date-picker-wrapper .gap {
    position: relative;
    z-index: 1;
    width: 15px;
    height: 100%;
    background-color: white;
    font-size: 0;
    line-height: 0;
    float: left;
    top: 0;
    margin: 0 10px;
    visibility: hidden;
    height: 0;
}

.date-picker-wrapper .gap .gap-lines {
    height: 100%;
    overflow: hidden;
    display: none;
}

.date-picker-wrapper .gap .gap-line {
    height: 15px;
    width: 15px;
    position: relative;
}

.date-picker-wrapper .gap .gap-line .gap-1 {
    z-index: 1;
    height: 0;
    border-left: 8px solid white;
    border-top: 8px solid #eee;
    border-bottom: 8px solid #eee;
}

.date-picker-wrapper .gap .gap-line .gap-2 {
    position: absolute;
    right: 0;
    top: 0px;
    z-index: 2;
    height: 0;
    border-left: 8px solid transparent;
    border-top: 8px solid white;
}

.date-picker-wrapper .gap .gap-line .gap-3 {
    position: absolute;
    right: 0;
    top: 8px;
    z-index: 2;
    height: 0;
    border-left: 8px solid transparent;
    border-bottom: 8px solid white;
}

.date-picker-wrapper .gap .gap-top-mask {
    width: 6px;
    height: 1px;
    position: absolute;
    top: -1px;
    left: 1px;
    background-color: #eee;
    z-index: 3;
}

.date-picker-wrapper .gap .gap-bottom-mask {
    width: 6px;
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 7px;
    background-color: #eee;
    z-index: 3;
}

.date-picker-wrapper .selected-days {
    display: none !important;
}

.date-picker-wrapper .drp_top-bar {
    line-height: 1.4;
    /*position: relative;
  padding: 10px 40px 10px 0;*/
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0 rem(24px) rem(24px) rem(24px);
    display: flex;
    flex-direction: column;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 rem(40px) rem(40px) rem(40px);
    }
}

.date-picker-wrapper .drp_top-bar .error-top,
.date-picker-wrapper .drp_top-bar .normal-top {
    display: none;
}

.date-picker-wrapper .drp_top-bar .default-top {
    display: block;
    margin-bottom: rem(24px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-bottom: 0;
    }
}

.date-picker-wrapper .drp_top-bar.error .default-top {
    display: none;
}

.date-picker-wrapper .drp_top-bar.error .error-top {
    display: block;
    color: red;
}

.date-picker-wrapper .drp_top-bar.normal .default-top {
    display: none;
}

.date-picker-wrapper .drp_top-bar.normal .normal-top {
    display: block;
    margin-bottom: rem(24px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-bottom: 0;
    }
}

.date-picker-wrapper .drp_top-bar.normal .normal-top .selection-top {
    color: #333;
    display: none;
}

.date-picker-wrapper .drp_top-bar .apply-btn {
    /*position: absolute;
  right: 0px;
  top: 6px;*/
    /* padding: 3px 5px;*/
    margin: 0;
    /*font-size: 12px;
  border-radius: 4px;*/
    cursor: pointer;
    /*color: #d9eef7;
  border: solid 1px #0076a3;
  background: #0095cd;
  background: -moz-linear-gradient(top, #00adee, #0078a5);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00adee', endColorstr='#0078a5');
  color: white;
  line-height: initial;*/
}

.date-picker-wrapper .drp_top-bar .apply-btn.disabled {
    /*cursor: pointer;
  color: #606060;
  border: solid 1px #b7b7b7;
  background: #fff;
  background: -moz-linear-gradient(top, #fff, #ededed);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ededed');*/
}

.date-picker-wrapper .time {
    position: relative;
}

.date-picker-wrapper.single-month .time {
    display: none;
}

.date-picker-wrapper .time input[type='range'] {
    vertical-align: middle;
    width: 129px;
    padding: 0;
    margin: 0;
    height: 20px;
}

.date-picker-wrapper .time1 {
    width: 180px;
    padding: 0 5px;
    text-align: center;
}

/*time styling*/
.time2 {
    width: 180px;
    padding: 0 5px;
    text-align: center;
}

.date-picker-wrapper .time1 {
    float: left;
}

.date-picker-wrapper .time2 {
    float: right;
}

.date-picker-wrapper .hour {
    text-align: right;
}

.minute {
    text-align: right;
}

.date-picker-wrapper .hide {
    display: none;
}

.date-picker-wrapper .first-date-selected,
.date-picker-wrapper .last-date-selected {
    background-color: $eucalyptus !important;
    color: white !important;
    border-radius: 50%;
}

.date-picker-wrapper .date-range-length-tip {
    position: absolute;
    margin-top: -4px;
    margin-left: -8px;
    -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    display: none;
    background-color: yellow;
    padding: 0 6px;
    border-radius: 2px;
    font-size: 12px;
    line-height: 16px;
    -webkit-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    -moz-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    -ms-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    -o-filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
}

.date-picker-wrapper .date-range-length-tip:after {
    content: '';
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid yellow;
    left: 50%;
    margin-left: -4px;
    bottom: -4px;
}

.date-picker-wrapper.two-months.no-gap .month1 .next,
.date-picker-wrapper.two-months.no-gap .month2 .prev {
    display: none;
}

.date-picker-wrapper .week-number {
    padding: 5px 0;
    line-height: 1;
    font-size: 12px;
    margin-bottom: 1px;
    color: #999;
    cursor: pointer;
}

.date-picker-wrapper .week-number.week-number-selected {
    color: #49e;
    font-weight: bold;
}
