.gui {
    &-container {
        background-color: #717171;
        padding-bottom: rem(40px);
    }

    &-block {
        border-bottom: rem(2px) solid #ebebeb;
        padding: rem(20px) 0;

        &--select {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
        }

        &--white {
            background-color: $white;
        }

        &--link {
            display: flex;
            flex-direction: column;
        }
    }
}
