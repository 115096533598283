.calendar-page {
    &__header {
        margin-bottom: rem(52px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(56px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(80px);
        }
    }

    &__events {
        padding-top: rem(48px);
        padding-bottom: rem(32px);

        &.calendar-page__top-event--row {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            margin-left: -1.5rem;
            margin-right: -1.5rem;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(64px);
            padding-bottom: rem(64px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(96px);
        }
    }

    &__top-event {
        width: 100%;
        margin-bottom: rem(56px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: -webkit-box;
            display: -ms-flexbox;
            align-items: center;
            margin-bottom: rem(72px);
            flex-direction: row;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(86px);
            min-height: rem(293px);
        }

        &-title {
            font-family: $foco;
            font-size: rem(24px);
            margin-bottom: rem(24px);
            line-height: 1.2;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(64px);
                margin-bottom: rem(40px);
            }
        }

        &-link {
            font-family: $foco;
            font-weight: bold;
            font-size: rem(16px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
            }
        }

        &-info {
            height: rem(220px);
            width: rem(220px);
            position: relative;
            flex-shrink: 0;
            margin-bottom: rem(32px);
            margin-left: auto;
            margin-right: auto;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                height: rem(146px);
                width: rem(146px);
                margin-right: 0;
                margin-bottom: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                height: rem(293px);
                width: rem(293px);
            }

            &-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                width: 100%;
                height: 100%;

                position: relative;
                z-index: 2;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                }
            }

            &-place {
                line-height: 1.2;
                font-size: rem(12px);
                letter-spacing: rem(0.4px);
                margin-bottom: rem(28px);
                text-transform: uppercase;
                color: $silverSand;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(16px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(14px);
                    letter-spacing: rem(0.8px);
                    margin-bottom: rem(28px);
                }
            }

            &-date {
                font-family: $foco;
                font-weight: bold;
                font-size: rem(44px);
                line-height: 0.8;
                display: flex;
                justify-content: center;
                align-items: center;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(28px);
                    line-height: 1.1;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(56px);
                    line-height: 1.08;
                    min-height: rem(102px);
                }
            }

            &-circles {
            }

            &-circle {
                position: absolute;

                &--main,
                &--first,
                &--second,
                &--third {
                    top: 0;

                    width: 100%;
                    height: 100%;
                }

                &--first,
                &--second,
                &--third {
                    background-color: $glacier;
                }
            }
        }

        &-tag {
            display: flex;
            align-items: center;
            margin-bottom: 1.25rem;
            font-weight: 400;
            font-size: 0.75rem;
            line-height: 1.125rem;
            letter-spacing: 0.805px;
            color: rgba(#0e212f, 0.4);
            text-transform: uppercase;

            &:after {
                content: '';
                display: block;
                background-repeat: no-repeat;
            }

            &--video {
                &:after {
                    width: 0.5rem;
                    height: 0.5rem;
                    margin-left: 0.75rem;
                    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOCA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIG9wYWNpdHk9IjAuNCIgZD0iTTYuMTk2MTUgNEwxIDdMMSAxTDYuMTk2MTUgNFoiIHN0cm9rZT0iIzBFMjEyRiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+PC9zdmc+);
                }
            }
        }
    }

    &__controls {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: rem(80px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(56px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(48px);
        }

        &-left {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: rem(10px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                justify-content: flex-start;
                margin-bottom: 0;
            }

            .select--transparent {
                .selection {
                    justify-content: flex-end;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        justify-content: flex-start;
                    }
                }
            }
        }

        &-right {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                justify-content: flex-start;
            }

            .select--transparent {
                .selection {
                    justify-content: flex-end;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        justify-content: flex-start;
                    }
                }
            }
        }

        &-label {
            position: relative;
            top: rem(-1px);
            color: $firefly06;
            margin-right: rem(5px);
            margin-bottom: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-right: rem(16px);
            }
        }

        &-range {
            &-container {
                position: relative;
            }

            &-input {
                visibility: hidden;
                pointer-events: none;
                position: absolute;
                z-index: -1;
                left: 0;
                top: 0;
            }
        }
    }

    &__tab {
        min-width: rem(80px);
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(40px);
        border-radius: rem(32px);
        border: rem(1px) solid transparent;
        padding: rem(10px) rem(16px);
        color: $firefly;
        transition: all 0.3s ease;

        &:hover {
            color: $firefly;
            border: rem(1px) solid $silverSand;
        }

        &:not(:last-of-type) {
            margin-right: rem(24px);
        }

        &--current {
            color: $white;
            background: $eucalyptus;
            border: rem(1px) solid $eucalyptus;

            &:hover {
                color: $white;
                border: rem(1px) solid $eucalyptus;
            }
        }

        &-circle {
            width: rem(12px);
            height: rem(12px);
            border-radius: 50%;
            margin-right: rem(16px);
            flex-shrink: 0;

            &--invest {
                background: $buddhaGold;
            }

            &--press {
                background: $congressBlue;
            }

            &--customer {
                background: $rawSienna;
            }

            &--partner {
                background: $morningGlory;
            }
        }
    }

    &__cards {
        &-row {
            margin-bottom: rem(-24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(-48px);
            }

            & + & {
                margin-top: rem(40px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(80px);
                }
            }
        }

        &-col {
            margin-bottom: rem(24px);

            &:nth-child(3) {
                margin-bottom: rem(40px);
            }

            &:last-of-type {
                margin-bottom: rem(24px);
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(48px);

                &:nth-child(3) {
                    margin-bottom: rem(48px);
                }

                &:last-of-type {
                    margin-bottom: rem(48px);
                }
            }
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: rem(24px);
        min-height: rem(258px);
        border: rem(1px) solid $silverSand;
        border-radius: rem(3px);

        height: rem(258px);

        &:hover {
            box-shadow: rem(10px) rem(10px) rem(45px) rgba(0, 0, 0, 0.07);
            border: rem(1px) solid $white;
        }

        &--invest {
            transition: 200ms;

            &:hover {
                box-shadow: rem(1px) rem(8px) rem(8px) rgba(0, 0, 0, 0.07);
            }
        }

        &--today {
            border: rem(1px) solid $congressBlue;
        }

        &--subscribe {
            border: none;
            background: $congressBlue;
            color: $white;
            height: auto;

            &:hover {
                box-shadow: rem(10px) rem(10px) rem(45px) rgba(0, 0, 0, 0.07);
                border: none;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                min-height: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                min-height: rem(258px);
            }
        }

        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
        }

        &-label {
            padding: rem(0) rem(8px);
            line-height: rem(18px);
            font-size: rem(12px);
            letter-spacing: 0.69px;
            text-transform: uppercase;
            color: $white;

            &--invest {
                background: $buddhaGold;
            }

            &--press {
                background: $congressBlue;
            }

            &--customer {
                background: $rawSienna;
            }

            &--partner {
                background: $morningGlory;
            }
        }

        &-inner-text {
            display: none;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: inline-block;
                font-size: rem(12px);
                line-height: rem(18px);
                text-transform: uppercase;
                letter-spacing: 0.69px;
                margin-right: auto;
                padding-left: rem(16px);

                color: $buddhaGold;
            }
        }

        &-btn {
            display: inline-flex;
            align-items: center;
            font-size: rem(12px);
            line-height: 1.5;
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
            transition: all 0.3s ease;

            padding: 0;
            appearance: none;
            border: none;
            background: none;

            color: $silverSand;

            &--calendar {
                color: $eucalyptus08;

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    color: $silverSand;

                    .calendar-page__card:hover & {
                        color: $eucalyptus08;
                    }
                }
            }

            &--finished {
                color: $congressBlue08;

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    color: $silverSand;

                    .calendar-page__card:hover & {
                        color: $congressBlue08;
                    }
                }
            }

            &-text {
                display: inline-block;
                margin-right: rem(16px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: none;

                    .calendar-page__card:hover & {
                        display: inline-block;
                    }
                }
            }

            &-icon {
            }
        }

        &-date {
            font-size: rem(14px);
            line-height: (22/14);
            letter-spacing: rem(0.8px);
            text-transform: uppercase;
            color: $firefly07;
            margin-bottom: rem(16px);

            &-text {
                &--today {
                    color: $eucalyptus;
                }
            }
        }

        &-name {
            font-family: $foco;
            font-size: rem(20px);
        }

        &-subscribe {
            display: flex;

            &-title {
                font-size: rem(16px);
                line-height: 1.5;
                margin-top: rem(8px);
                margin-bottom: rem(93px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(20px);
                    line-height: 1.6;
                    margin-top: rem(16px);
                    margin-bottom: rem(16px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(72px);
                }
            }

            &-form {
                display: flex;
                align-items: center;

                &-input {
                    font-size: rem(16px);

                    border-left: none;
                    border-right: none;
                    border-top: none;
                    border-bottom: rem(1px) solid $white;

                    padding-bottom: rem(15px);
                    margin-right: rem(24px);

                    flex-grow: 1;
                    flex-basis: 0%;

                    color: $white;
                    background: transparent;

                    &::placeholder {
                        color: transparentize($white, 0.4);
                    }

                    &:focus {
                        outline: none;
                    }
                }

                &-submit {
                    color: $congressBlue;
                    border: none;
                    font-size: rem(6px);

                    background: $white;
                    border-radius: 50%;

                    width: rem(40px);
                    height: rem(40px);

                    flex-shrink: 0;

                    position: relative;
                    top: 0;

                    transition: all 0.15s ease-in-out;

                    &:hover {
                        top: rem(-5px);
                    }
                }
            }
        }
    }

    &-detail {
        &__title {
            &--empty-page {
                position: relative;
                padding-top: rem(20px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    padding-top: rem(40px);
                    margin-top: rem(40px);
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    background: $iron;
                    height: rem(1px);
                }
            }
        }

        &__p {
            margin-bottom: rem(32px);
        }

        &__header {
            padding-top: rem(64px);
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(96px);
                padding-bottom: rem(80px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-bottom: rem(96px);
            }

            &-button {
                width: 100%;
                margin-top: rem(40px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(48px);
                }
            }
        }

        &__program {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-header {
                margin-bottom: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(60px);
                }
            }

            &-events {
                speaker {
                    font-size: rem(14px);
                    //font-weight: bold;
                    //line-height: (17/14);
                    letter-spacing: rem(0.8px);
                    text-transform: uppercase;
                    color: $silverSand;
                }
            }

            &-event {
                display: flex;
                flex-direction: column;
                margin-bottom: rem(32px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    flex-direction: row;
                    margin-bottom: rem(40px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(49px);
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                &-time {
                    font-family: $foco;
                    color: $firefly05;
                    margin-bottom: rem(8px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-bottom: 0;
                        margin-right: rem(48px);
                        min-width: rem(103px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(20px);
                        min-width: rem(125px);
                    }
                }

                &-name {
                    font-family: $foco;

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(20px);
                    }
                }
            }
        }

        &__trailer {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-heading {
                margin-bottom: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(32px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(24px);
                }
            }

            &-link {
                height: rem(150px);
                width: rem(150px);
                position: relative;
                flex-shrink: 0;
                margin-bottom: rem(32px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: 0;
                }

                &-inner {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;

                    position: relative;
                    z-index: 2;
                }

                &-circles {
                }

                &-circle {
                    position: absolute;

                    &--main,
                    &--first,
                    &--second,
                    &--third {
                        top: 0;

                        width: 100%;
                        height: 100%;
                    }

                    &--first,
                    &--second,
                    &--third {
                        background-color: $eucalyptus;
                    }
                }
            }
        }

        &__links {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-heading {
                margin-bottom: rem(32px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(40px);
                }
            }

            &-link {
                font-family: $foco;
                font-weight: bold;
                margin-bottom: rem(24px);
                width: 100%;

                &:last-of-type {
                    margin-bottom: 0;
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: 0;
                    width: auto;
                    margin-right: rem(56px);
                }
            }
        }

        &__call {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-heading {
                margin-bottom: rem(32px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(40px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(48px);
                }
            }

            &-card {
                margin-bottom: rem(32px);

                &-row {
                    margin-bottom: rem(-32px);
                }

                &-title {
                    font-family: $foco;
                    font-weight: bold;
                    font-size: rem(16px);
                    margin-bottom: rem(24px);

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(24px);
                    }
                }

                &-phone {
                    & + & {
                        margin-top: rem(8px);
                    }
                }

                &-pin {
                    margin-top: rem(24px);

                    &-title {
                        color: $firefly05;
                    }
                }
            }
        }

        &__speakers {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-header {
                margin-bottom: rem(32px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(64px);
                }
            }

            &-heading {
                margin-bottom: rem(28px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }
            }

            &-card {
                margin-bottom: rem(38px);

                &-row {
                    margin-bottom: rem(-38px);
                }

                &-name {
                    font-family: $foco;
                    font-size: rem(20px);
                    line-height: (32/20);
                    margin-bottom: rem(8px);
                }

                &-position {
                    font-size: rem(14px);
                    letter-spacing: rem(0.8px);
                    text-transform: uppercase;
                    color: $firefly06;
                    margin-bottom: rem(24px);
                }

                &-description {
                }

                &-photo {
                    border-radius: rem(3px);
                    overflow: hidden;
                    width: 100%;
                    margin-top: rem(40px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-top: rem(64px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        margin-top: rem(72px);
                    }

                    &-img {
                        width: 100%;
                    }
                }
            }
        }

        &__banner {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-img {
                width: 100%;
            }
        }

        &__partners {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-heading {
                margin-bottom: rem(28px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }
            }

            &-card {
                margin-bottom: rem(32px);

                &:hover &-logo {
                    filter: none;
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(56px);
                }

                &-row {
                    margin-bottom: rem(-32px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-bottom: rem(-56px);
                    }
                }

                &-link {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                }

                &-logo {
                    max-width: 100%;
                    max-height: 100%;
                    filter: grayscale(1);
                }
            }
        }

        &__map {
            &-empty-page {
                min-height: rem(141px) !important;
                margin-top: rem(24px);
                margin-bottom: rem(48px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    min-height: rem(345px) !important;
                    margin-top: rem(56px);
                    margin-bottom: rem(96px);
                }
            }
        }

        &__company {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-content {
                & :last-child {
                    margin-bottom: 0;
                }
            }

            &-heading {
                margin-bottom: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }
            }

            &-facts {
                margin-top: rem(32px);
                margin-bottom: rem(8px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(48px);
                    margin-bottom: rem(8px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: 0;
                }

                &-card {
                    margin-bottom: rem(24px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-bottom: rem(40px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        margin-bottom: rem(48px);
                    }

                    &-num {
                        margin-bottom: rem(8px);
                        display: block;

                        &-number {
                            display: inline-block;
                            font-family: $foco;
                            font-weight: bold;
                            font-size: rem(24px);
                            line-height: (32/24);

                            @media screen and (min-width: #{breakpoint-min('xl')}) {
                                font-size: rem(32px);
                                line-height: (40/32);
                            }
                        }

                        &-caption {
                            display: inline-block;
                            margin-left: rem(12px);
                        }
                    }
                }
            }
        }

        &__memory {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-heading {
                margin-bottom: rem(28px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }
            }
        }

        &__gallery {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-heading {
                margin-bottom: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }
            }

            &-slider {
                .owl-stage {
                    display: flex;
                }
            }

            &-controls {
                &-slider {
                    display: flex;
                    align-items: center;

                    margin-top: rem(32px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-top: rem(40px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        margin-top: rem(48px);
                    }
                }
            }

            &-timelapse {
                flex-grow: 1;
                margin-left: rem(24px);
                margin-right: rem(24px);
            }
        }

        &__registration {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            &-heading {
                margin-bottom: rem(32px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(78px);
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }
            }

            &-form {
                position: relative;

                &-inner {
                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        padding: rem(64px);
                        border: rem(1px) solid $iron;
                        border-radius: rem(16px);
                        text-align: center;
                    }
                }

                &-fieldset {
                    position: relative;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: rem(32px);
                    max-width: rem(400px);
                }

                .select--phone-prefix {
                    position: absolute;
                    top: rem(-8px);
                    left: 0;
                }

                &-input {
                    text-align: left;
                    font-family: $foco;
                    font-size: rem(16px);
                    font-weight: bold;

                    border-left: none;
                    border-right: none;
                    border-top: none;
                    border-bottom: rem(1px) solid $silverSand;

                    padding-top: 0;
                    padding-bottom: rem(15px);

                    width: 100%;

                    &::placeholder {
                        color: $silverSand;
                    }

                    &:focus {
                        outline: none;
                    }

                    &--phone {
                        padding-left: rem(70px);
                    }
                }

                &-button {
                    margin-top: rem(8px);
                    margin-left: auto;
                    margin-right: auto;
                    width: rem(288px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-top: rem(16px);
                    }
                }

                &-caption {
                    margin-top: rem(24px);
                    color: $silverSand;
                }

                &-popup {
                    visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: transparentize($white, 0.2);

                    &-inner {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        background: $white;
                    }

                    &-check {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        border-radius: 50%;
                        color: $white;
                        background: $eucalyptus;
                        width: rem(56px);
                        height: rem(56px);
                        font-size: rem(20px);
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &-icon {
                        }
                    }

                    &-content {
                        padding: rem(54px) rem(58px) rem(40px) rem(58px);
                        text-align: center;
                        box-shadow: rem(10px) rem(10px) rem(45px)
                            rgba(0, 0, 0, 0.07);
                        border-radius: rem(16px);
                    }

                    &--visible {
                        animation: registrationSubmitPopup 3s ease;

                        @keyframes registrationSubmitPopup {
                            0% {
                                visibility: visible;
                                opacity: 0;
                            }

                            20% {
                                opacity: 1;
                            }

                            80% {
                                opacity: 1;
                            }

                            100% {
                                visibility: hidden;
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }

        &__similar {
            padding-bottom: rem(72px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(104px);
            }

            &-heading {
                margin-bottom: rem(32px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(40px);
                }
            }

            &-slider {
                position: relative;

                &:before {
                    position: absolute;
                    content: '';
                    top: rem(-20px);
                    bottom: rem(-20px);
                    right: calc(100% + #{rem(20px)});
                    background: $white;
                    left: -100vw;
                    z-index: 1;
                }

                &-item {
                    width: rem(288px);

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        width: rem(340px);
                    }
                }

                .owl-item {
                    height: rem(258px);
                }

                .owl-stage-outer {
                    overflow: visible;
                }

                .owl-stage {
                    display: flex;
                }
            }

            &-controls {
                &-slider {
                    display: flex;
                    align-items: center;

                    margin-top: rem(32px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-top: rem(40px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        margin-top: rem(48px);
                    }
                }
            }

            &-timelapse {
                flex-grow: 1;
                margin-left: rem(24px);
                margin-right: rem(24px);
            }
        }

        &__drive {
            padding-bottom: rem(72px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(104px);
            }

            &-heading {
                margin-top: rem(48px);
                margin-bottom: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(48px);
                    margin-bottom: rem(32px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: rem(70px);
                    margin-bottom: rem(32px);
                }
            }
        }

        &__aside {
            z-index: $aside-z-index;

            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(96px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-top: rem(96px);
                position: sticky;
                top: rem(30px);
            }

            &-button {
                width: 100%;
                margin-bottom: rem(40px);
            }

            &-contacts {
                margin-bottom: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(44px);
                }
            }

            &-route {
                margin-bottom: rem(8px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(16px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(8px);
                }
            }
        }
    }
}
