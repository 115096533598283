@mixin margin-level($direction, $variable, $negative: false, $important: false, $debug: false) {
    $imp:"";
    @if $important {
        $imp:"!important"
    }
    @if $negative {
        margin-#{$direction}: -#{map-get(map-get($spacersTokens, $variable), mobile)} #{$imp};
    } @else {
        margin-#{$direction}: map-get(map-get($spacersTokens, $variable), mobile) #{$imp};
    }

    $desktop: map-get(map-get($spacersTokens, $variable), desktop);
    $tablet: map-get(map-get($spacersTokens, $variable), tablet);
    $v-tablet: map-get(map-get($spacersTokens, $variable), v-tablet);
    $mobile: map-get(map-get($spacersTokens, $variable), mobile);

    @if $v-tablet {
        @include breakpoint(v-mobile) {
            @if $negative {
                margin-#{$direction}: -#{map-get(map-get($spacersTokens, $variable), v-tablet)} #{$imp};
            } @else {
                margin-#{$direction}: map-get(map-get($spacersTokens, $variable), v-tablet) #{$imp};
            }
        }
    }

    @if $tablet {
        @include breakpoint(mobile) {
            @if $negative {
                margin-#{$direction}: -#{map-get(map-get($spacersTokens, $variable), tablet)} #{$imp};
            } @else {
                margin-#{$direction}: map-get(map-get($spacersTokens, $variable), tablet) #{$imp};
            }
        }
    }

    @if $desktop {
        @include breakpoint(tablet) {
            @if $negative {
                margin-#{$direction}: -#{map-get(map-get($spacersTokens, $variable), desktop)} #{$imp};
            } @else {
                margin-#{$direction}: map-get(map-get($spacersTokens, $variable), desktop) #{$imp};
            }
        }
    }
}

@mixin padding-level($direction, $variable, $important: false, $debug: false) {
    $imp:"";
    @if $important {
        $imp:"!important"
    }
    padding-#{$direction}: map-get(map-get($spacersTokens, $variable), mobile) #{$imp};

    $desktop: map-get(map-get($spacersTokens, $variable), desktop);
    $tablet: map-get(map-get($spacersTokens, $variable), tablet);
    $v-tablet: map-get(map-get($spacersTokens, $variable), v-tablet);
    $mobile: map-get(map-get($spacersTokens, $variable), mobile);
    @if $v-tablet {
        @include breakpoint(v-mobile) {
            padding-#{$direction}: map-get(map-get($spacersTokens, $variable), v-tablet) #{$imp};
        }
    }

    @if $tablet {
        @include breakpoint(mobile) {
            padding-#{$direction}: map-get(map-get($spacersTokens, $variable), tablet) #{$imp};
        }
    }

    @if $desktop {
        @include breakpoint(tablet) {
            padding-#{$direction}: map-get(map-get($spacersTokens, $variable), desktop) #{$imp};
        }
    }
}

$sizesTokens:
    3XS,
    2XS,
    XS,
    S,
    M,
    L,
    XL,
    2XL,
    3XL,
    4XL;

$sizesTokensName:
    '3xs',
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
    '4xl';

@mixin marginToken($position) {
    $n: 1;
    @each $size in $sizesTokens {
        .u-#{($position)}-margin--#{nth($sizesTokensName, $n)} {
            @include margin-level($position, nth($sizesTokens, $n));
        }
        .u-#{($position)}-margin--#{nth($sizesTokensName, $n)}-important {
            @include margin-level($position, nth($sizesTokens, $n), false, true);
        }
        $n: $n + 1;
    }
}

@mixin paddingToken($position) {
    $n: 1;
    @each $size in $sizesTokens {
        .u-#{($position)}-padding--#{nth($sizesTokensName, $n)} {
            @include padding-level($position, nth($sizesTokens, $n));
        }
        .u-#{($position)}-padding--#{nth($sizesTokensName, $n)}-important {
            @include padding-level($position, nth($sizesTokens, $n), true);
        }
        $n: $n + 1;
    }
}

@mixin gap-level($variable, $type: "") {
    @if $type == "column" {
        column-gap: #{map-get(map-get($spacersTokens, $variable), mobile)};
    }
    
    @if $type == "row" {
        row-gap: #{map-get(map-get($spacersTokens, $variable), mobile)};
    }

    @if $type == "" {
        gap: #{map-get(map-get($spacersTokens, $variable), mobile)};
        grid-gap: #{map-get(map-get($spacersTokens, $variable), mobile)};
    }

    $desktop: map-get(map-get($spacersTokens, $variable), desktop);
    $tablet: map-get(map-get($spacersTokens, $variable), tablet);
    $v-tablet: map-get(map-get($spacersTokens, $variable), v-tablet);
    $mobile: map-get(map-get($spacersTokens, $variable), mobile);

    @if $v-tablet {
        @include breakpoint(v-mobile) {
            @if $type == "column" {
                column-gap: #{map-get(map-get($spacersTokens, $variable), v-tablet)};
            } @else if $type == "row" {
                row-gap: #{map-get(map-get($spacersTokens, $variable), v-tablet)};
            } @else {
                gap: #{map-get(map-get($spacersTokens, $variable), v-tablet)};
                grid-gap: #{map-get(map-get($spacersTokens, $variable), v-tablet)};
            }
        }
    }

    @if $tablet {
        @include breakpoint(mobile) {
            @if $type == "column" {
                column-gap: #{map-get(map-get($spacersTokens, $variable), tablet)};
            } @else if $type == "row" {
                row-gap: #{map-get(map-get($spacersTokens, $variable), tablet)};
            } @else {
                gap: #{map-get(map-get($spacersTokens, $variable), tablet)};
                grid-gap: #{map-get(map-get($spacersTokens, $variable), tablet)};
            }
        }
    }

    @if $desktop {
        @include breakpoint(tablet) {
            @if $type == 'column' {
                column-gap: #{map-get(map-get($spacersTokens, $variable), desktop)};
            } @else if $type == 'row' {
                row-gap: #{map-get(map-get($spacersTokens, $variable), desktop)};
            } @else {
                gap: #{map-get(map-get($spacersTokens, $variable), desktop)};
                grid-gap: #{map-get(map-get($spacersTokens, $variable), desktop)};
            }
        }
    }
}

@mixin gapToken {
    $n: 1;
    @each $size in $sizesTokens {
        .u-gap--#{nth($sizesTokensName, $n)} {
            @include gap-level(nth($sizesTokens, $n));
        }
        $n: $n + 1;
    }
}

@mixin gapRowToken {
    $n: 1;
    @each $size in $sizesTokens {
        .u-row-gap--#{nth($sizesTokensName, $n)} {
            @include gap-level(nth($sizesTokens, $n), "row");
        }
        $n: $n + 1;
    }
}

@mixin gapColumnToken {
    $n: 1;
    @each $size in $sizesTokens {
        .u-column-gap--#{nth($sizesTokensName, $n)} {
            @include gap-level(nth($sizesTokens, $n), "column");
        }
        $n: $n + 1;
    }
}
