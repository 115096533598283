.product-details-pill-group {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        flex-direction: row;
        align-items: flex-end;
        margin-top: rem(-24px);
        margin-bottom: rem(-24px);
    }

    &__item {
        padding-bottom: rem(72px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(96px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-top: rem(24px);
            margin-bottom: rem(24px);
            padding-bottom: 0;
            padding-right: rem(64px);
        }

        &:last-of-type {
            padding-right: 0;
            padding-bottom: 0;
        }

        &:not(:last-of-type) &-body {
            position: relative;

            &::before,
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: rem(-64px);
                z-index: 1;
                width: rem(20px);
                height: rem(3px);
                background-color: $alto;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: rem(24px);
                    height: rem(4px);
                }
            }

            &::after {
                transform: rotate(90deg);
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                &::before,
                &::after {
                    bottom: rem(-90px);
                }
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                &::before,
                &::after {
                    left: 100%;
                    bottom: 50%;
                    margin-top: rem(2px);
                    margin-left: rem(20px);
                }
            }
        }

        &-footer {
            display: flex;
            margin-top: rem(8px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: rem(24px);
            }
        }
    }

    &__tag {
        margin-right: rem(24px);

        & span {
            font-size: rem(14px);
            line-height: (24 / 14);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(20px);
                line-height: (24 / 20);
            }
        }
    }

    &__icon {
        margin-right: rem(8px);

        &--autumn {
            color: $tenn;
        }

        &--sprout {
            color: $eucalyptus;
        }

        &--sun {
            color: $supernova;
        }
    }
}
