// colors
.white-bg {
    background-color: #ffffff !important;
}

.white-text {
    color: #ffffff !important;
}

.terracotta-red-bg {
    background-color: #d3824c !important;
}

.terracotta-red-text {
    color: #d3824c !important;
}

.sky-blue-bg {
    background-color: #64b8cf !important;
}

.sky-blue-text {
    color: #64b8cf !important;
}

.secondary-bg {
    background-color: #004a93 !important;
}

.secondary-text {
    color: #004a93 !important;
}

.primary-bg {
    background-color: #2daa55 !important;
}

.primary-text {
    color: #2daa55 !important;
}

.olive-green-bg {
    background-color: #4d712e !important;
}

.olive-green-text {
    color: #4d712e !important;
}

.mustard-bg {
    background-color: #daaa00 !important;
}

.mustard-text {
    color: #daaa00 !important;
}

.mineral-gray-bg {
    background-color: #5e6367 !important;
}

.mineral-gray-text {
    color: #5e6367 !important;
}

.link-dark-default-bg {
    background-color: #0e212f !important;
}

.link-dark-default-text {
    color: #0e212f !important;
}

.link-light-default-bg {
    background-color: #f3f4f4 !important;
}

.link-light-default-text {
    color: #f3f4f4 !important;
}

.link-dark-hover-bg {
    background-color: #269448 !important;
}

.link-dark-hover-text {
    color: #269448 !important;
}

.link-light-hover-bg {
    background-color: #ffffff !important;
}

.link-light-hover-text {
    color: #ffffff !important;
}

.link-default-bg {
    background-color: #249a4a !important;
}

.link-default-text {
    color: #249a4a !important;
}

.link-hover-bg {
    background-color: #33be60 !important;
}

.link-hover-text {
    color: #33be60 !important;
}

.link-active-bg {
    background-color: #2ba150 !important;
}

.link-active-text {
    color: #2ba150 !important;
}

.link-dark-active-bg {
    background-color: #11293c !important;
}

.link-dark-active-text {
    color: #11293c !important;
}

.link-light-active-bg {
    background-color: #f3f4f4 !important;
}

.link-light-active-text {
    color: #f3f4f4 !important;
}

.light-yellow-bg {
    background-color: #ecd47f !important;
}

.light-yellow-text {
    color: #ecd47f !important;
}

.light-blue-bg {
    background-color: #9ed9e5 !important;
}

.light-blue-text {
    color: #9ed9e5 !important;
}

.form-text-color-disabled-bg {
    background-color: #a1a7b5 !important;
}

.form-text-color-disabled-text {
    color: #a1a7b5 !important;
}

.form-text-color-default-bg {
    background-color: #0e212f !important;
}

.form-text-color-default-text {
    color: #0e212f !important;
}

.form-placeholder-color-default-bg {
    background-color: #b1b6ba !important;
}

.form-placeholder-color-default-text {
    color: #b1b6ba !important;
}

.form-border-color-valid-bg {
    background-color: #2daa55 !important;
}

.form-border-color-valid-text {
    color: #2daa55 !important;
}

.form-border-color-error-bg {
    background-color: #832925 !important;
}

.form-border-color-error-text {
    color: #832925 !important;
}

.form-border-color-default-bg {
    background-color: #cfd3d5 !important;
}

.form-border-color-default-text {
    color: #cfd3d5 !important;
}

.earthy-brown-bg {
    background-color: #8e7159 !important;
}

.earthy-brown-text {
    color: #8e7159 !important;
}

.corn-yellow-bg {
    background-color: #ffcc66 !important;
}

.corn-yellow-text {
    color: #ffcc66 !important;
}

.button-secondary-text-hover-bg {
    background-color: #0a1b27 !important;
}

.button-secondary-text-hover-text {
    color: #0a1b27 !important;
}

.button-secondary-text-disabled-bg {
    background-color: #ffffff !important;
}

.button-secondary-text-disabled-text {
    color: #ffffff !important;
}

.button-secondary-text-default-bg {
    background-color: #0e212f !important;
}

.button-secondary-text-default-text {
    color: #0e212f !important;
}

.button-secondary-text-active-bg {
    background-color: #11293b !important;
}

.button-secondary-text-active-text {
    color: #11293b !important;
}

.button-secondary-bg-hover-bg {
    background-color: #ffffff !important;
}

.button-secondary-bg-hover-text {
    color: #ffffff !important;
}

.button-secondary-bg-disabled-bg {
    background-color: #cfd3d5 !important;
}

.button-secondary-bg-disabled-text {
    color: #cfd3d5 !important;
}

.button-secondary-bg-default-bg {
    background-color: #ffffff !important;
}

.button-secondary-bg-default-text {
    color: #ffffff !important;
}

.button-secondary-bg-active-bg {
    background-color: #f2f2f2 !important;
}

.button-secondary-bg-active-text {
    color: #f2f2f2 !important;
}

.button-primary-text-hover-bg {
    background-color: #ffffff !important;
}

.button-primary-text-hover-text {
    color: #ffffff !important;
}

.button-primary-text-disabled-bg {
    background-color: #ffffff !important;
}

.button-primary-text-disabled-text {
    color: #ffffff !important;
}

.button-primary-text-default-bg {
    background-color: #ffffff !important;
}

.button-primary-text-default-text {
    color: #ffffff !important;
}

.button-primary-text-active-bg {
    background-color: #ffffff !important;
}

.button-primary-text-active-text {
    color: #ffffff !important;
}

.button-primary-bg-hover-bg {
    background-color: #33be60 !important;
}

.button-primary-bg-hover-text {
    color: #33be60 !important;
}

.button-primary-bg-disabled-bg {
    background-color: #cfd3d5 !important;
}

.button-primary-bg-disabled-text {
    color: #cfd3d5 !important;
}

.button-primary-bg-default-bg {
    background-color: #2daa55 !important;
}

.button-primary-bg-default-text {
    color: #2daa55 !important;
}

.button-primary-bg-active-bg {
    background-color: #2ba150 !important;
}

.button-primary-bg-active-text {
    color: #2ba150 !important;
}

.button-bordered-hover-bg {
    background-color: #2daa55 !important;
}

.button-bordered-hover-text {
    color: #2daa55 !important;
}

.button-bordered-disabled-bg {
    background-color: #bfc6d3 !important;
}

.button-bordered-disabled-text {
    color: #bfc6d3 !important;
}

.button-bordered-default-bg {
    background-color: #2daa55 !important;
}

.button-bordered-default-text {
    color: #2daa55 !important;
}

.button-bordered-active-bg {
    background-color: #2daa55 !important;
}

.button-bordered-active-text {
    color: #2daa55 !important;
}

.bright-green-bg {
    background-color: #a0b335 !important;
}

.bright-green-text {
    color: #a0b335 !important;
}

.black-95-bg {
    background-color: #0e212f !important;
}

.black-95-text {
    color: #0e212f !important;
}

.black-90-bg {
    background-color: #17171a !important;
}

.black-90-text {
    color: #17171a !important;
}

.black-80-bg {
    background-color: #1d1f23 !important;
}

.black-80-text {
    color: #1d1f23 !important;
}

.black-70-bg {
    background-color: #3b414f !important;
}

.black-70-text {
    color: #3b414f !important;
}

.black-60-bg {
    background-color: #4e566a !important;
}

.black-60-text {
    color: #4e566a !important;
}

.black-50-bg {
    background-color: #626c84 !important;
}

.black-50-text {
    color: #626c84 !important;
}

.black-40-bg {
    background-color: #81899d !important;
}

.black-40-text {
    color: #81899d !important;
}

.black-30-bg {
    background-color: #a1a7b5 !important;
}

.black-30-text {
    color: #a1a7b5 !important;
}

.black-20-bg {
    background-color: #d0d3da !important;
}

.black-20-text {
    color: #d0d3da !important;
}

.black-15-bg {
    background-color: #e1e4eb !important;
}

.black-15-text {
    color: #e1e4eb !important;
}

.black-10-bg {
    background-color: #edeff3 !important;
}

.black-10-text {
    color: #edeff3 !important;
}

.black-5-bg {
    background-color: #f3f4f4 !important;
}

.black-5-text {
    color: #f3f4f4 !important;
}

.black-bg {
    background-color: #000000 !important;
}

.black-text {
    color: #000000 !important;
}

.azure-blue-bg {
    background-color: #018fa1 !important;
}

.azure-blue-text {
    color: #018fa1 !important;
}

.table-accent-dark-bg {
    background-color: #004a93 !important;
}

.table-accent-dark-text {
    color: #004a93 !important;
}

.table-accent-middle-bg {
    background-color: #8aa7c4 !important;
}

.table-accent-middle-text {
    color: #8aa7c4 !important;
}

.table-accent-light-bg {
    background-color: #e6edf5 !important;
}

.table-accent-light-text {
    color: #e6edf5 !important;
}

.table-text-totall-bg {
    background-color: #004a93 !important;
}

.table-text-totall-text {
    color: #004a93 !important;
}

.table-text-dark-bg {
    background-color: #282830 !important;
}

.table-text-dark-text {
    color: #282830 !important;
}

.table-text-middle-bg {
    background-color: #414346 !important;
}

.table-text-middle-text {
    color: #414346 !important;
}

.table-text-light-bg {
    background-color: #838894 !important;
}

.table-text-light-text {
    color: #838894 !important;
}

.table-bg-grey-bg {
    background-color: #f8fafc !important;
}

.table-bg-grey-text {
    color: #f8fafc !important;
}

.table-bg-hover-bg {
    background-color: #eaf6ee !important;
}

.table-bg-hover-text {
    color: #eaf6ee !important;
}
// typo-level
            .text-size-2xs  {
                @include typo-level(2XS);
                line-height: inherit;

                &--important {
                    @include typo-level(2XS, true);
                }
            }
        
            .text-size-xs  {
                @include typo-level(XS);
                line-height: inherit;

                &--important {
                    @include typo-level(XS, true);
                }
            }
        
            .text-size-s  {
                @include typo-level(S);
                line-height: inherit;

                &--important {
                    @include typo-level(S, true);
                }
            }
        
            .text-size-m  {
                @include typo-level(M);
                line-height: 1.5;

                &--important {
                    @include typo-level(M, true);
                }
            }
        
            .text-size-l  {
                @include typo-level(L);
                line-height: 1.5;

                &--important {
                    @include typo-level(L, true);
                }
            }
        
            .text-size-xl  {
                @include typo-level(XL);
                line-height: inherit;

                &--important {
                    @include typo-level(XL, true);
                }
            }
        
            .text-size-2xl  {
                @include typo-level(2XL);
                line-height: inherit;

                &--important {
                    @include typo-level(2XL, true);
                }
            }
        
            .text-size-3xl  {
                @include typo-level(3XL);
                line-height: inherit;

                &--important {
                    @include typo-level(3XL, true);
                }
            }
        
            .text-size-4xl  {
                @include typo-level(4XL);
                line-height: inherit;

                &--important {
                    @include typo-level(4XL, true);
                }
            }

.u-collapse--all {
    margin: 0!important;
}

.u-block {
    display: block!important;
}
