.top-right-menu {
    display: none;

    &__list-link {
        font-family: Foco, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        display: block;
        margin-right: 2.5rem;

        &__list {
            display: flex;
            padding: 0;
            margin-bottom: 0;

            &-item {
                &:not(:last-of-type) {
                    margin-right: 2rem;
                }
            }

            &-link {
                color: #fff;
                transition: opacity .3s ease-in-out, color .3s ease-in-out;
                font-weight: 700;

                &:hover {
                    color: inherit;
                    opacity: .6;
                }

                &.active {
                    // color: #2daa55;
                    // opacity: 1;
                    pointer-events: none;
                }
            }

            &-icon {
                display: none;
            }
        }
    }

    &__list {
        list-style: none;
    }
}

.header--fixed {
    .top-right-menu {
        display: none;
    }
}

.header__menu-inner {
    .top-right-menu {
        display: block;

        &--inner {
            font-family: $foco;
            margin-right: 0;

            .top-right-menu__list {
                display: block;
                padding: 0;
                margin-bottom: 0;

                &-link {
                    font-size: rem(16px);
                    line-height: 1.5;
                    font-weight: 700;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #0e212f;
                    transition: color .3s ease-in-out;

                    &:hover {
                        color: #2daa55;
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(20px);
                    }
                }

                &-item {
                    margin-bottom: 16px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }

                &-icon {
                    display: inline-block;
                    height: 1em;
                    width: 0.599609375em;
                    color: #bec4c7;
                    font-size: .625rem;
                    stroke-width: 0;
                    stroke: currentColor;
                    fill: currentColor;
                }
            }
        }
    }
}

.html--header-search-opened .top-right-menu {
    display: none;
}