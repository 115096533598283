.icon {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;

    &-slider-arrow-left {
        width: 0.599609375em;
    }

    &-slider-arrow-right {
        width: 0.599609375em;
    }

    &-aic {
        width: 45px;
        height: 20px;
    }

    &-qsoft {
        width: 80px;
        height: 13px;
    }

    &-vk {
        width: 1.5625em;
    }

    &-twitter {
        width: 1.158203125em;
    }

    &-fb {
        width: 0.5em;
    }

    &-link {
        width: 1em;

        &--small {
            width: .5em;
            height: .5em;
        }
    }

    &-warning {
        width: 0.75em;
    }

    &-circle {
        width: 0.625em;
    }

    &-print-ver {
        width: 0.625em;
    }

    &-phone {
        width: 0.6em;
    }

    &-mail {
        width: rem(16px);
        height: rem(16px);

        &--news {
            width: 0.6em;
            margin-top: 0.5em!important;
        }

        &--procurement {
            width: 0.625em;
        }
    }

    &-document {
        width: 0.726em;

        &--small {
            width: 0.55em;
        }
    }

    &-external-t {
        width: 0.726em;

        &--small {
            width: 0.55em;
        }
    }

    &-external-bold {
        width: 1em;

        &--small {
            width: 0.55em;
        }
    }

    &--subscribe {
        background: $eucalyptus;
        border-radius: 50%;
        color: #fff;
        padding: 5px;
        display: block;
    }

    &--link {
        width: rem(8px);
        height: rem(8px);
    }

    &--pin {
        width: rem(13px);
        height: rem(16px);
    }

    &--telephone {
        width: rem(10px);
        height: rem(14px);
    }

    &--email {
        width: rem(12px);
        height: rem(14px);
    }

    &--accordion-arrow {
        width: rem(12px);
        height: rem(8px);
    }

    &--accordion-arrow-huge {
        width: rem(15px);
        height: rem(10px);
    }

    &--document-audio,
    &--webcast {
        width: rem(52px);
        height: rem(52px);
    }

    &--social-responsibility,
    &--briefcase,
    &--ecology,
    &--medal,
    &--volleyball,
    &--route,
    &--barrier,
    &--microscope,
    &--education-program,
    &--social-events,
    &--internship,
    &--worker,
    &--tech-education,
    &--chemistry,
    &--sertificate,
    &--telescope {
        width: rem(64px);
        height: rem(64px);
    }

    &--family,
    &--food,
    &--health,
    &--education,
    &--genders,
    &--sanitation,
    &--energy,
    &--trend,
    &--infrastructure,
    &--inequality,
    &--cities,
    &--recycling,
    &--climate,
    &--sea,
    &--ecosystem,
    &--justice,
    &--partnership {
        width: rem(32px);
        height: rem(32px);
    }

    &--list {
        width: 0.625rem;
        height: 0.625rem;
    }
}
