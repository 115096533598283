.owl {
    &-next,
    &-prev {
        position: relative;
        padding: 0;
        background: none;
        border: none;
        appearance: none;
        font-size: rem(11px) !important;
        color: $congressBlue;
        transition: all 0.3s ease;
        width: auto;
        height: rem(24px);

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            &:before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                width: rem(63px);
                height: rem(63px);
                transition: all 0.3s ease;
                transform: translateY(-50%) translateX(-50%) scale(0);
                background: $eucalyptus;
                border-radius: 50%;
                z-index: 1;
            }

            &:after {
                content: '';
                position: absolute;
                left: rem(-25px);
                right: rem(-25px);
                top: rem(-25px);
                bottom: rem(-25px);
            }

            &:hover {
                color: $white !important;

                &:before {
                    transform: translateY(-50%) translateX(-50%) scale(1);
                }
            }
        }

        .icon {
            position: relative;
            z-index: 2;
        }
    }

    &-prev {
        margin-right: rem(40px);

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-right: rem(48px);
        }
    }

    &-stage {
        display: flex;
    }
}

.owl-carousel .owl-stage:after {
    content: '';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
    width: 0;
}

.slider {
    &__slider {
    }

    &__controls {
        &-num {
            &-container {
                display: flex;
                font-weight: bold;
                font-size: rem(16px);
                line-height: (24/16);
            }

            &--current {
                margin-right: rem(6px);
            }

            &--quantity {
                margin-left: rem(6px);
            }
        }
    }

    .owl-dots {
        display: none;
    }
}
