.scrollbar-track {
    background: none !important;

    &-x {
        height: rem(4px) !important;
    }

    &-y {
        width: rem(4px) !important;
    }
}

.scrollbar-thumb {
    &-x {
        height: rem(4px) !important;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: rem(10px);
        }
    }

    &-y {
        width: rem(4px) !important;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: rem(10px);
        }
    }
}
