.content-card {
    padding: rem(32px) rem(24px);
    border-radius: rem(3px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding: rem(40px) rem(48px);
    }

    &--large {
        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding: rem(80px) rem(64px);
        }
    }

    &--primary {
        background: rgba($eucalyptus, 0.1);
        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &--bordered {
        border: 2px solid #2DAA55;
    }

    &--contrast {
        background: rgba($eucalyptus, 0.2);
    }

    &__text {
        &--grey {
            margin-top: rem(8px);
            color: #a3beab;
            word-break: normal;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: 0;
            }

            &-wrap {
                margin-bottom: 0;
            }
        }

        &-wrap {
            margin-bottom: 0;
        }
    }
}
