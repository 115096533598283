.branch {
    &__header {
        padding-top: rem(50px);
        font-size: rem(28px);
        line-height: (36 / 28);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(65px);
            font-size: rem(48px);
            line-height: (60 / 48);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(80px);
            font-size: rem(64px);
            line-height: (48 / 64);
        }
    }

    &__topic {
        padding-top: rem(32px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(48px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(64px);
        }
    }

    &__caption {
        padding-top: rem(10px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(40px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(48px);
        }

        &--gallery {
            padding-top: rem(25px);
            padding-bottom: rem(32px);
        }
    }

    &__rubric {
        padding-top: rem(48px);

        &--slider {
            padding-top: rem(32px);
            padding-bottom: rem(12px);
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(64px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(64px);
        }
    }

    &__slider {
        .owl-nav {
            position: absolute;
            right: 1%;
            bottom: rem(-20px);
            color: $congressBlue;
            display: flex;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            .owl-nav {
                left: 64%;
                bottom: rem(-20px);
            }
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            .owl-nav {
                left: rem(20px);
                bottom: rem(45px);
                color: $congressBlue;
            }
        }
    }

    &__map {
        margin-bottom: rem(30px);
    }

    &__unordered-list__item {
        padding-bottom: rem(24px);
        width: 100%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(48px);
            width: 45%;
        }
    }

    &__unordered-list {
        padding-top: rem(24px);
        flex-direction: column;

        &--inner {
            padding-top: 0;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(32px);
            flex-direction: row;
            justify-content: space-between;

            &--inner {
                padding-top: 0;
            }
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(57px);

            &--inner {
                padding-top: 0;
            }
        }
    }

    &__tabs-container {
        padding-right: 0;
    }

    &__info-container {
        display: block;
        padding-bottom: rem(48px);

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            display: none;
        }
    }

    &__top-info-block {
        display: none;

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            display: block;
            padding-right: 0;
            padding-bottom: rem(80px);
        }
    }

    &__manager {
        display: flex;
        align-items: center;
        padding-top: rem(56px);
        position: relative;
        border: none;

        &::after {
            content: '';
            position: absolute;
            width: 150%;
            height: 1px;
            background-color: $iron;
            bottom: -1px;
            left: -1px;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            position: static;
            border-bottom: rem(1px) solid $iron;

            &::after {
                content: '';
                display: none;
            }
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            display: block;
        }
    }

    &__manager-wrapper {
        padding-left: rem(30px);

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-left: 0;
        }
    }

    &__timelapse-wrapper {
        padding-bottom: rem(28px);

        &--gallery {
            padding-bottom: 0;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            &--gallery {
                display: none;
            }
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            display: none;
        }
    }

    &__manager-photo {
        width: rem(66px);
        margin-bottom: auto;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: rem(128px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-bottom: rem(24px);
        }
    }

    &__fluid-container {
        background-color: $wildSand;
    }

    &__gallery-slider {
        padding-top: rem(32px);
        padding-bottom: rem(15px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            &--decoration {
                .owl-nav {
                    display: none;
                }
            }
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(85px);

            &--decoration {
                padding-bottom: rem(5px);
            }
        }
    }

    &__slider-controls {
        display: flex;
        align-items: center;
        pointer-events: all;
        color: $firefly;
        opacity: 0.2;
    }

    &__product-cards {
        flex-direction: column;
        padding-top: rem(32px);

        .product-cards__item {
            width: 100%;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            flex-direction: row;

            .product-cards__item {
                width: 50%;
            }
        }
    }

    &__inner-tabs {
        margin-right: rem(-16px);
        overflow: hidden;

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            margin-right: 0;
        }
    }

    &__news-img {
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    &__news {
        padding-top: rem(48px);
        margin-bottom: rem(-32px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(64px);
            margin-bottom: rem(-24px);
        }
    }

    &__news-list {
        display: none;
        padding-bottom: rem(48px);

        &.active-tab {
            display: block;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(80px);
        }
    }

    &__news-heading {
        padding-top: rem(24px);
        padding-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(40px);
            padding-bottom: rem(32px);
        }
    }

    &__text-card {
        padding-bottom: rem(32px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: 24px;
        }
    }

    &__list-heading {
        padding-left: 0;
        padding-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: none;
        }
    }

    &__contacts__list {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: flex;
            justify-content: space-between;

            div {
                width: 47%;
            }
        }
    }

    &__document {
        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-bottom: rem(80px);
            padding-top: rem(56px);
        }
    }

    &__caption-heading {
        padding-top: rem(48px);
        font-size: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(26px);
            font-size: rem(20px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-top: rem(48px);
            font-size: rem(24px);
        }
    }

    &__ordered {
        &-item {
            padding-top: rem(24px);
        }

        &-list {
            margin-bottom: rem(8px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(16px);
            }
        }
    }

    &__link {
        color: $eucalyptus;
        letter-spacing: rem(0.2px);
        font-weight: 600;

        &-block {
            padding-top: rem(24px);

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-top: rem(32px);
            }
        }
    }

    &__slider-wrapper {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-right: -40%;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-right: -40%;
        }
    }

    &__tab-content {
        display: none;

        &.active-tab {
            display: block;
        }
    }

    &__box-content {
        display: none;

        &.active-tab-main {
            display: block;
        }
    }

    &__photo {
        width: 100%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 50%;
        }

        &-img {
            width: 100%;
        }
    }

    &--products {
        .branch__manager {
            padding-top: 0;

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-top: rem(46px);
            }
        }

        .product-info__title {
            font-size: rem(14px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(18px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                font-size: rem(24px);
            }
        }
    }

    &--connection {
        .branch__caption {
            padding-top: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(32px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(48px);
            }
        }
    }

    /*.fancybox-content {
    width: auto !important;
    margin-right: 0 !important;
  }*/

    .gallery-slider__item img {
        width: 100%;
    }

    .fancybox-active {
        height: 100% !important;
    }
}
