.contact-info {
    &__title {
        margin-bottom: rem(24px);
    }

    &__label {
        margin-bottom: rem(16px);
        font-size: rem(14px);
        line-height: (17 / 14);
        letter-spacing: em(0.8px, 14px);
        text-transform: uppercase;
        color: $silverSand;
    }

    &__caption {
        display: block;
        font-size: 18px;
        margin-bottom: 24px;
    }

    &__list {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: rem(16px);
        }
    }

    &__link {
        display: flex;

        &--external {
            font-family: $foco, $fonts-default;
            font-size: rem(16px);
            font-weight: bold;
            line-height: (20 / 16);

            &:last-child {
                margin-top: rem(24px);
            }

            &:not(:last-child) {
                margin-bottom: rem(16px);
            }
        }

        &-icon {
            margin-top: 0.3em;
            flex-shrink: 0;

            &--address {
                margin-left: em(-0.5px);
                margin-right: em(15.5px);
            }

            &--phone {
                margin-right: em(17px);
            }

            &--email {
                margin-right: em(17px);
                width: rem(12px);
                height: rem(16px);
            }

            &--telegram {
                margin-left: em(-2px);
                margin-right: em(15px);
            }
        }

        &-text {
            letter-spacing: 0.0125em;

            &--light {
                color: $firefly05;
            }
        }
    }
}
