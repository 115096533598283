    .tabs {
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &--visible {
        overflow: visible;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            overflow: auto;
        }
    }

    background: $white;
    position: relative;

    &--branch {
        background-color: $wildSand;
        overflow: hidden;
    }

    &:after {
        content: '';
        position: absolute;
        bottom: rem(1px);
        height: rem(20px);
        left: 0;
        right: 0;
        border-bottom: rem(2px) solid $iron;
    }

    &--header {
        width: 100%;
        max-width: rem(1024px);
        margin-left: auto;
        margin-right: auto;
        color: #fff;
        padding-top: rem(23px);
        background-color: transparent;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: rem(20px);
            left: 0;
            right: 0;
            border-bottom: rem(2px) solid rgba(255, 255, 255, 0.2);
        }
    }

    &__container {
        overflow: hidden;

        &--scroll:is(.container) {
            display: none;
            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: flex;
            }
        }

        &--select:is(.container) {
            overflow: visible;
            display: flex;
            padding: 48px 16px 32px;
            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: none;
            }
        }
    }

    &__counter {
        text-shadow: none;
        padding-left: rem(6px);
    }

    &__scroll-content {
        position: relative;
        z-index: 1;
    }

    &__with-arrows {
        margin-left: 30px;
        margin-right: 30px;
    }

    &__inner {
        display: flex;
        flex-wrap: nowrap;
        position: relative;
        z-index: 1;

        &:after {
            content: '';
            width: rem(15px);
            height: rem(1px);
            display: block;
            flex-shrink: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(30px);
            }
        }
    }

    &__controls-arrows-prev,
    &__controls-arrows-next {
        background: none;
        border: none;
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        padding-top: rem(24px);
        padding-bottom: rem(24px);
        width: rem(50px);
        z-index: 1;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(30px);
        }
    }

    &__controls-arrows-prev {
        justify-content: flex-start;
        left: 0;
        background: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0.0001) 0%,
            #ffffff 100%
        );
    }

    &__controls-arrows-next {
        justify-content: flex-end;
        right: 0;
        background: linear-gradient(
            270deg,
            #ffffff 0%,
            rgba(255, 255, 255, 0.0001) 100%
        );
    }

    &__icon-arrow {
        width: rem(7px);
        color: $congressBlue;
    }

    &__tab {
        position: relative;
        flex-shrink: 0;
        font-size: rem(14px);
        line-height: 1;
        color: $limedSpruce;
        cursor: pointer;
        padding-top: rem(24px);
        padding-bottom: rem(24px);

        &:hover {
            color: $firefly;
            text-shadow: 0.5px 0 0 $firefly, 0.5px 0 0 $firefly;
        }

        &--branch-header {
            font-size: rem(14px);
            line-height: rem(24px);
            padding-top: rem(24px);
            padding-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(16px);
            }
        }

        &--slider {
            line-height: rem(24px);
            padding-top: rem(12px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(40px);
                padding-top: rem(20px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-bottom: rem(40px);
                padding-top: rem(48px);
            }
        }

        &--covid-19 {
            &:before {
                content: '';
                display: inline-block;
                vertical-align: top;
                width: 0.875rem;
                height: 0.875rem;
                margin-right: 0.5rem;
                background-repeat: no-repeat;
                background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxNCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI3IiBjeT0iNyIgcj0iNi4yNSIgc3Ryb2tlPSIjREFBQTAwIiBzdHJva2Utd2lkdGg9IjEuNSIvPjxjaXJjbGUgY3g9IjciIGN5PSI3IiByPSIzLjI1IiBzdHJva2U9IiNEODAwMjciIHN0cm9rZS13aWR0aD0iMS41Ii8+PC9zdmc+);
            }

            &.current {
                &:after {
                    background-color: #d80027 !important;
                }
            }
        }

        &-icon {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
        }

        &-img {
            margin-right: 1rem;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(16px);
            padding-top: rem(24px);
            padding-bottom: rem(30px);
        }

        &:not(:last-of-type) {
            margin-right: rem(34px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-right: rem(40px);
            }
        }

        &:last-of-type {
            margin-right: rem(34px);
        }

        &.active,
        &.current {
            color: $firefly;
            position: relative;
            transition: 0.3s ease color;
            text-shadow: 0.5px 0 0 $firefly, 0.5px 0 0 $firefly;

            &:hover {
                color: $firefly;
            }

            &:after {
                content: '';
                border-radius: rem(4px);
                background-color: $eucalyptus;
                position: absolute;
                bottom: 0;
                height: rem(4px);
                left: 0;
                right: 0;
                // border-bottom: rem(4px) solid $eucalyptus;
                z-index: 1;
            }
        }

        &--header {
            color: #fff;
            opacity: 0.6;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: 0;
            }

            &:hover {
                opacity: 0.8;
                color: $white;
                transition: 30ms;
            }

            &.active {
                color: $white;
                opacity: 1;

                &:after {
                    bottom: 1px;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        bottom: 0;
                    }
                }

                &:hover {
                    color: $white;
                }
            }
        }
    }

    .scroll-content {
        position: relative;
        z-index: 2;
    }

    .scrollbar-track-x {
        z-index: 2;
    }

    &--search-popup {
        @media screen and (min-width: #{breakpoint-min('sm')}) {
            //margin-right: rem(-45px);

            .tabs__container {
                max-width: none;
            }
        }

        @media screen and (min-width: 630px) {
            //margin-right: rem(-105px);
        }

        @media screen and (min-width: 910px) {
            //margin-right: rem(-125px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            //margin-right: rem(25px);

            .tabs__container {
                max-width: rem(820px);
            }
        }

        .tabs__container {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .tabs__tab {
            color: $firefly06;
        }
    }
}
