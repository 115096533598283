.map {
    position: relative;
    width: 100%;
    height: rem(430px);
    overflow: hidden;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        height: rem(532px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        height: rem(585px);
    }

    &__information {
        &-icon {
            position: relative;

            &::after {
                content: '';
                width: rem(12px);
                height: rem(1px);
                right: 0;
                background-color: $eucalyptus;
                top: 0;
                bottom: 0;
                position: absolute;
            }

            &::before {
                content: '';
                width: rem(12px);
                height: rem(1px);
                right: 0;
                background-color: $eucalyptus;
                top: 0;
                bottom: 0;
                position: absolute;
                transform: rotate(90deg);
            }
        }

        &-heading {
            display: block;
            color: $eucalyptus;
            font-size: rem(14px);
            line-height: rem(20px);
            letter-spacing: rem(0.2px);
            font-weight: 600;
            width: 100%;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(16px);
            }
        }

        &-wrapper {
            margin-top: rem(24px);
            padding-bottom: rem(15px);
            display: flex;
            align-items: center;
            cursor: pointer;

            &.is-active {
                .map__information-icon {
                    &::before {
                        display: none;
                    }
                }
            }
        }
    }

    &__container {
        width: 100%;
        height: 100%;

        canvas {
            filter: grayscale(1);
            -ms-filter: grayscale(1);
            -webkit-filter: grayscale(1);
            -moz-filter: grayscale(1);
            -o-filter: grayscale(1);
        }
    }

    &__control {
        position: relative;
        overflow: hidden;
        width: 48px;
        height: 48px;
        padding: 0;
        border: none;
        border-radius: 50%;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        transition: box-shadow 0.15s ease-in-out;

        &:hover {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
        }

        &:active {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
        }

        img {
            position: absolute;
            top: -5px;
            left: 50%;
            z-index: 1;
            transform: translateX(-50%);
        }
    }

    &__popup {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: $white;
        border: rem(1px) solid $mercury;
        overflow-y: auto;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: rem(374px);
        }

        &-content {
            overflow-y: auto;
            height: 100%;
            padding: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding: rem(24px);
            }
        }

        &-figure {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: percentage(220 / 326);
            margin-bottom: rem(24px);
        }

        &-image {
            width: 100%;

            &-wrapper {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                display: flex;
                align-items: center;
            }
        }

        &-title {
            margin-bottom: rem(8px);
            font-family: $foco;
            font-size: rem(24px);
            line-height: rem(30px);
        }

        &-subtitle {
            margin-bottom: rem(24px);
            font-size: rem(14px);
            line-height: (22 / 14);
            text-transform: uppercase;
            color: rgba($firefly, 0.4);
        }

        &-list {
            margin-bottom: 0;
            padding-left: 0;
            list-style: none;

            &-item:not(:last-of-type) {
                margin-bottom: rem(12px);
            }

            &-link {
                display: flex;

                &-icon {
                    flex-shrink: 0;
                    margin-top: 0.3em;
                }

                &-text {
                    font-size: rem(16px);
                    line-height: (24 / 16);
                    letter-spacing: rem(0.2px);
                    color: $firefly;
                    transition: color 0.3s ease-in-out;

                    &:hover {
                        color: $eucalyptus;
                    }

                    &:visited {
                        color: $goblin;
                    }

                    &--address {
                        transition: none;

                        &:hover {
                            color: $firefly;
                        }

                        &:visited {
                            color: $firefly;
                        }
                    }
                }
            }
        }

        &-close-button {
            position: absolute;
            top: rem(8px);
            right: rem(16px);
            z-index: 1;
            padding: rem(16px);
            line-height: 1;
            border: none;
            background-color: $white;
            border-radius: 50%;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
            transition: box-shadow 0.15s ease-in-out;

            &:hover {
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
            }

            &:active {
                box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
            }
        }
    }
}
