.modal-window {
    $parent: &;

    &--audio-player {
        .modal-window__header {
            display: flex;
            justify-content: space-between;
            padding: rem(20px) rem(16px);
            background-image: none;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-left: rem(40px);
                padding-right: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-left: rem(56px);
                padding-right: rem(56px);
            }
        }

        .modal-window__title {
            color: $firefly;
        }

        .modal-window__body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-top: 0;
        }

        .modal-window__close-button {
            margin: auto 0;

            .modal-window__close-button-text {
                display: none;
            }
        }
    }

    &__inner {
        max-width: 100%;
        height: 100%;
        margin: 0;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            max-width: rem(624px);
            height: auto;
            margin: rem(96px) auto;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            max-width: rem(736px);
        }
    }

    &__content {
        min-height: 100%;
        border: none;
        border-radius: 0;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            min-height: auto;
            border-radius: rem(6px);
        }
    }

    &__header,
    &__footer {
        border: none;
    }

    &__header {
        padding: rem(32px) rem(16px) rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: rem(240px) rem(40px) rem(40px);
            color: $white;
            background-image: linear-gradient(
                    179.94deg,
                    rgba(0, 0, 0, 0) 0.05%,
                    rgba(0, 0, 0, 0.85) 99.87%
                ),
                url('/local/templates/phosagro/img/covid-19.jpg');
            background-repeat: no-repeat;
            background-size: cover;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-left: rem(56px);
            padding-right: rem(56px);
        }
    }

    &__body {
        padding: 0 rem(16px) rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: rem(32px) rem(40px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-left: rem(56px);
            padding-right: rem(56px);
        }
    }

    &__footer {
        padding: 0 1.5rem 1.5rem;
        justify-content: center;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: 0 3rem 2.5rem;
            justify-content: flex-start;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-left: 3.5rem;
            padding-right: 3.5rem;
        }
    }

    &__title {
        font-size: rem(20px);
        line-height: (24 / 20);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(32px);
            line-height: (40 / 32);
        }
    }

    &__text {
        font-size: rem(14px);
        line-height: (22 / 14);

        &:not(:last-of-type) {
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(32px);
            }
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(16px);
            line-height: (24 / 16);
        }
    }

    &__close-button {
        display: flex;
        padding: 0;
        font-size: rem(14px);
        font-weight: bold;
        line-height: (24 / 14);
        letter-spacing: rem(0.8px);
        text-transform: uppercase;
        background-color: transparent;
        border: none;

        &-icon {
            margin-top: 0.3em;
        }

        &-text {
            margin-left: rem(16px);
        }
    }

    &__go-button {
        margin-bottom: 1.5rem;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-right: 2rem;
            margin-bottom: 0;
        }
    }

    &--msg {
        padding: 0 !important;

        #{$parent}__header {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            height: 40vh;
            padding: 1.5rem 1rem;
            color: $white;
            background-position: center;
            background-image: linear-gradient(
                    179.94deg,
                    rgba(0, 0, 0, 0) 0.05%,
                    rgba(0, 0, 0, 0.85) 99.87%
                ),
                url('/local/templates/phosagro/img/covid-19.jpg');
            background-repeat: no-repeat;
            background-size: cover;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding: 2.5rem;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-left: 3.5rem;
                padding-right: 3.5rem;
            }
        }

        #{$parent}__body {
            padding: 1rem;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding: 2rem 2.5rem;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-left: 3.5rem;
                padding-right: 3.5rem;
            }
        }
    }

    &--site-selector {
        padding: 0 !important;

        #{$parent}__inner {
            display: flex;
            align-items: center;
            height: 100%;
            margin: auto;
        }

        #{$parent}__header {
            display: flex;
            flex-flow: row nowrap;
            align-items: flex-end;
            padding: 1.5rem 1rem 1rem;
            color: $firefly;
            background: none;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding: 3.5rem 2.5rem 1rem;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-left: 3.5rem;
                padding-right: 3.5rem;
            }
        }

        #{$parent}__text {
            color: rgba($firefly, 0.5);
        }

        #{$parent}__body {
            flex: 0;
            padding: 0 1rem 1rem;

            @media screen and (min-width: #{breakpoint-min('md')}),
            screen and (min-width: #{breakpoint-min('lg')}) {
                flex: 1 1 auto;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding: 0 2.5rem 1rem;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-left: 2.9rem;
                padding-right: 3.5rem;
                padding-bottom: 1rem;
            }
        }
    }

}
.site-selector.site-selector__modal {
    margin-top: 1rem;
}
.modal-window__header.modal-sub-title {
    flex-direction: column;
    align-items: flex-start;
    padding: 2.5rem 3.5rem 1rem;
    .modal-window__text {
        margin-bottom: 0.5rem;
    }
}
.modal-sub-title + .modal-window__header {
    padding: 0.4rem 3.5rem 0rem;
}
.modal-sub-title {
    &__arrow {
        position: relative;
        padding-right: 2rem;
        &:after {
            right: 0;
            top: 0.7rem;
            position: absolute;
            content: '';
            height: 1rem;
            width: 1rem;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(/local/templates/.default/img/arrow.svg);
        }
    }
}

.modal-default {
    position: relative;
    padding: rem(45px) rem(15px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding: rem(50px) rem(65px);
    }

    &__title {
        margin-bottom: rem(30px);

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(50px);
        }
    }

    &__close {
        position: absolute;
        top: rem(20px);
        right: rem(20px);
        background-color: transparent;
        border: none;
    }
}