@keyframes loader {
    from {
    }
    to {
        transform: rotate(calc(360deg / 7));
    }
}

@keyframes petal {
    from {
    }
    50% {
        transform: scale(0.857, 0.857) translateY(15%);
    }
    to {
    }
}

@keyframes petalmax {
    from {
        transform: scale(0.8, 0.8);
    }
    to {
        transform: scale(1, 1);
    }
}

.loader {
    position: absolute;
    width: 102px;
    max-height: 83px;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
}

.petals {
    margin: 0 auto 5px;
    width: 41px;
    height: 41px;
    animation-direction: normal;
    animation: loader 0.6s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

.petal {
    position: absolute;
    width: 44%;
    height: 50%;
    top: 50%;
    left: 30%;
    transform-origin: 50% 0px;
}

.petal:after {
    content: '';
    width: 100%;
    height: 88%;
    background-color: rgba(1, 74, 133, 1);
    border-radius: 50%;
    position: absolute;
    top: 56%;
    left: 50%;
    animation: petal 1.2s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

.petal:nth-child(2) {
    transform: rotate(calc(360deg / 7));
}

.petal:nth-child(3) {
    transform: rotate(calc(360deg / 7 * 2));
}

.petal:nth-child(4) {
    transform: rotate(calc(360deg / 7 * 3));
}

.petal:nth-child(5) {
    transform: rotate(calc(360deg / 7 * 4));
}

.petal:nth-child(6) {
    transform: rotate(calc(360deg / 7 * 5));
}

.petal:nth-child(7) {
    transform: rotate(calc(360deg / 7 * 6));
}

.leaves {
    display: flex;
    justify-content: space-between;
}

.leaf {
    width: 49%;
}

.leaf svg {
    width: 100%;
}
