.search {
    position: relative;
    height: rem(40px);

    &__not-found {
        margin-top: 3rem;
        margin-bottom: 1rem;
    }

    &__block {
        &-title {
            color: #0e212f;
            opacity: 0.4;

            &--mt4 {
                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    margin-top: 4rem;
                }
            }

            &--mt2 {
                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    margin-top: 2rem;
                }
            }
        }
    }

    &__field {
        width: 100%;
        height: 100%;
        padding: rem(8px) rem(24px);
        border-radius: rem(25px);
        border: rem(1px) solid currentColor;
        color: currentColor;
        background: transparent;

        &::placeholder {
            color: rgba($firefly, 0.4);
        }
    }

    &__results {
        display: none;
        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% + #{rem(12px)});
        z-index: 1;
        overflow: auto;
        color: $firefly;
        background: $white;
        box-shadow: rem(10px) rem(10px) rem(45px) rgba(0, 0, 0, 0.07);
        border-radius: rem(3px);
        max-height: rem(80px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            max-height: rem(120px);
        }

        &-list {
            margin: 0;
            padding: 0;
            list-style: none;

            &-item {
                padding: 0;
            }
        }

        &-result {
            width: 100%;
            padding: rem(8px) rem(24px);
            font-size: rem(16px);
            line-height: (24 / 16);
            letter-spacing: rem(0.2px);
        }
    }
}
