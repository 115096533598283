.video-card {
    height: 100%;
    display: flex;
    flex-direction: column;

    &__top {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &__title {
        margin-top: rem(24px);
        margin-bottom: rem(25px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(35px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-top: rem(32px);
            margin-bottom: rem(64px);
        }
    }

    &__date {
        font-family: $foco;
        font-weight: bold;
        font-size: rem(16px);
        margin-top: auto;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(20px);
        }
    }
}

.video {
    display: block;
    position: relative;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - #{rem(12px)});
        left: calc(50% - #{rem(10px)});
        z-index: 1;

        width: 0;
        height: 0;
        border-top: rem(14px) solid transparent;
        border-left: rem(20px) solid $white;
        border-bottom: rem(14px) solid transparent;
    }
}

.video--adaptive {
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
