.product-details-description {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        flex-direction: row;
    }

    &__figure {
        flex-shrink: 0;
        max-width: 40%;
        margin-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            max-width: rem(175px);
            margin-bottom: 0;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            max-width: rem(190px);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-left: rem(48px);
        }
    }

    &__header {
        margin-bottom: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(24px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-bottom: rem(32px);
        }
    }

    &__footer {
        margin-top: auto;
        margin-bottom: 10px;
    }

    &__image {
        max-width: 100%;
    }

    &__button {
        min-width: rem(288px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            min-width: rem(196px);
        }
    }
}
