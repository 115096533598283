.gallery-page {
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: rem(32px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(56px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(70px);
        }
    }

    &__events {
        padding-bottom: rem(56px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(72px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(80px);
        }
    }

    &__enterprises {
        padding-bottom: rem(64px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(100px);
        }

        &-bottom {
            margin-top: rem(40px);
        }
    }

    &__video {
        padding-top: rem(48px);
        padding-bottom: rem(64px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(72px);
            padding-bottom: rem(96px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(96px);
        }

        &-row {
            margin-bottom: rem(-48px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(-96px);
            }
        }

        &-col {
            margin-bottom: rem(48px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(96px);
            }
        }

        .video-card__video {
            img {
                aspect-ratio: 1.73;
                object-fit: cover;
            }
        }
    }
}
