.product-details-table {
    width: 100%;
    border-collapse: collapse;

    & td {
        padding: 0;
        font-size: rem(16px);
        line-height: (24 / 16);
        letter-spacing: rem(0.2px);
        vertical-align: top;

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            font-size: rem(20px);
            letter-spacing: rem(0.25px);
        }

        &:first-child {
            padding-right: rem(8px);
        }
    }

    & tr:not(:last-of-type) td {
        padding-bottom: rem(32px);
    }
}
