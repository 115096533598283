.year-control {
    position: relative;

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding-top: rem(34px);
        padding-bottom: rem(34px);
    }

    &--fixed {
        overflow: hidden;
        box-shadow: 0px -4px 8px rgba(148, 148, 148, 0.08);
        background-color: $white;
    }

    &--fixed & {
        &__inner {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-left: rem(16px);
            padding-right: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-left: rem(24px);
                padding-right: rem(24px);
            }
        }

        &__item {
            padding-left: rem(16px);
            padding-right: rem(16px);
            font-size: rem(16px);
            line-height: (24 / 16);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-left: rem(24px);
                padding-right: rem(24px);
                font-size: rem(24px);
                line-height: (40 / 24);
            }
        }
    }

    * {
        outline: 0;
    }

    &__item {
        padding-top: rem(12px);
        padding-bottom: rem(12px);
        font-size: rem(24px);
        line-height: (40 / 24);
        color: rgba($black, 0.4) !important;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
        transition: color 150ms ease-in-out;

        &.is-active {
            color: $black !important;
            font-weight: bold;
        }

        &:hover {
            color: $black;
        }
    }

    &__arrow {
        position: absolute;
        z-index: 1;
        width: 100%;
        padding: rem(11px);
        color: $firefly;
        text-align: center;
        cursor: pointer;
        border: 0;
        background-color: transparent;

        &:focus {
            outline: 0;
        }

        &--previous {
            top: 0;
            transform: rotate(180deg) translateY(100%);
        }

        &--next {
            bottom: 0;
            transform: translateY(100%);
        }
    }

    & .slick-slide {
        height: auto !important;
    }
}
