.company {
    &__about {
        &-main {
            padding-top: rem(40px);
            padding-bottom: rem(60px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(65px);
                padding-bottom: rem(120px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(70px);
                padding-bottom: rem(90px);
            }
        }

        &-geography {
            padding-bottom: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(120px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-bottom: rem(130px);
            }

            &-figure {
                position: absolute;
                height: 100%;
                left: rem(-16px);
                right: rem(-16px);
                overflow: hidden;

                @media screen and (min-width: #{breakpoint-min('sm')}) {
                    left: 0;
                    right: 0;
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    left: auto;
                    right: 0;
                    width: 50vw;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: auto;
                }

                &-container {
                    position: relative;
                    height: rem(305px);
                    margin-bottom: rem(30px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-bottom: 0;
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        height: rem(475px);
                    }
                }

                &-img {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 100%;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        object-fit: cover;
                        font-family: 'object-fit: cover;';
                        width: 100%;
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        width: auto;
                        position: relative;
                        left: 0;
                        transform: translateX(0);
                    }
                }

                &-link {
                    cursor: pointer;
                }
            }
        }

        &-phos {
            padding-bottom: rem(60px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(85px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-bottom: rem(170px);
            }

            &-logo {
                height: rem(40px);
                margin-bottom: rem(40px);
                display: flex;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(68px);
                }

                &-img {
                    height: 100%;
                }
            }
        }

        &-production {
            padding-bottom: rem(60px);
            margin: auto;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                max-width: 888px;
                padding-bottom: rem(160px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                max-width: 1296px;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-bottom: rem(170px);
            }
        }

        &-title {
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(-80px);
                margin-bottom: rem(56px);
            }
        }
    }

    &__concern {
        padding-bottom: rem(90px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(120px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(190px);
        }

        &-title {
            margin-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(48px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(80px);
            }
        }
    }

    &__geography {
        padding-bottom: rem(48px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(56px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(80px);
        }

        &-title {
            margin-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(48px);
            }
        }

        &-text {
            margin-bottom: rem(48px);
        }

        &-info-tabs {
            margin-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(48px);
            }
        }

        &-statistics {
            position: relative;
            z-index: 1;
            padding-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(120px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: 0;
            }

            &-list {
                display: block;
                margin-bottom: 0;
                padding-left: 0;
                list-style: none;

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: flex;
                }
            }

            &-item {
                display: flex;
                align-items: center;
                margin-bottom: rem(32px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: block;
                    // margin-bottom: 0;

                    &:nth-of-type(3n) {
                        padding-right: 0;
                    }
                }
            }

            &-icon {
                width: rem(32px);
                height: rem(32px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: rem(48px);
                    height: rem(48px);
                }
            }

            &-info {
                display: flex;
                align-items: flex-start;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(16px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: rem(20px);
                }

                &-count {
                    position: relative;
                    /*top: rem(16px);*/
                    min-width: rem(60px);
                    margin-left: rem(16px);
                    margin-right: rem(16px);
                    font-size: rem(32px);
                    line-height: (39 / 32);
                    text-align: right;

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        min-width: auto;
                        margin-left: 0;
                    }
                }

                &-description {
                    line-height: rem(16px);
                }
            }

            &-detail {
                position: relative;
                z-index: 1;
                width: rem(226px);
                height: rem(226px);
                margin: rem(56px) auto 0;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    position: absolute;
                    top: rem(50px);
                    right: rem(-24px);
                    width: rem(336px);
                    height: rem(336px);
                    margin: 0;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    right: rem(24px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    right: rem(-525px);
                    top: rem(-525px);
                }

                &-inner {
                    position: relative;
                    z-index: 2;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    text-align: center;
                }

                &-count {
                    margin-bottom: rem(16px);
                    font-family: $foco;
                    font-size: rem(80px);
                    font-weight: bold;
                    line-height: 1;
                    color: $congressBlue;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        font-size: rem(136px);
                    }
                }

                &-description {
                    width: 100%;
                    max-width: rem(180px);
                    margin-left: auto;
                    margin-right: auto;
                    font-size: rem(16px);
                    font-weight: bold;
                    line-height: (20 / 16);
                    letter-spacing: rem(0.2px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        max-width: rem(220px);
                    }
                }

                &-circle {
                    position: absolute;

                    &--main,
                    &--first,
                    &--second,
                    &--third {
                        top: 0;
                        left: 0;

                        width: rem(226px);
                        height: rem(226px);

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            width: rem(336px);
                            height: rem(336px);
                        }
                    }

                    &--first,
                    &--second,
                    &--third {
                        background-color: $glacier;
                    }
                }
            }

            &-map {
                margin-bottom: rem(48px);

                &--world {
                    margin-top: rem(48px);
                    margin-left: -10vw;
                    margin-right: -10vw;
                }

                &--russia {
                    margin-left: -2vw;
                    margin-right: -2vw;
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    &--world {
                        margin-top: rem(80px);
                    }

                    &--russia {
                        margin-left: -8vw;
                        margin-right: -8vw;
                    }
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    &--russia {
                        margin-left: -3vw;
                        margin-right: -3vw;
                    }
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-right: percentage(-(4 / 7));
                    margin-left: 0;

                    &--world {
                        margin-top: rem(48px);
                    }
                }

                &-image {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &-participation {
            &-controls {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    justify-content: flex-start;
                }
            }

            &-title {
                margin-bottom: rem(32px);
                font-size: rem(16px);
                line-height: (20 / 16);
                letter-spacing: rem(0.2px);

                @media screen and (min-width: #{breakpoint-min('sm')}) {
                    font-size: rem(24px);
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                    font-size: rem(32px);
                    line-height: 1;
                    letter-spacing: 0;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    width: 100%;
                }

                &--contacts {
                    font-size: rem(24px);
                    line-height: (28/24);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        font-size: rem(40px);
                        line-height: (44/40);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(56px);
                        line-height: (60/56);
                    }
                }
            }

            &-tabs {
                display: flex;
                margin-bottom: rem(32px);
                padding-left: 0;
                list-style: none;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(48px);
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    margin-right: rem(64px);
                }

                &-item {
                    flex-grow: 1;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        flex-grow: 0;
                    }
                }

                &-item:first-of-type &-tab {
                    border-radius: rem(32px) 0px 0px rem(32px);
                    border-right: none;
                }

                &-item:last-of-type &-tab {
                    border-radius: 0 rem(32px) rem(32px) 0;
                }

                &-item:not(:first-of-type) &-tab {
                    border-left: none;
                }

                &-tab {
                    display: block;
                    padding: rem(9px) rem(23px);
                    font-size: rem(16px);
                    line-height: (20 / 16);
                    text-align: center;
                    color: $firefly;
                    border: rem(1px) solid rgba($firefly, 0.2);

                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                        color: $white;
                        background: $eucalyptus;
                        border-color: $eucalyptus;
                    }
                }
            }

            &-filters {
                display: none;
                margin-top: 0;
                margin-bottom: rem(46px);
                padding-left: 0;
                list-style: none;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    display: flex;
                    justify-content: space-between;
                    flex-grow: 1;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(40px);
                    max-width: rem(680px);
                }

                &-filter {
                    padding: rem(9px) rem(23px);
                    font-size: rem(16px);
                    line-height: (20 / 16);
                    color: $firefly;
                    border: rem(1px) solid rgba($firefly, 0.2);
                    border-radius: rem(32px);

                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                        color: $white;
                        background: $eucalyptus;
                        border-color: $eucalyptus;
                    }
                }
            }

            &-select {
                margin-bottom: rem(32px);
            }

            //   &-list {
            //     margin-top: rem(40px);
            //   }

            &-tabs {
                &-pane {
                    margin-bottom: rem(-48px);

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        margin-bottom: rem(-64px);
                    }
                }
            }

            &-contacts {
                margin-bottom: rem(48px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(64px);
                }

                &-title {
                    margin-bottom: rem(32px);
                    font-size: rem(24px);
                    line-height: (30 / 24);
                }

                &-list {
                    margin-bottom: 0;
                    padding-left: 0;
                    list-style: none;
                }

                &-item:not(:last-of-type) {
                    margin-bottom: rem(12px);
                }

                &-link {
                    display: flex;

                    &-icon {
                        flex-shrink: 0;
                        margin-top: 0.3em;
                    }

                    &-text {
                        font-size: rem(16px);
                        line-height: (24 / 16);
                        letter-spacing: rem(0.2px);
                        color: $firefly;

                        &:hover {
                            color: $eucalyptus;
                        }

                        &:visited {
                            color: $goblin;
                        }
                    }
                }
            }
        }
    }

    &__history-controls-arrows {
        display: none;

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            display: flex;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-left: rem(310px);
        }
    }

    &__hystory-controls-arrows-prev {
        margin-right: rem(75px);
    }

    &__history-title {
        margin-bottom: 24px;
    }

    &__history-title-wrapper {
        margin-bottom: rem(32px);

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-top: rem(24px);
            margin-bottom: rem(48px);
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            justify-content: flex-start;
        }
    }

    &__history {
        padding-bottom: rem(86px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(120px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(176px);
        }

        &-info {
            position: relative;
            z-index: 2;
            display: flex;
            margin-bottom: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                flex-direction: column;
                margin-top: rem(88px);
                margin-bottom: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: 0;
            }
        }

        &-figure {
            width: rem(50px);
            height: rem(50px);
            border-radius: 50%;
            overflow: hidden;
            margin-right: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(100px);
                height: rem(100px);
                margin-bottom: rem(32px);
                margin-right: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: rem(150px);
                height: rem(150px);
            }

            &-img {
                height: 100%;
            }
        }

        &-year {
            font-family: $foco;
            font-size: rem(60px);
            font-weight: bold;
            line-height: 1;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(64px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(96px);
            }
        }

        &-slider {
            position: relative;

            &:before {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                right: 100%;
                background: $white;
                left: -100vw;
                z-index: 1;
            }

            .owl-stage-outer {
                overflow: visible;
            }

            .owl-dots {
                position: absolute;
                left: -100vw;
            }

            .owl-item {
                color: $silverSand;
                transition: color 0.3s ease;

                &.active {
                    color: $firefly;

                    & + .active {
                        color: $silverSand;
                    }
                }
            }

            &-controls {
                position: relative;
                margin-top: rem(30px);
                padding-top: rem(20px);
                padding-bottom: rem(20px + 22px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(45px);
                    padding-top: rem(22px);
                    padding-bottom: rem(22px + 50px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: rem(25px);
                }

                &-dots {
                    position: relative;
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    margin-left: rem(14px);

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        margin-left: 0;
                    }
                }

                &-dot {
                    position: relative;
                    width: rem(6px);
                    height: rem(6px);
                    background: $iron;
                    border-radius: 50%;
                    cursor: pointer;
                    flex-shrink: 0;

                    //@include click-area;

                    &:not(:last-of-type) {
                        margin-right: rem(48px);
                    }

                    &--with-year {
                        width: rem(12px);
                        height: rem(12px);
                        margin-right: rem(64px);

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            width: rem(16px);
                            height: rem(16px);
                        }

                        &:not(:first-of-type) {
                            margin-left: rem(64px - 48px);
                        }

                        .-dragger + & {
                            margin-left: 0;
                        }
                    }

                    &-year {
                        font-family: $foco;
                        font-size: rem(16px);
                        font-weight: bold;
                        color: $iron07;

                        position: absolute;
                        top: rem(32px);
                        left: 50%;
                        transform: translateX(-50%);

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            font-size: rem(24px);
                            top: rem(53px);
                        }
                    }
                }

                &-dragger {
                    position: absolute;
                    left: 0;
                    top: 0;

                    &-before {
                        position: absolute;
                        top: rem(-14px);
                        left: rem(-14px);
                        z-index: 1;
                        width: rem(40px);
                        height: rem(40px);
                        background: $white;
                        border-radius: 50%;
                        box-shadow: 0 rem(2px) rem(5px) 0 rgba(0, 0, 0, 0.15);
                        transition: all 0.3s ease;

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            top: rem(-20px);
                            left: rem(-20px);
                            width: rem(56px);
                            height: rem(56px);
                        }
                    }

                    &-dot {
                        width: rem(12px);
                        height: rem(12px);
                        background: $congressBlue;
                        border-radius: 50%;
                        cursor: pointer;
                        position: relative;
                        z-index: 1;

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            width: rem(16px);
                            height: rem(16px);
                        }
                    }
                }

                &-line {
                    height: rem(20px);
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    top: rem(26px);
                    border-top: rem(1px) dashed $iron;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        top: rem(30px);
                    }
                }
            }
        }

        &-slide {
            max-width: 100%;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(384px);
                min-height: rem(290px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: rem(704px);
            }
        }
    }

    &__place {
        width: rem(228px);
        margin-top: rem(60px);
        margin-left: auto;
        margin-right: auto;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: 0;;
        }

        @include breakpoint(desktop) {
            width: rem(364px);
        }

        &-info {
            position: relative;
            flex-shrink: 0;
            width: rem(226px);
            height: rem(226px);
            margin: rem(56px) auto 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                height: rem(228px);
                width: 100%;
                left: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                height: rem(364px);
            }

            &--circle {
                height: rem(228px);


                .company__place-info-text--small,
                .company__place-info-num--small,
                .company__place-info-title {
                    font-size: .875rem;
                }

                .company__place-info-place ~ .company__place-info-place {
                    margin-top: 1.5rem;
                }

                .company__place-info-title {
                    font-weight: 800;
                    color: rgb(14, 33, 47);
                    margin-bottom: 1rem;
                }

                @media screen and (max-width: #{breakpoint-max('md')}) {
                    .company__place-info-text--small {
                        max-width: 100%;
                    }
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    height: rem(256px);
                    width: 100%;
                    left: 0;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    height: rem(336px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    height: rem(364px);
                }
            }

            &-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;

                position: relative;
                z-index: 2;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: rem(228px);
                    left: auto;
                    position: absolute;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    right: 0;
                    width: 100%;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: 100%;
                    left: 0;
                    right: auto;
                    position: relative;
                }
            }

            &-place {
                display: flex;
                justify-content: flex-start;
            }

            &-num_no {
                display: inline-block;
                font-family: $foco;
                font-size: rem(104px);
                font-weight: bold;
                line-height: 0.81;
                height: em(70px, 104px);
                color: $congressBlue;

                &::before {
                    content: 'No.';
                    font-family: $calibry;
                    font-size: rem(16px);
                    font-weight: bold;
                    letter-spacing: 0.2px;
                    color: $congressBlue;
                }
            }

            &-num {
                display: inline-block;
                font-family: $foco;
                font-size: rem(104px);
                font-weight: bold;
                line-height: 0.81;
                height: em(70px, 104px);
                color: $congressBlue;

                &--min {
                    font-size: rem(64px);
                }

                &--small {
                    font-size: rem(24px);
                    color: #0E212F;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(136px);

                    &--min {
                        font-size: rem(96px);
                    }

                    &--small {
                        font-size: rem(16px);
                    }
                }

                &-text {
                    display: inline-block;
                    font-family: $calibry;
                    font-weight: bold;
                    font-size: rem(16px);
                    color: $congressBlue;
                    margin-left: rem(-10px);
                }
            }

            &-text {
                font-weight: bold;
                margin-top: rem(10px);
                text-align: center;

                &--small {
                    max-width: 220px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #0E212F;
                    opacity: 0.7;
                }
            }

            &-circles {
                width: rem(228px);
                margin: 0 auto;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: auto;
                }
            }

            &-circle {
                position: absolute;

                &--main,
                &--first,
                &--second,
                &--third {
                    top: 0;

                    height: rem(228px);
                    width: rem(228px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        position: absolute;
                        left: auto;
                        transform: none;
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        right: auto;
                        height: rem(364px);
                        width: rem(364px);
                    }
                }

                &--first,
                &--second,
                &--third {
                    background-color: $glacier;
                }
            }

            &-circle2 {
                position: absolute;

                &--main,
                &--first,
                &--second,
                &--third {
                    top: 50%;
                    left: 50%;
                    margin-left: rem(-144px);
                    margin-top: rem(-144px);
                    height: rem(288px);
                    width: rem(288px);

                    @media screen and (min-width: #{breakpoint-min('sm')}) {
                        margin-left: rem(-168px);
                        margin-top: rem(-168px);
                        height: rem(336px);
                        width: rem(336px);
                    }

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-left: rem(-118px);
                        margin-top: rem(-144px);
                        height: rem(288px);
                        width: rem(288px);
                    }

                    @media screen and (min-width: #{breakpoint-min('lg')}) {
                        margin-left: rem(-168px);
                        margin-top: rem(-168px);
                        height: rem(336px);
                        width: rem(336px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        margin-top: rem(-182px);
                        margin-left: rem(-182px);
                        height: rem(364px);
                        width: rem(364px);
                    }
                }

                &--first,
                &--second,
                &--third {
                    background-color: $glacier;
                }
            }
        }

        &-caption {
            max-width: rem(240px);
            margin-left: auto;
            margin-right: auto;
            font-weight: bold;
            text-align: center;
            margin-top: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: rem(45px);
            }
        }
    }

    &--development {
        & .company__about-phos--development {
            padding-top: rem(48px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(64px);
            }
        }

        & .h4--development {
            padding-bottom: rem(32px);
            font-weight: bold;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(64px);
            }
        }

        & .h3--development {
            padding-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(32px);
            }
        }
    }
}
