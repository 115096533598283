.circled-text {
    &__place {
        margin-top: rem(60px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: 0;
        }

        &-info {
            height: rem(228px);
            position: relative;
            flex-shrink: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(255px);
                height: rem(255px);
                left: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: rem(336px);
                height: rem(336px);
            }

            &--circle {
                height: rem(228px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    height: rem(256px);
                    width: 100%;
                    left: 0;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    height: rem(336px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    height: rem(364px);
                }
            }

            &-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: rem(150px);
                height: 100%;
                margin: 0 auto;

                text-align: center;

                position: relative;
                z-index: 2;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: rem(180px);
                    left: 50%;
                    margin-right: -25%;
                    transform: translate(-50%, 0);

                    position: absolute;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    right: rem(16px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: 100%;
                    right: auto;
                    position: relative;
                    margin: 0;
                }

                &--md {
                    width: rem(175px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        width: rem(200px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        width: rem(244px);
                    }
                }

                &--circle {
                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        width: rem(256px);
                    }

                    @media screen and (min-width: #{breakpoint-min('lg')}) {
                        width: 100%;
                    }
                }
            }

            &-place {
                display: flex;
                justify-content: flex-start;
            }

            &-num_no {
                display: inline-block;
                font-family: $foco;
                font-weight: bold;
                height: em(70px, 104px);
                color: $congressBlue;

                &::before {
                    content: 'No.';
                    font-family: $calibry;
                    font-size: rem(16px);
                    font-weight: bold;
                    letter-spacing: 0.2px;
                    color: $congressBlue;
                }
            }

            &-num {
                display: inline-block;
                font-family: $foco;
                font-size: rem(40px);
                font-weight: bold;
                line-height: 0.81;
                //height: em(70px, 104px);
                color: $congressBlue;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(48px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(64px);
                }

                &--min {
                    font-size: rem(64px);
                }

                &--xs {
                    font-size: rem(20px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        font-size: rem(24px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(32px);
                    }
                }

                //@media screen and (min-width: #{breakpoint-min('xl')}) {
                //  font-size: rem(136px);
                //
                //  &--min {
                //    font-size: rem(96px);
                //  }
                //}

                &-text {
                    display: inline-block;
                    font-family: $calibry;
                    font-weight: bold;
                    font-size: rem(16px);
                    color: $congressBlue;
                    margin-left: rem(-10px);
                }
            }

            &-text {
                font-weight: bold;
                margin-top: rem(10px);
                font-size: rem(14px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(16px);
                }

                &--md {
                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        width: rem(200px);
                    }
                }
            }

            &-circles {
                width: rem(228px);
                margin: 0 auto;

                @media screen and (max-width: #{breakpoint-min('md')}) {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    top: 0;
                }
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    position: relative;
                    width: auto;
                }
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    position: unset;
                }
            }

            &-circle {
                position: absolute;

                &--main,
                &--first,
                &--second,
                &--third {
                    top: 0;

                    height: rem(228px);
                    width: rem(228px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        position: absolute;
                        left: auto;
                        right: 0;
                        transform: none;

                        width: rem(255px);
                        height: rem(255px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        height: rem(335px);
                        width: rem(335px);
                    }
                }

                &--first,
                &--second,
                &--third {
                    background-color: $glacier;
                }
            }

            &-circle2 {
                position: absolute;

                &--main,
                &--first,
                &--second,
                &--third {
                    top: 0;

                    height: rem(228px);
                    width: rem(228px);

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        position: absolute;
                        left: auto;
                        right: rem(-26px);
                        transform: none;

                        height: rem(256px);
                        width: rem(256px);
                    }

                    //@media screen and (min-width: #{breakpoint-min('lg')}) {
                    //  right: rem(16px);
                    //
                    //  height: rem(336px);
                    //  width: rem(336px);
                    //}

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        //left: rem(32px);
                        //right: auto;

                        height: rem(364px);
                        width: rem(364px);
                    }
                }

                &--first,
                &--second,
                &--third {
                    background-color: $glacier;
                }
            }
        }

        &-caption {
            max-width: rem(240px);
            margin-left: auto;
            margin-right: auto;
            font-weight: bold;
            text-align: center;
            margin-top: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: rem(45px);
            }
        }
    }
}
