.breadcrumbs {
    &--header {
        margin-bottom: rem(24px);

        & + .header__banner-header {
            margin-top: 0;
        }
    }

    &--content {
        margin-top: rem(32px);
        margin-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(32px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-bottom: rem(48px);
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;
        overflow: hidden;

        &-item {
            list-style: none;

            &:not(:last-of-type) {
                margin-right: rem(10px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-right: rem(16px);
                }
            }
        }
    }

    &__list-item:first-of-type &__breadcrumb &__text {
        width: auto;
    }

    &__text {
        display: block;
        max-width: calc(37vw - #{rem(12px)});
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            max-width: calc(29vw - #{rem(12px)});
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            max-width: calc(18vw - #{rem(12px)});
        }
    }

    &__breadcrumb {
        display: flex;
        transition: color 0.3s ease;
        color: transparentize($firefly, 0.2);

        &:hover {
            color: $firefly;
        }

        &--header {
            color: transparentize($white, 0.2);

            &:hover {
                color: $white;
            }
        }

        &--content {
            color: rgba($firefly, 0.8);

            &:hover {
                color: $firefly;
            }
        }

        &-icon {
            font-size: rem(11px);
            margin-top: 0.6em;
            margin-left: rem(16px);
        }
    }
}
