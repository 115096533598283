@function rem($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1rem;
}

@function em($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return $pixels / $context * 1em;
}

$pi: 3.14159265359;
$_precision: 10;

@function pow($base, $exp) {
    $value: $base;
    @if $exp > 1 {
        @for $i from 2 through $exp {
            $value: $value * $base;
        }
    }
    @if $exp < 1 {
        @for $i from 0 through -$exp {
            $value: $value / $base;
        }
    }
    @return $value;
}

@function fact($num) {
    $fact: 1;
    @if $num > 0 {
        @for $i from 1 through $num {
            $fact: $fact * $i;
        }
    }
    @return $fact;
}

@function _to_unitless_rad($angle) {
    @if unit($angle) == 'deg' {
        $angle: $angle / 180deg * $pi;
    }
    @if unit($angle) == 'rad' {
        $angle: $angle / 1rad;
    }
    @return $angle;
}

@function sin($angle) {
    $a: _to_unitless_rad($angle);
    $sin: $a;
    @for $n from 1 through $_precision {
        $sin: $sin + (pow(-1, $n) / fact(2 * $n + 1)) * pow($a, (2 * $n + 1));
    }
    @return $sin;
}

@function cos($angle) {
    $a: _to_unitless_rad($angle);
    $cos: 1;
    @for $n from 1 through $_precision {
        $cos: $cos + (pow(-1, $n) / fact(2 * $n)) * pow($a, 2 * $n);
    }
    @return $cos;
}

@function tan($angle) {
    @return sin($angle) / cos($angle);
}
