.year-control-container {
    @media screen and (min-width: #{breakpoint-min('xl')}) {
        position: sticky;
        top: rem(150px);
        bottom: none;
    }

    &--fixed {
        position: fixed;
        z-index: 4;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $white;
    }
}
