.detail-card {
    &__heading {
        margin-bottom: rem(16px);
        letter-spacing: rem(0.25px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(24px);
        }
    }

    &__text,
    &__icon {
        margin-bottom: rem(24px);
    }
}
