.contact {
    margin-left: rem(16px);
    padding-left: 0;

    &__item {
        color: $grayChateau;
        list-style: none;
        font-size: rem(14px);
        letter-spacing: rem(0.175px);
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        &__item {
            font-size: rem(16px);
            letter-spacing: rem(0.2px);
        }
    }
}
