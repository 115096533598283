.h1,
h1 {
    font-family: $foco, $fonts-default;
    font-size: rem(28px);
    line-height: (36/28);
    font-weight: bold;
    margin: 0;

    &--huge {
        font-size: rem(48px);
        line-height: (60 / 48);
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        font-size: rem(48px);
        line-height: (60/48);

        &--huge {
            font-size: rem(64px);
            line-height: 1;
        }
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        font-size: rem(56px);
        line-height: (72/64);

        &--huge {
            font-size: rem(104px);
        }
    }
}

.h2,
h2 {
    font-family: $foco, $fonts-default;
    font-size: rem(24px);
    line-height: (28/24);
    font-weight: bold;
    margin: 0;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        font-size: rem(40px);
        line-height: (44/40);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        font-size: rem(56px);
        line-height: (60/56);

        &--big {
            font-size: rem(64px);
            line-height: (72/64);
        }
    }
}

.h3,
h3 {
    font-family: $foco, $fonts-default;
    font-size: rem(20px);
    line-height: (28/20);
    font-weight: bold;
    margin: 0;

    &--small {
        font-size: rem(20px);
        line-height: (32/20);
    }

    &--medium {
        font-size: rem(24px);
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        font-size: rem(24px);
        line-height: (32/24);

        &--small {
            font-size: rem(20px);
            line-height: (32/20);
        }

        &--fixed-width {
            max-width: rem(288px);
        }
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        &--fixed-width {
            max-width: rem(330px);
        }
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        font-size: rem(32px);
        line-height: (40/32);

        &--small {
            font-size: rem(20px);
            line-height: (32/20);
        }
    }
}

.h4,
h4 {
    font-family: $foco, $fonts-default;
    font-size: rem(16px);
    line-height: (24/16);
    font-weight: bold;
    margin: 0;

    &--mod {
        font-family: $calibry, $fonts-default;
    }

    &--small {
        font-size: rem(14px);
        line-height: (22/14);
    }

    &--medium {
        font-size: rem(16px);
    }

    &--large {
        font-size: rem(24px);
        line-height: (32 / 24);
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        font-size: rem(16px);
        line-height: (24/16);

        &--small {
            font-size: rem(14px);
            line-height: (22/14);
        }

        &--large {
            font-size: rem(24px);
            line-height: (32 / 24);
        }

        &--big {
            font-size: rem(24px);
            line-height: (36/24);
        }
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        font-size: rem(20px);
        line-height: (32/20);

        &--large {
            font-size: rem(24px);
            line-height: (32 / 24);
        }

        &--big {
            font-size: rem(24px);
            line-height: (36/24);
        }
    }
}

.h5,
h5 {
    @media screen and (min-width: #{breakpoint-min('xl')}) {
        font-size: rem(18px);
        line-height: (26/18);

        &--small {
            font-size: rem(16px);
        }
    }
}

.p,
p {
    font-family: $calibry, $fonts-default;
    font-size: rem(14px);
    line-height: (22/14);
    margin: 0;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        font-size: rem(16px);
        line-height: (24/16);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        font-size: rem(16px);
        line-height: (24/16);
    }

    &--light {
        color: $firefly05;
    }

    &--small {
        font-size: 0.875rem;
        line-height: 1.125rem;
    }

    &--middle {
        font-size: 1rem;
        line-height: 1.5rem;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            font-size: 1.25rem;
            line-height: 2rem;
        }
    }

    &--big {
        font-size: 1rem;
        line-height: 1.5rem;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: 1.25rem;
            line-height: 1.75rem;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            font-size: 1.5rem;
            line-height: 2rem;
        }
    }
}

ul li,
ol li {
    font-family: $calibry, $fonts-default;
    font-size: rem(14px);
    line-height: (22/14);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        font-size: rem(16px);
        line-height: (24/16);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        font-size: rem(16px);
        line-height: (24/16);
    }
}

.small-title {
    font-size: rem(14px);
    font-weight: bold;
    line-height: (17/14);
    letter-spacing: rem(0.8px);
    text-transform: uppercase;
    color: $silverSand;

    &--mb-16px {
        margin-bottom: rem(16px);
    }
}

.lead {
    font-size: rem(16px);
    line-height: (24/16);
    font-weight: bold;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        font-size: rem(24px);
        line-height: (36/24);
    }
}

.font-size {
    &--12 {
        font-size: 0.75rem;
    }

    &--14 {
        font-size: 0.875rem;
    }

    &--16 {
        font-size: 1rem;
    }

    &--18 {
        font-size: 1.125rem;
    }

    &--20 {
        font-size: 1.25rem;
    }

    &--24 {
        font-size: 1.5rem;
    }
}

.font-weight {
    &--n {
        font-weight: normal;
    }
}
