@mixin typo-level($variable, $important: false) {
    $imp:"";
    @if $important {
        $imp:"!important"
    }

    font-size: map-get(map-get(map-get($typography, $variable), mobile), size) #{$imp};

    $desktop: map-get(map-get($typography, $variable), desktop);
    $tablet: map-get(map-get($typography, $variable), tablet);
    $vTablet: map-get(map-get($typography, $variable), v-tablet);
    $mobile: map-get(map-get($typography, $variable), mobile);



    @if $vTablet {
        @include breakpoint(v-mobile) {
            font-size: map-get(map-get(map-get($typography, $variable), v-tablet), size) #{$imp};
        }
    }

    @if $tablet {
        @include breakpoint(mobile) {
            font-size: map-get(map-get(map-get($typography, $variable), tablet), size) #{$imp};
        }
    }

    @if $desktop {
        @include breakpoint(tablet) {
            font-size: map-get(map-get(map-get($typography, $variable), desktop), size) #{$imp};
        }
    }
}
