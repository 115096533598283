.development {
    &__circles-title {
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 75%;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            width: 70%;
        }
    }

    &__slider {
        .owl-stage {
            transition: none !important;
        }
        .owl-item {
            width: 100% !important;
            margin-left: 3px !important;
            opacity: 0 !important;
        }

        .owl-item.active {
            opacity: 1 !important;
            transition: opacity 400ms ease-in-out;
        }

        &-slide {
            display: block;
        }

        &-slide:hover &-text {
            color: $firefly !important;
        }

        &-figure {
            margin-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: 0;
            }
        }

        &-content {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-bottom: rem(96px);
            }
        }

        &-text {
            font-weight: normal;
        }

        &-controls {
            position: relative;
            z-index: 1;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(-63px) !important;

                &--invest {
                    margin-top: rem(48px) !important;
                }
            }

            &-inner {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: rem(300px);
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    width: rem(500px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: rem(180px);
                }
            }
        }

        &-button {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                min-width: auto !important;
                width: auto !important;
                max-width: auto !important;
            }
        }
    }
}
