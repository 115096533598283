/* This stylesheet generated by Transfonter (https://transfonter.org) on April 24, 2018 3:36 PM */

$fontsPath: '../fonts/';

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontsPath}Calibri.eot');
    src: url('#{$fontsPath}Calibri.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Calibri.woff2') format('woff2'),
        url('#{$fontsPath}Calibri.woff') format('woff'),
        url('#{$fontsPath}Calibri.ttf') format('truetype'),
        url('#{$fontsPath}Calibri.svg#Calibri') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Regular.eot');
    src: url('#{$fontsPath}Foco-Regular.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Foco-Regular.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Regular.woff') format('woff'),
        url('#{$fontsPath}Foco-Regular.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Regular.svg#Foco-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Italic.eot');
    src: url('#{$fontsPath}Foco-Italic.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Foco-Italic.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Italic.woff') format('woff'),
        url('#{$fontsPath}Foco-Italic.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Italic.svg#Foco-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Light.eot');
    src: url('#{$fontsPath}Foco-Light.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Foco-Light.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Light.woff') format('woff'),
        url('#{$fontsPath}Foco-Light.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Light.svg#Foco-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Regular_1.eot');
    src: url('#{$fontsPath}Foco-Regular_1.eot?#iefix')
            format('embedded-opentype'),
        url('#{$fontsPath}Foco-Regular_1.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Regular_1.woff') format('woff'),
        url('#{$fontsPath}Foco-Regular_1.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Regular_1.svg#Foco-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Bold.eot');
    src: url('#{$fontsPath}Foco-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Foco-Bold.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Bold.woff') format('woff'),
        url('#{$fontsPath}Foco-Bold.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Bold.svg#Foco-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Bold_1.eot');
    src: url('#{$fontsPath}Foco-Bold_1.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Foco-Bold_1.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Bold_1.woff') format('woff'),
        url('#{$fontsPath}Foco-Bold_1.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Bold_1.svg#Foco-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontsPath}Calibri-Italic.eot');
    src: url('#{$fontsPath}Calibri-Italic.eot?#iefix')
            format('embedded-opentype'),
        url('#{$fontsPath}Calibri-Italic.woff2') format('woff2'),
        url('#{$fontsPath}Calibri-Italic.woff') format('woff'),
        url('#{$fontsPath}Calibri-Italic.ttf') format('truetype'),
        url('#{$fontsPath}Calibri-Italic.svg#Calibri-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Regular_2.eot');
    src: url('#{$fontsPath}Foco-Regular_2.eot?#iefix')
            format('embedded-opentype'),
        url('#{$fontsPath}Foco-Regular_2.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Regular_2.woff') format('woff'),
        url('#{$fontsPath}Foco-Regular_2.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Regular_2.svg#Foco-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Bold_2.eot');
    src: url('#{$fontsPath}Foco-Bold_2.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Foco-Bold_2.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Bold_2.woff') format('woff'),
        url('#{$fontsPath}Foco-Bold_2.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Bold_2.svg#Foco-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-BoldItalic.eot');
    src: url('#{$fontsPath}Foco-BoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('#{$fontsPath}Foco-BoldItalic.woff2') format('woff2'),
        url('#{$fontsPath}Foco-BoldItalic.woff') format('woff'),
        url('#{$fontsPath}Foco-BoldItalic.ttf') format('truetype'),
        url('#{$fontsPath}Foco-BoldItalic.svg#Foco-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontsPath}Calibri-BoldItalic.eot');
    src: url('#{$fontsPath}Calibri-BoldItalic.eot?#iefix')
            format('embedded-opentype'),
        url('#{$fontsPath}Calibri-BoldItalic.woff2') format('woff2'),
        url('#{$fontsPath}Calibri-BoldItalic.woff') format('woff'),
        url('#{$fontsPath}Calibri-BoldItalic.ttf') format('truetype'),
        url('#{$fontsPath}Calibri-BoldItalic.svg#Calibri-BoldItalic')
            format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Calibri';
    src: url('#{$fontsPath}Calibri-Bold.eot');
    src: url('#{$fontsPath}Calibri-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Calibri-Bold.woff2') format('woff2'),
        url('#{$fontsPath}Calibri-Bold.woff') format('woff'),
        url('#{$fontsPath}Calibri-Bold.ttf') format('truetype'),
        url('#{$fontsPath}Calibri-Bold.svg#Calibri-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Bold_3.eot');
    src: url('#{$fontsPath}Foco-Bold_3.eot?#iefix') format('embedded-opentype'),
        url('#{$fontsPath}Foco-Bold_3.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Bold_3.woff') format('woff'),
        url('#{$fontsPath}Foco-Bold_3.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Bold_3.svg#Foco-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Foco';
    src: url('#{$fontsPath}Foco-Regular_3.eot');
    src: url('#{$fontsPath}Foco-Regular_3.eot?#iefix')
            format('embedded-opentype'),
        url('#{$fontsPath}Foco-Regular_3.woff2') format('woff2'),
        url('#{$fontsPath}Foco-Regular_3.woff') format('woff'),
        url('#{$fontsPath}Foco-Regular_3.ttf') format('truetype'),
        url('#{$fontsPath}Foco-Regular_3.svg#Foco-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
