.content-figure {
    &--fixed-height {
        height: rem(300px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            height: rem(243px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            height: rem(417px);
        }
    }

    &--fixed-width {
        @media screen and (min-width: #{breakpoint-min('xl')}) {
            max-width: rem(233.31px);
        }
    }

    &--report {
        display: flex;
        align-items: flex-end;
        width: 100%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            //height: 390px;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            height: auto;
            min-height: 262px;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            min-height: 317px;
        }
    }

    &__image {
        width: 100%;
        height: auto;
        vertical-align: middle;

        &--cover {
            height: 100%;
            object-fit: cover;
            object-position: center;
            font-family: 'object-fit: cover; object-position: center;'; /* ie-11 polyfill */
        }

        &--cover-at-desktop {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                height: 100%;
                object-fit: cover;
                object-position: center;
                font-family: 'object-fit: cover; object-position: center;'; /* ie-11 polyfill */
            }
        }
    }

    &__text {
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 1.4;
    }
}
