.product-category-info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: rem(48px);
    padding-bottom: rem(82px);

    @media screen and (min-width: #{breakpoint-min('sm')}) {
        padding-top: rem(64px);
        padding-bottom: rem(75px);
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding-top: rem(67px);
        padding-bottom: rem(86px);
    }

    &__card {
        //@media screen and (min-width: #{breakpoint-min('md')}) {
        //    max-width: rem(250px);
        //}

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            max-width: rem(450px);
            padding-top: 0;
        }

        &-title {
            margin-bottom: rem(17px);
            font-size: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(24px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(40px);
            }
        }
    }

    &__details {
        position: relative;
        width: rem(227px);
        height: rem(228px);
        margin-top: rem(55px);
        margin-left: auto;
        margin-right: auto;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(64px);
            width: rem(336px);
            height: rem(336px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-top: 0;
            margin-right: rem(11px);
        }

        &-inner {
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
        &-inner--left-align,
        &-inner--right-align {
            flex: 0 0;
            width: auto;
            margin-left: auto;
            margin-right: auto;
        }

        &-inner--left-align {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-left: 0;
            }
        }

        &-inner--right-align {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-right: 0;
            }
        }

        &-count {
            font-family: $foco;
            font-size: rem(104px);
            font-weight: bold;
            line-height: rem(136px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(11px);
                font-size: rem(136px);
            }
        }

        &-desc {
            font-size: rem(16px);
            font-weight: bold;
            line-height: rem(20px);
            letter-spacing: rem(0.2px);
            text-align: center;
        }

        &-circle {
            position: absolute;

            &--main,
            &--first,
            &--second,
            &--third {
                top: 0;
                left: 0;

                width: rem(227px);
                height: rem(228px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: rem(336px);
                    height: rem(336px);
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    // left: rem(24px);
                }
            }

            &--first,
            &--second,
            &--third {
                background-color: $glacier;
            }
            &--eco-first,
            &--eco-second,
            &--eco-third {
                background-color: $eucalyptus;
            }
        }
    }
}
