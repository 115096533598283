html,
body {
    width: 100%;
    height: 100%;
    color: $firefly;
    font-weight: 400;
    font-size: #{$baseFontSize}px;
    -webkit-text-size-adjust: none;
    font-family: $calibry, $fonts-default;
}

body {
    height: 100%;
    width: 100%;
}

.page-container {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

.page-content {
    padding-top: 2rem;
    padding-bottom: 5rem;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding-top: 3rem;
        padding-bottom: 8rem;
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding-top: 5rem;
        padding-bottom: 12.5rem;
    }
}

.anchor {
    width: 100%;
    height: rem(1px);
    display: block;
    position: relative;

    top: rem(-124px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        top: rem(-169px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        top: rem(-108px);
    }
}
.anchor-link {
    position: relative;
    top: -75px;
    height: 0;
    opacity: 0;
}

figure {
    margin: 0;
}

button:focus {
    outline: none;
}

a {
    display: inline-block;
    color: $firefly;
    outline: none;
    transition: color .3s ease-in-out;

    &:hover {
        color: #269448;
        text-decoration: none;
    }
}

*::ms-reveal,
*::ms-clear {
    display: none;
}

input[type='text']::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

input[type='text']::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
    display: none;
    width: 0;
    height: 0;
}

input {
    border-radius: 0;
    outline: none;

    &:focus {
        outline: none;
    }
}

.btx-content p a {
    word-break: break-all;
}

.hidden {
    display: none;
}

.visible {
    display: initial;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    white-space: nowrap;
    border: 0;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
}


.sticky-aside {
    position: sticky;
    top: rem(75px);
    padding-top: rem(16px);
    padding-bottom: rem(8px);
    margin-bottom: rem(16px);
    background: #fff;
    z-index: 10;

    @include breakpoint (tablet) {
        top: rem(72px);
    }

    @include breakpoint (desktop) {
        top: rem(96px);
    }
}

sup,
sub {
    color: #018FA1;
}

p {
    margin-top: 0;
    margin-bottom: 8px;

    @include breakpoint(mobile) {
        margin-bottom: 12px;
    }

    @include breakpoint(tablet) {
        margin-bottom: 16px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.menu-arrow {
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.0411 0.654859C10.3494 0.984765 10.3494 1.49716 10.0411 1.82706L6.51643 5.5983C6.17716 5.96131 5.60149 5.96131 5.26222 5.5983C4.95388 5.26839 4.95388 4.756 5.26222 4.4261L8.78687 0.654859C9.12614 0.291853 9.70181 0.291853 10.0411 0.654859Z' fill='%230e212f'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.71087 0.654859C1.40253 0.984765 1.40253 1.49716 1.71087 1.82706L5.23552 5.5983C5.5748 5.96131 6.15046 5.96131 6.48973 5.5983C6.79807 5.26839 6.79807 4.756 6.48973 4.4261L2.96508 0.654859C2.62581 0.291853 2.05014 0.291853 1.71087 0.654859Z' fill='%230e212f'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    transform: rotate(0deg);
    transition: transform .1s;
    cursor: pointer;

    &.active, &.current {
        transform: rotate(180deg);
    }
}