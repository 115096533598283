.news-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    padding-top: rem(40px);

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        display: flex;
        flex-wrap: wrap;
        margin-left: rem(-24px);
        margin-right: rem(-24px);
    }

    &__item {
        padding-bottom: rem(40px);

        &:last-child {
            padding-bottom: 0;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(32px);

            &:last-child {
                padding-bottom: 0;
            }
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(48px);
            width: 50%;
            padding-left: rem(24px);
            padding-right: rem(24px);
        }
    }

    &__heading {
        font-size: rem(16px);
        line-height: rem(24px);
        font-weight: bold;
        letter-spacing: rem(0.25px);
        color: $firefly;
        padding-bottom: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(24px);
        }
    }

    &__text {
        color: $firefly06;

        @media screen and (min-width: #{breakpoint-min('xl')}) {
        }
    }

    &-message {
        margin-bottom: 24px;
        background: rgba($eucalyptus, 0.1);
        padding: rem(24px);
        border-radius: rem(3px);

        &-header {
            font-weight: bold;
            padding-bottom: rem(16px);
        }

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            padding: rem(32px) rem(64px);
        }
    }
}
