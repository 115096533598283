.gypsum {
    .gypsum-card {
        background-image: url('/images/production/phosphogypsum/flower-light.png');
        background-position: 102% 102%;
        background-repeat: no-repeat;
        padding: 24px;
        border: 1px solid #D0D3DA;
        border-radius: 3px;
        position: relative;
        transition: all 500ms ease;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &:hover {
            border: 1px solid transparent;
            transform: translateY(-10px);
            box-shadow: 15px 15px 45px rgba(8, 37, 98, 0.1);
        }

        &__tag {
            padding: 4px 8px 2px;
            color: white;
            text-transform: uppercase;
            background-color: #2DAA55;
            font-size: 12px;
            font-weight: 400;
            line-height: 1.2;
            letter-spacing: 0.69px;
            display: inline-block;
            max-width: 150px;
        }

        &__title {
            margin-top: 128px;
            color: #3B414F;
            font-weight: 700;
            font-size: 24px;
        }

        &__link {
            margin-top: 16px;
            color: #249A4A;
            font-weight: 700;
            font-size: 18px;
            display: inline-block;
            position: relative;

            &:before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.25 0.999883C10.2501 0.585669 10.5859 0.249935 11.0001 0.25L14.9694 0.25062C15.1714 0.242425 15.3761 0.315442 15.5303 0.46967C15.6846 0.623896 15.7576 0.82858 15.7494 1.03058L15.75 4.99988C15.7501 5.4141 15.4143 5.74994 15.0001 5.75C14.5859 5.75006 14.2501 5.41433 14.25 5.00012L14.2497 2.811L8.53033 8.53033C8.23744 8.82322 7.76256 8.82322 7.46967 8.53033C7.17678 8.23744 7.17678 7.76256 7.46967 7.46967L13.189 1.75034L10.9999 1.75C10.5857 1.74994 10.2499 1.4141 10.25 0.999883ZM1.75 4.5C1.75 2.98122 2.98122 1.75 4.5 1.75H7C7.41421 1.75 7.75 1.41421 7.75 1C7.75 0.585786 7.41421 0.25 7 0.25H4.5C2.15279 0.25 0.25 2.15279 0.25 4.5V11.5C0.25 13.8472 2.15279 15.75 4.5 15.75H11.5C13.8472 15.75 15.75 13.8472 15.75 11.5V9C15.75 8.58579 15.4142 8.25 15 8.25C14.5858 8.25 14.25 8.58579 14.25 9V11.5C14.25 13.0188 13.0188 14.25 11.5 14.25H4.5C2.98122 14.25 1.75 13.0188 1.75 11.5V4.5Z' fill='%232DAA55'/%3e%3c/svg%3e ");
                margin-right: 8px;
                vertical-align: middle;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: contain;
            }

            &:hover {
                color: #33BE60;
            }
        }
    }
}