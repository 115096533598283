.product-text-cards {
    margin-bottom: rem(64px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-bottom: rem(80px);
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        margin-bottom: rem(96px);
    }

    &__list {
        margin-top: rem(-24px);
        margin-bottom: rem(-24px);
        padding-left: 0;
        list-style: none;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(-36px);
            margin-bottom: rem(-36px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-top: rem(-48px);
            margin-bottom: rem(-48px);
        }
    }

    &__item {
        padding: rem(16px);
        padding-top: rem(24px);
        transition: box-shadow 0.2s ease-in;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: rem(24px);
            padding-top: rem(32px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding: rem(32px);
            padding-top: rem(60px);
        }

        &:hover {
            box-shadow: 15px 15px 45px rgba(8, 37, 98, 0.1);
        }
    }

    &__link {
        &:hover {
            color: $firefly;
        }
    }
}
