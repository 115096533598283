.page404 {
    margin-top: rem(152px);
    margin-bottom: rem(210px);
    align-items: center;

    &__content {
        font-weight: 700;
        font-family: $calibry;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            align-items: flex-start;
        }

        &-title {
            font-family: $foco;
            font-size: rem(20px);
            line-height: rem(40px);
            color: $firefly;
            margin-bottom: rem(16px);
        }

        &-text {
            max-width: 287px;
            font-size: rem(14px);
            letter-spacing: 0.2px;
            opacity: 0.3;
        }

        &-button {
            margin-top: rem(24px);
            width: 100%;
            max-width: 288px;
            height: 56px;
            border-radius: 28px;
            background-color: $eucalyptus;
            color: white;
            font-size: rem(14px);
            letter-spacing: 0.8px;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                color: white;
            }
        }

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            &-title {
                font-size: rem(24px);
            }

            &-button {
                margin-top: rem(24px);
                max-width: none;
                width: 274px;
                height: 64px;
                border-radius: 32px;
            }
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            &-title {
                font-size: rem(32px);
            }

            &-text {
                font-size: rem(16px);
            }

            &-button {
                margin-top: rem(40px);
                width: 268px;
            }
        }
    }

    &__circles {
        position: relative;
        flex-shrink: 0;
        display: flex;
        height: rem(228px);
        justify-content: center;
        margin-bottom: rem(96px);

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            height: rem(320px);
            margin-bottom: rem(120px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            height: rem(448px);
            order: 1;
            margin-bottom: 0;
        }

        &-container {
            position: absolute;
            margin: 0 auto;
            width: rem(228px);
            height: rem(228px);

            .circle {
                position: absolute;
                height: 100%;
                width: 100%;
                top: 0;

                &--first,
                &--second,
                &--third {
                    background-color: $seaGreen;
                    opacity: 0.05;
                }

                //@include mq('lg'){
                //  width: rem(448px);
                //  height: rem(448px);
                //}
            }
        }

        &-inner {
            font-family: $foco;
            font-weight: bold;
            font-size: rem(16px);
            line-height: rem(32px);
            color: $firefly;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: rem(228px);
            height: rem(228px);

            &--title {
                font-size: rem(64px);
                line-height: rem(64px);
            }
        }

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            &-inner {
                font-size: rem(20px);
                &--title {
                    font-size: rem(104px);
                    line-height: rem(104px);
                }
            }

            &-inner,
            &-container {
                width: rem(320px);
                height: rem(320px);
            }
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            &-inner {
                font-size: rem(24px);
                &--title {
                    font-size: rem(136px);
                    line-height: rem(136px);
                }
            }

            &-inner,
            &-container {
                width: rem(448px);
                height: rem(448px);
            }
        }
    }
}
