.uprawy-page {
    a {
        width: 100%;
        border: 1px solid rgba(14, 33, 47, 0.2);
        border-radius: 3px;
        padding: 10px;

        &:hover {
            border-color: #2daa55;
        }
    }
}

.pl-bg-btn {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 30px;
    color: white;
    font-size: 24px;
    font-family: Foco;
    font-weight: bold;
    border-radius: 3px;
    background-size: cover;
    background-position: bottom;
    width: 100%;
    height: 362px;

    @media screen and (max-width: 768px) {
        height: 300px;
    }

    @media screen and (max-width: 512px) {
        height: 272px;
    }

    &:hover {
        &::after {
            content: url('/local/templates/.default/img/pl/uprawy/arrow-link.svg');
            width: 12px;
            height: 12px;
            position: absolute;
            bottom: 54px;
            left: auto;
            right: 46px;
        }
    }
}

@for $i from 1 through 10 {
    .pl-bg-btn-#{$i} {
        background-image: linear-gradient(
                180deg,
                rgba(196, 196, 196, 0) 50%,
                rgba(14, 33, 47, 0.5) 100%
            ),
            url('/local/templates/.default/img/pl/uprawy/pl-btn-#{$i}.jpg');
    }
}
