.investors-report-card {
    color: #0E212F;
    font-size: 14px;

    &__inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__image {
        display: flex;
        width: 72px;
        height: 72px;
        align-items: center;
        justify-content: center;

        img {
            margin: auto;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__col + &__col {
        margin-left: 1em;
    }

    &__row + &__row {
        margin-top: 1em;
    }

    a {
        font-size: inherit;
    }

    &__title {
        font-weight: 800;
        line-height: 140%;
        font-size: inherit;
    }
}

.investors-reports,
.flex-grid-20 {
    &__list {
        @include list-reset;
        display: flex;
        flex-wrap: wrap;
        margin: -10px;

    }
    &__item {
        @include list-reset;
        padding: 10px;
    }
}
