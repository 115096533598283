.html {
    &--header-menu-opened,
    &--header-search-opened,
    &--header-search-opened {
        .header--main,
        .header--inner,
        .header--without-banner {
            .header__nav-link,
            .link--header {
                color: #2DAA55;
            }
            & .link__selector .z-icon path{
                fill: #2DAA55;
            }
        }
    }

    &--header-menu-opened {
        .header__nav-link {
            font-weight: 400;

            &.active {
                font-weight: 700;

                &:after {
                    background: #2daa55;
                }
            }

            &.current {
                font-weight: 700;
                color: #2daa55;
            }
            
        }
        .page-container {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
        }
    }
    &--header-search-opened {
        .page-container {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
        }
    }
}

.modal-open {
    overflow: initial;

    .page-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }
}
