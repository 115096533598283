.subnavigation {
    $parent: &;

    margin-bottom: rem(48px);
    position: relative;
    z-index: 3;

    &--sticky {
        position: sticky;
        top: rem(144px);
    }

    &__outer {
        position: absolute;
        z-index: 4;
        top: 100%;
        left: 0;
        width: 100%;
        margin: rem(8px) 0 0;
        border-radius: rem(8px);
        box-shadow: rem(15px) rem(15px) rem(45px) rgba(8, 37, 98, 0.1);
        background: $white;

        // @media screen and (max-width: #{breakpoint-min('lg')}) {
        //     margin-top: -20px;
        // }

        @media screen and (min-width: #{breakpoint-min('lg')}) and (max-height: 768px) {
            max-height: calc(100vh - 130px);
            padding-right: 6px;
            overflow-x: hidden;
            overflow-y: auto;
            -ms-overflow-style: none;  /* for IE and Edge */
            scrollbar-width: none;  /* for Firefox */

            &::-webkit-scrollbar {
                display: none;
            }
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            position: static;
            margin: rem(-20px) 0;
            border-radius: 0;
            box-shadow: none;
            background-color: transparent;
        }

        &.collapse:not(.show) {
            @media screen and (min-width: #{breakpoint-min('lg')}) {
                display: block;
            }
        }
    }

    &__arrow-wrapper {
        width: 40px;
        height: 40px;
        position: absolute;
        right: 0;
        top: 50%;
        transition: 200ms;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);
        cursor: pointer;

        &.collapsed {
            transform: translateY(-50%) rotate(-180deg);
        }
    }

    &__inner {
        padding: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: rem(40px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding: 0;
        }
    }

    &__menu,
    &__submenu {
        margin-top: 0;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
    }

    &__menu {
        margin: rem(-12px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin: rem(-20px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin: 0 rem(-20px);
        }

        &-link {
            position: relative;
            display: block;
            padding: rem(12px);
            padding-right: rem(32px);
            font-family: $foco, $fonts-default;
            font-size: rem(16px);
            font-weight: bold;
            line-height: (24 / 16);
            letter-spacing: rem(0.2px);
            text-decoration: none;
            cursor: pointer;
            transition: color .3s ease-in-out;
            touch-action: manipulation;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding: rem(20px);
                padding-right: rem(40px);
                font-size: rem(18px);
                line-height: (25 / 20);
                letter-spacing: normal;
            }

            &:hover,
            &:not([href]):not([tabindex]):hover {
                color: $eucalyptus;
            }

            .active {
                color: $eucalyptus;
            }

            &.collapsed {
                color: $firefly !important;

                &:hover {
                    color: $eucalyptus !important;
                }
            }

            //&:not([href]):not([href=""]):not(.collapsed),
            .active & {
                color: $eucalyptus !important;

                &--parent {
                    &:hover {
                        color: $eucalyptus !important;
                    }
                }
            }

            &.collapsed:not(.js-investors-menu) & {
                &-icon {
                    transform: translateY(-50%) rotate(0deg);
                }
            }

            &-icon {
                z-index: 1;
                top: 50%;
                right: 0;
                color: #0e212f;
                transform: rotate(180deg);
                transition: transform 200ms ease-in-out;

                &--absolute {
                    position: absolute;
                }
            }

            &-location-link {
                display: inline-block;
                max-width: 200px;
            }

            &--parent {
                cursor: pointer;

                &:hover {
                    color: $firefly !important;
                }
            }
        }

        &-item {

            &.active {

                #{$parent}__menu-link {
                    color: $eucalyptus;
                }
            }
        }
    }

    &__submenu {
        margin-top: rem(-8px);
        margin-bottom: rem(4px);
        padding-left: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(8px);
            padding-left: rem(32px);
        }

        &-link {
            padding: rem(8px);
        }

        &-item.active {
            a {
                color: $eucalyptus;
                pointer-events: none;
            }
        }
    }

    &__toggle {
        position: relative;
        display: block;
        width: 100%;
        //padding-top: rem(8px);
        padding-right: rem(38px);
        //padding-bottom: rem(6px);
        padding-left: rem(23px);
        height: rem(38px);
        line-height: rem(38px);
        font-family: $foco, $fonts-default;
        font-size: rem(16px);
        font-weight: bold;
        //line-height: (24 / 16);
        letter-spacing: rem(0.2px);
        text-decoration: none;
        text-align: left;
        cursor: pointer;
        border: 1px solid $firefly02;
        border-radius: rem(25px);
        background-color: $white;
        transition: color 400ms ease-in-out;
        touch-action: manipulation;
        white-space: nowrap;
        padding-right: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            //padding-top: rem(19px);
            //padding-bottom: rem(18px);
            //line-height: (25 / 20);
            padding-right: rem(59px);
            padding-left: rem(31px);
            font-size: rem(20px);
            height: rem(62px);
            line-height: rem(62px);
            letter-spacing: normal;
            border-radius: rem(32px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            display: none;
        }

        &.collapsed & {
            &-icon {
                transform: translateY(-50%) rotate(0deg);
            }
        }

        &-text {
            overflow: hidden;
            display: block;
            white-space: nowrap;
        }

        &-icon {
            position: absolute;
            z-index: 1;
            top: 50%;
            right: rem(24px);
            color: $congressBlue;
            transform: translateY(-50%) rotate(180deg);
            transition: transform 400ms ease-in-out;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                right: rem(32px);
            }
        }
    }
}

.subnavigation-wrapper-mobile { //костыль для https://tracker.yandex.ru/PHOSAGRO-2012
    position: sticky;
    top: 70px;
    background-color: #fff;
    padding: 1rem 0;
    z-index: 3;
    @media screen and (min-width: #{breakpoint-min('lg')}) {
        display: none;
    }

    .subnavigation {
        margin-bottom: 1rem;
    }

    .subnavigation__toggle {
        overflow: hidden;
    }

    .subnavigation__outer {
        margin-top: 0;
    }
}

.bx-ie11 {
    .subnavigation {
        &--sticky {
            position: static;
        }
    }
}
