.content {
    margin-top: rem(48px);
    margin-bottom: rem(64px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-top: rem(64px);
        margin-bottom: rem(80px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        margin-top: rem(80px);
        margin-bottom: rem(120px);
    }

    &__section,
    &__heading-intro {
        margin-bottom: rem(64px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(88px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(120px);
        }
    }

    &__section {
        width: 100%;

        &:last-child {
            margin-bottom: 0;
        }

        &--intro {
            margin-bottom: rem(48px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(64px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(80px);
            }
        }
    }

    &__subsection,
    &__header,
    &__lead,
    &__navigation,
    &__figure {
        margin-bottom: rem(40px) !important;

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(64px) !important;
        }
    }

    &__lead {
        &--small {
            margin-bottom: rem(32px) !important;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(48px) !important;
            }
        }
    }

    &__subsection {
        &:last-child {
            margin-bottom: 0;
        }

        &--large {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(64px);
            }
        }
    }

    &__grid {
        margin-top: rem(-48px);
        padding-left: 0;
        list-style: none;

        &--small {
            margin-top: rem(-32px);
        }

        &--small & {
            &-item {
                margin-top: rem(32px);
            }
        }

        &-item {
            margin-top: rem(48px);
        }
    }

    &__heading {
        margin-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(32px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(48px);

            &--slider-circle {
                margin-bottom: rem(16px);
            }
        }
    }

    &__subheading {
        margin-bottom: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(24px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(32px);
        }
    }

    &__text {
        margin-bottom: rem(24px);

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__circles {
        margin-top: rem(40px);
        margin-left: auto;
        margin-right: auto;
        padding-top: rem(18px);
        padding-bottom: rem(18px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: 0;
            margin-right: rem(20px);
            padding-top: rem(20px);
            padding-bottom: rem(20px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-right: rem(32px);
            padding-top: rem(32px);
            padding-bottom: rem(32px);
        }

        &-outer {
            position: relative;
            width: rem(228px);
            height: rem(228px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(255px);
                height: rem(255px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: rem(336px);
                height: rem(336px);
            }
        }

        &-content {
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
        }

        &-item {
            position: absolute;
            top: 0;
            left: 0;

            &--main,
            &--first,
            &--second,
            &--third {
                height: rem(228px);
                width: rem(228px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    height: rem(255px);
                    width: rem(255px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    height: rem(336px);
                    width: rem(336px);
                }
            }

            &--first,
            &--second,
            &--third {
                background-color: $glacier;
            }
        }
    }

    &-container {
        max-width: 53rem;
        margin: 0 auto;
        z-index: 4;
        position: relative;
        background-color: white;

        &--large {
            max-width: 65rem;
            margin: 0 1.5rem 0 auto;

            @media screen and (max-width: $breakpoint-v-mobile) {
                margin: 0 auto;
            }
        }
    }

    &-link {
        &--external {
            color: #2daa55;
            position: relative;
            font-weight: bold;
            line-height: 1.25rem;

            &::before {
                content: '';
                background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='9' height='9' viewBox='0 0 9 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect y='6.75641' width='8' height='2' rx='1' transform='rotate(-45 0 6.75641)' fill='%230E212F'/%3e%3cpath d='M1.17188 0.999023H7.17188V6.99902' stroke='%230E212F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");
                width: 9px;
                height: 9px;
                background-size: cover;
                position: absolute;
                top: 50%;
                margin-top: -4.5px;
                left: -1rem;
            }

            @media screen and (max-width: $breakpoint-desktop) {
                margin-bottom: 1.5rem;
                margin-left: 1rem;
            }
        }
    }
}

