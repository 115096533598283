.details-list {
    $parent: &;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    & .icon--accordion-arrow {
        color: $limedSpruce;
    }

    &--no-arrow {
        #{$parent}__arrow {
            display: none;
        }
    }

    &__arrow {
        position: absolute;
        right: 24px;
        top: 30px;
        height: 10px;
        width: 10px;

        .icon {
            position: absolute;
            left: 0;
            top: -3px;
            opacity: 0.5;
            transform: rotate(0deg);
        }
    }

    &__item {
        &.active {

            .simplebar-scrollbar {
                transition: 150ms;
            }

            .simplebar-content {
                padding: 0 !important;
            }

            #{$parent}__arrow {
                .icon {
                    transform: rotate(-180deg) translateY(3px);
                    opacity: 1;
                }
            }
        }
    }

    &__inner {
        padding: rem(16px) 0 rem(12px);
        width: 100%;
        position: relative;

        &:hover {
            cursor: pointer;
        }
    }

    &__caption {
        height: auto;
        margin-bottom: rem(16px);
        display: block;
    }

    &__header {
        font-size: rem(20px);
        line-height: rem(28px);
        padding-bottom: rem(8px);
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        margin-left: rem(-24px);
        margin-right: rem(-24px);

        &__item {
            width: 50%;
        }

        &__heading {
            font-size: rem(20px);
            line-height: rem(32px);
        }

        &__inner {
            padding: rem(24px) rem(24px) rem(8px) rem(24px);
        }

        &__item {
            position: relative;

            &.active {
                .details-list__inner {
                    position: absolute;
                    z-index: 5;
                    background-color: #fff;
                    box-shadow: 15px 15px 45px rgba(8, 37, 98, 0.1);
                }

                .simplebar-scrollbar {
                    transition: 150ms;
                }

                .simplebar-content {
                    padding: 0 !important;
                }
            }
        }

        &__caption {
            display: block;
        }

        &__header {
            font-size: rem(24px);
            line-height: rem(32px);
            padding-bottom: rem(16px);
        }

        &__item {
            min-height: rem(144px);
        }
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        &__header {
            font-size: rem(24px);
            line-height: rem(32px);
        }

        &__arrow {
            right: 24px;
            top: 34px;
        }
    }
}
