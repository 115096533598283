.manager {
    border-bottom: rem(1px) solid $iron;
    padding-top: rem(80px);
    padding-bottom: rem(35px);

    &__photo {
        margin-bottom: rem(25px);
        width: rem(128px);

        &-pic {
            width: 100%;
        }
    }

    &__position {
        margin-bottom: 20px;
    }

    &__list {
        ~ [id^='com_']::before {
            display: block;
            content: '';
            height: 130px;
            margin: -130px 0 0;

            @include breakpoint(mobile) {
                height: 183px;
                margin-top: -183px;
            }

            @include breakpoint(desktop) {
                height: 101px;
                margin-top: -101px;
            }
        }
    }

    &__link {
        color: $eucalyptus;
        font-weight: 700;
        font-size: rem(14px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(18px);
        }
    }

    &--invest {
        border: 0;
        padding-top: 0;
        padding-bottom: 0;

        .manager__photo {
            width: rem(128px);
        }
    }
}
