.youth-program {
    &__header-background-img {
        object-position: 65%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            object-position: 60%;
        }
    }

    &__subnavigation {
        margin-bottom: rem(50px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(80px);
        }
    }

    &__title {
        margin-bottom: rem(30px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(75px);
        }
    }

    &__container {
        margin: rem(25px) 0 rem(65px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin: 0 0 rem(80px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin: 0 0 rem(120px);
        }
    }

    &__about {
        margin: rem(30px) 0 rem(40px);
        color: $black;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin: rem(50px) 0 rem(55px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin: rem(85px) 0 rem(50px);
        }

        &-title {
            margin-bottom: rem(25px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(50px);
            }
        }

        &-top {
            margin-bottom: rem(30px);
            font-weight: bold;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(55px);
            }
        }

        &-text {
            margin-bottom: rem(25px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(30px);
            }

            &-wrap {
                margin-bottom: rem(25px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(40px);
                }
            }
        }

        &-card {
            &-wrap {
                margin-bottom: rem(30px);
            }

            &--graduate {
                display: flex;
                flex-direction: row;
                //align-items: center;
                align-items: baseline;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    flex-direction: column;
                    align-items: center;
                }

                @media screen and (max-width: #{breakpoint-min('md')}) {
                    align-items: center;
                    justify-content: center;
                }

                .picture-list__item-figure {
                    margin-right: rem(30px);
                    margin-bottom: 0;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        margin-right: 0;
                        margin-bottom: rem(10px);
                    }
                }
            }
        }
    }

    &__education-program {
        margin-top: rem(50px);
    }

    &__college {
        &-cards {
            margin-bottom: rem(25px);
        }

        .youth-program__about-card {
            height: fit-content;
            margin-bottom: rem(25px);

            &-title {
                font-size: rem(16px);
                margin-bottom: rem(15px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(24px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(20px);
                }
            }
        }

        &-wrap {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }
    }

    &__uni {
        .youth-program__list {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: block;
                column-count: 2;
            }
        }

        &-partners {
            .youth-program__list-item {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    min-height: rem(75px);
                }
            }
        }
    }

    &__gallery {
        width: 200%;
        margin-top: rem(20px);

        &-title {
            margin-bottom: rem(20px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(45px);
            }
        }

        .gallery-slider__item {
            width: rem(290px);
            height: rem(190px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(425px);
                height: rem(285px);
            }
        }

        .gallery-slider .owl-nav {
            margin-left: 0;
            margin-top: rem(25px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: rem(40px);
            }
        }
    }

    &__features {
        margin-bottom: rem(15px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }

        &-title {
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(50px);
            }
        }

        &-wrap {
            margin-bottom: rem(25px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }
        }
    }

    &__list {
        flex-direction: column;
        flex-wrap: nowrap;
        margin-bottom: rem(25px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: 0;
        }

        &-item {
            width: 100%;
            padding-left: rem(18px);
            padding-right: 0;
            margin-bottom: rem(25px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(30px);
            }
        }
    }

    &__quote {
        margin-top: rem(60px);
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(90px);
            margin-bottom: rem(50px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-top: rem(140px);
            margin-bottom: rem(55px);
        }

        &-title {
            margin-bottom: rem(45px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(50px);
            }
        }

        &-info {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 65%;
            }
        }

        &-job {
            margin-bottom: rem(20px);

            font-weight: bold;
            font-size: rem(12px);
            text-transform: uppercase;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(14px);
            }
        }

        &-section {
            width: 48%;
            font-weight: bold;

            //&:nth-child(2) {
            //  @media screen and (min-width: #{breakpoint-min('md')}) {
            //    width: 51%;
            //  }
            //}
            //
            &--last {
                width: 100%;
                margin-bottom: rem(40px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(65px);
                    width: 100%;
                }
            }

            &-title {
                font-size: rem(14px);
                color: #a5b0b9;
            }
        }

        &-time {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: rem(15px);
        }

        &-text {
            margin-bottom: rem(30px);

            &--top {
                font-weight: bold;
                font-size: rem(16px);
            }
        }

        .owl-nav {
            position: absolute;
            left: rem(16px);
            bottom: rem(45px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                left: rem(30px);
                bottom: rem(60px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                left: rem(110px);
                bottom: rem(85px);
            }
        }
    }

    &__quotation {
        padding-bottom: rem(75px);
        margin-top: rem(20px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(85px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(130px);
        }
    }

    &__benefits {
        margin-bottom: rem(20px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }

        &-title {
            margin-bottom: rem(50px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(60px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(95px);
            }
        }

        &-list {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                columns: 2;
            }
        }

        &-item {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 70%;
                min-height: rem(100px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: 90%;
            }
        }

        .picture-list__item {
            width: 75%;
        }

        .picture-list__item-title {
            font-weight: normal;
            font-size: rem(14px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(16px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(16px);
            }
        }

        .picture-list__item-figure {
            min-height: rem(60px);
        }
    }

    &__requirements {
        margin-bottom: rem(15px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }

        &-title {
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(40px);
            }
        }

        &-top-text {
            font-size: rem(16px);
            font-weight: bold;
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(50px);
                font-size: rem(20px);
            }
        }
    }

    &__application {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(55px);
        }

        &--how-to {
            .youth-program__steps:nth-of-type(1) {
                margin-right: -44px;

                @media screen and (max-width: #{breakpoint-min('md')}) {
                    margin-right: 0;
                }
            }

            .youth-program__steps:nth-of-type(2) {
                margin-left: -44px;

                @media screen and (max-width: #{breakpoint-min('md')}) {
                    margin-left: 0;
                }
            }

            .youth-program__step {
                .h4 {
                    font-size: 16px;

                    @media screen and (max-width: #{breakpoint-min('lg')}) {
                        font-size: 14px;
                    }
                }
            }
        }

        &-title {
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(55px);
            }
        }

        .content-card__text--grey {
            text-transform: uppercase;
        }
    }

    &__steps {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }

        .icon {
            display: block;
            width: rem(16px);
            height: rem(33px);
            margin: 0 auto;
            color: #e3e3e3;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                transform: rotate(270deg);
                height: rem(47px);
                width: rem(47px);
            }
        }

        &--school {
            margin-bottom: rem(25px);

            .icon {
                height: rem(31px);
                width: rem(16px);
            }
        }

        &--contrast {
            .icon {
                color: #b6b6b6;
            }
        }
    }

    &__step {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(30px);
        height: rem(192px);
        position: relative;

        @media screen and (max-width: #{breakpoint-min('md')}) {
            .content-card__text--grey-wrap {
                padding-right: 36px;
            }
        }

        .content-card__text--grey-wrap,
        .content-card__text-wrap {
            left: 1.5625rem;
            bottom: 1.5625rem;
            position: absolute;

            @media screen and (max-width: #{breakpoint-min('md')}) {
                left: unset;
                bottom: 1rem;
            }
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 30%;
            padding: rem(25px);
            margin-bottom: 0;
            min-height: rem(340px);
        }

        @media screen and (max-width: #{breakpoint-min('lg')}) {
            padding: 1rem;
        }

        &--short {
            min-height: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: rem(15px);
                width: 21%;
                min-height: rem(150px);
                text-align: center;
            }
        }
    }

    &__button {
        display: block;
        width: 100%;
        margin: 0 auto;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: rem(223px);
            margin: 0 auto 0 0;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            width: rem(288px);
        }
    }

    &__news {
        .content-card {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding: rem(80px) rem(65px);
            }
        }

        .content-figure__image {
            width: rem(54px);
            height: rem(54px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(64px);
                height: rem(64px);
            }
        }
    }

    .modal-dialog {
        width: rem(850px);
    }

    &__modal {
        position: relative;
        top: rem(76px);
        padding: rem(55px) rem(15px);
        border-radius: rem(6px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            top: rem(-8px);
            padding: rem(50px) rem(65px);
        }

        &--success {
            text-align: center;

            .icon-success {
                display: block;
                width: rem(48px);
                height: rem(48px);
                margin: 0 auto rem(15px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: rem(55px);
                    height: rem(55px);
                }
            }

            .youth-program__modal-title {
                width: 80%;
                font-size: rem(16px);
                margin: 0 auto;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(20px);
                }
            }
        }

        &-window-inner {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                max-width: rem(850px);
            }
        }

        &-window {
            &--success {
                min-height: 0;
            }
        }

        &-title {
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(50px);
            }
        }

        &-close {
            position: absolute;
            top: rem(20px);
            right: rem(20px);
            background-color: transparent;
            border: none;
        }

        &-button {
            width: 100%;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(224px);
            }

            &.hidden {
                display: none;
            }

            & + & {
                margin-bottom: rem(15px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: 0;
                }
            }
        }

        &-next {
            margin-left: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-left: auto;
            }
        }

        &-fieldset {
            position: relative;
            margin-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: 47%;
            }

            &--full {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: 100%;
                }
            }

            &--textarea {
                width: 100% !important;
            }

            &--checkbox {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: 80%;
                }
            }

            &--two-calendars {
                width: 47%;
            }

            &--square,
            &--radio {
                margin-bottom: 0;
            }

            &--radio {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: 40%;
                }
            }

            &-legend {
                margin-bottom: rem(25px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(16px);
                }
            }

            &-wrap {
                margin-bottom: rem(70px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(30px);
                }

                &:last-child {
                    margin-bottom: rem(50px);

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        margin-bottom: rem(20px);
                    }
                }

                &--work {
                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        flex-direction: column;
                    }
                }

                &--radio {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        justify-content: flex-start;
                    }
                }

                &--checkboxes {
                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        flex-direction: column;
                    }
                }
            }
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__arrow {
            right: 0;
        }

        .select2-container--default .select2-selection--single {
            min-height: 0;
            height: auto;
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__rendered {
            //line-height: rem(38px);
            line-height: 0;
            font-size: rem(16px);
        }

        .select--phone-prefix {
            top: rem(3px);
        }

        &-buttons {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            position: relative;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                flex-direction: row;
                justify-content: space-between;
                align-items: normal;
            }
        }

        .subscribe-modal__label--agree {
            margin-bottom: 0;
        }

        &-square-radios {
            margin-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: flex;
                align-items: center;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: 47%;
            }

            &-title {
                color: $silverSand;
                font-weight: bold;
                font-size: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-right: rem(40px);
                }
            }

            &-wrap {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
            }
        }

        &-two-calendars {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &-add-workplace {
            background-color: transparent;
            border: none;
            display: flex;
            flex-direction: row;
            align-items: center;

            &-round {
                display: block;
                position: relative;
                width: rem(48px);
                height: rem(48px);
                background: $ironLight;
                margin-right: rem(20px);
                border-radius: 50%;

                .icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-right: -50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                }
            }

            &-text {
                color: $eucalyptus;
                font-weight: bold;
            }
        }

        &-checkboxes {
            &-wrap {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    column-count: 2;
                }
            }
        }

        &-work {
            &-container {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            &-item {
                position: relative;

                &.hidden {
                    display: none;
                }
            }

            &-wrap {
                position: relative;
                gap: 0 1rem;

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                }
            }
        }

        &-send {
            display: block;
            margin-bottom: rem(15px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: 0;
                margin-left: auto;
            }
        }

        &-prev {
            &.final {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: rem(-63px);
                }
            }
        }

        &-delete {
            position: absolute;
            z-index: 3;
            top: 0;
            right: 0;
            border: none;
            background-color: transparent;

            .icon {
                color: #b7bcc1;
            }
        }
    }

    &__stage {
        display: none;

        &.active {
            display: block;
        }

        &-title {
            display: block;
            margin-bottom: rem(70px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(80px);
            }

            &-wrap {
                position: relative;

                &:after {
                    position: absolute;
                    content: '';
                    bottom: rem(-20px);
                    z-index: 2;
                    width: 100%;
                    height: rem(4px);
                    border-radius: rem(20px);
                    background-color: #f1f2f3;
                    transition: width 0.3s;
                }
            }
        }

        &-progress {
            position: absolute;
            content: '';
            bottom: rem(-20px);
            z-index: 5;
            width: 0;
            height: rem(4px);
            border-radius: rem(20px);
            background-color: $eucalyptus;
            transition: width 0.3s;
        }
    }

    &__wrapper {
        .select2-dropdown {
            max-width: calc(100% - 30px);

            @include breakpoint(mobile) {
                max-width: 494px;
            }
            @include breakpoint(tablet) {
                max-width: 606px;
            }

            @include breakpoint(desktop) {
                max-width: 338px;
            }
        }
    }
}
