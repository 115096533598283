.vacancies {
    .header__background-img {
        object-position: 65%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            object-position: 60%;
        }
    }

    &__container {
        margin: rem(25px) 0 rem(65px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin: rem(50px) 0 rem(80px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin: rem(80px) 0 rem(120px);
        }
    }

    &__subnavigation {
        margin-bottom: rem(50px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(80px);
        }
    }

    &__wrap {
        margin-bottom: rem(70px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(105px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(120px);
        }
    }

    &__intro {
        margin-bottom: rem(30px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(30px);
        }

        &-info {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    &__title {
        margin-bottom: rem(30px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(75px);
        }
    }

    &__select {
        &-wrap {
            margin-bottom: rem(20px);

            .select2-container--default
                .select2-selection--single
                .select2-selection__rendered {
                font-weight: bold;
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(25px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: rem(288px);
                margin-bottom: 0;
            }
        }
    }

    &__total {
        font-weight: bold;
        text-transform: uppercase;
        color: $grayChateau;
        font-size: rem(12px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(14px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            width: rem(160px);
        }
    }

    &__info {
        display: flex;
        flex-direction: row;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: rem(250px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-left: rem(250px);
        }

        &-text {
            font-weight: bold;
            color: $grayChateau;
            font-size: rem(14px);
            margin-right: rem(45px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(16px);
            }
        }
    }

    &__list {
        @include list-reset();
        margin: 0 0 rem(20px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(30px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(40px);
        }
    }

    &__item {
        position: relative;
        //border-top: rem(1px) solid rgba($grayChateau, 0.25);
        min-height: 0;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 104%;
            margin-left: rem(-25px);
            min-height: 0;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            min-height: 0;
            //margin-left: rem(-50px);
        }

        &:first-child {
            border: none;
        }

        &.active {
            margin: 0 rem(-25px);

            box-shadow: 15px 15px 45px rgba(8, 37, 98, 0.1);
            border-top: none;
        }

        &:not(.active):before {
            @include line();
            border-bottom: rem(1px) solid rgba($grayChateau, 0.25);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 93%;
                left: rem(25px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: 90%;
                left: rem(35px);
            }
        }

        &:first-child {
            &:before {
                display: none;
            }
        }

        &-inner {
            padding: rem(30px) 0 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding: rem(30px) 0 0 rem(25px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding: rem(30px) rem(35px) 0;
            }
        }

        &.active &-inner {
            padding: rem(30px) rem(25px) rem(70px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                position: relative;
                padding: rem(35px) 0 rem(70px) rem(25px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding: rem(35px) rem(35px) rem(80px);
            }
        }

        &-top {
            position: relative;
            padding-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: flex;
                flex-direction: row;
                //justify-content: space-between;
                align-items: center;
                padding-bottom: rem(35px);
            }
        }

        &-bottom {
            position: relative;
            padding-top: rem(30px);
            //border-top: rem(1px) solid rgba($grayChateau, 0.25);

            &:before {
                @include line();
                top: 0;
                border-bottom: rem(1px) solid rgba($grayChateau, 0.25);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: 96%;
                    left: 0;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: 98%;
                }
            }
        }

        &-button {
            width: 100%;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: rem(336px);
                max-width: none;
            }
        }

        &-arrow {
            right: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                right: rem(24px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                top: rem(20px);
            }

            .icon {
                opacity: 1;
            }
        }

        &.active &-arrow {
            .icon {
                opacity: 1;
            }
        }
    }

    &__requirements {
        margin-bottom: rem(30px);
    }

    &__section {
        margin-bottom: rem(20px);

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            width: 55%;
        }

        &:nth-child(2) {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 43%;
            }
        }

        &-title {
            margin-bottom: rem(10px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(25px);
            }
        }

        &-list {
            display: flex;
            flex-direction: column;
        }

        &-item {
            width: 100%;
            margin-bottom: rem(20px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(25px);
            }
        }

        &-wrap {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }

    &__buttons {
        margin-bottom: rem(25px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: rem(30px);
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            justify-content: flex-start;
        }

        &--bottom {
            justify-content: flex-start;
        }
    }

    &__button {
        width: 100%;
        margin-bottom: rem(15px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: auto;
            margin-bottom: 0;
            padding-left: rem(65px);
            padding-right: rem(65px);
        }

        & + & {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-left: rem(20px);
            }
        }
    }

    &__link {
        font-weight: bold;
        .icon {
            transform: rotate(135deg);
            color: #000;
        }
    }

    &__all {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: rem(230px);
        }
    }

    &__young-specialists {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: rem(320px);
            max-width: none;
        }
    }

    &__address {
        display: flex;
        flex-direction: row;
        align-items: center;

        &-wrap {
            margin-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(50px);
            }
        }

        &-icon {
            margin-right: rem(10px);
            margin-top: rem(5px);
        }
    }

    &__job {
        font-size: rem(16px);
        padding-bottom: 0;
        margin-bottom: rem(10px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(20px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            width: rem(300px);
            margin-bottom: 0;
        }
    }

    &__speech {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(55px);
        }

        &-title {
            margin-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(60px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(85px);
            }
        }
    }

    &__quote {
        &-wrap {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: flex;
                flex-direction: row;
                padding: rem(50px) rem(65px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding: rem(65px) rem(35px);
            }
        }

        &-title {
            font-size: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(20px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(24px);
            }
        }

        &-info {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 60%;
                margin-top: rem(40px);
                margin-left: rem(50px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-left: rem(30px);
            }
        }

        &-text {
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(55px);
            }
        }

        &-name {
            font-weight: bold;
            font-size: rem(16px);
            margin-bottom: rem(15px);
        }

        &-job {
            max-width: rem(300px);
            white-space: normal;
        }
    }

    .modal-dialog {
        width: rem(850px);
    }

    &__modal {
        position: relative;
        top: rem(76px);
        padding: rem(55px) rem(15px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            top: rem(-8px);
            padding: rem(50px) rem(65px);
        }

        &-title {
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(50px);
            }
        }

        &-close {
            position: absolute;
            top: rem(20px);
            right: rem(20px);
            background-color: transparent;
            border: none;
        }

        &-button {
            width: 100%;
        }

        &-fieldset {
            position: relative;
            margin-bottom: rem(30px);

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                width: 45%;
            }

            &--textarea {
                margin-top: rem(50px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    width: 100%;
                }
            }

            &-wrap {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    flex-wrap: wrap;
                }
            }
        }

        &-attach {
            font-weight: bold;
            font-size: rem(16px);
            margin-bottom: rem(50px);

            .icon {
                width: rem(16px);
                color: #000;
            }
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__arrow {
            right: 0;
        }

        .calendar-page-detail__registration-form .select--phone-prefix {
            top: rem(15px);
        }

        .select2-container--default .select2-selection--single {
            min-height: rem(40px);
        }

        .select2-container--default
            .select2-selection--single
            .select2-selection__rendered {
            line-height: rem(38px);
        }
    }
}
