.header-sustainability {
    .slider {
        @media only screen and (max-width: 768px) {
            .owl-dots {
                display: flex;
                padding-top: 0.2rem;

                @media only screen and (min-width: 400px) {
                    justify-content: center;
                }
            }

            .owl-carousel button.owl-dot {
                background: white;
                color: inherit;
                border: 2px solid #444;
                padding: 2px;
                margin: 0.75rem;
                border-radius: 50%;

                &.active {
                    background: #2daa55;
                    border: 2px solid #2daa55;
                }
            }
        }
    }
    &__aside {
        &-title {
            cursor: pointer;
            transition: all 0.3s ease;

            @media (max-width: 768px) {
                display: flex;
            }

            .owl-next {
                padding: 0;
                padding-top: 0.5rem;
                margin-left: 1rem;

                @media (min-width: 320px) {
                    &::before {
                        transform: translateY(-50%) translateX(-50%) scale(1);
                        width: 1.75rem;
                        height: 1.75rem;
                        content: '';
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transition: all 0.3s ease;
                        background: #2daa55;
                        border-radius: 50%;
                        z-index: 1;
                    }
                }

                svg {
                    margin-top: -0.5rem;
                    fill: white;
                    width: 0.75rem;
                    height: 0.75rem;
                }
            }

            &:hover {
                color: #2daa55;

                .owl-next {
                    &::before {
                        background: white;
                        box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.2);
                    }

                    svg {
                        fill: #2daa55;
                    }
                }
            }
        }

        a {
            max-width: 200px;

            &:first-child {
                @media (max-width: 768px) {
                    max-width: 100%;
                }
            }

            @media (max-width: 768px) {
                margin-top: 0rem;
                margin-bottom: 1rem;
            }
        }

        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
        }
    }

    &__content {
        .icon {
            margin-bottom: 1rem;
            margin-top: 1rem;
            width: 2rem;
            height: 2rem;
        }

        &-heading {
            font-size: 1rem;
            margin-bottom: 1rem;
        }

        p {
            font-size: 0.75rem;
        }

        &-social {
            .development__slider-slide {
                margin: 1rem 2rem 0rem 0;
            }

            .concern-card__figure-img {
                min-height: 127px;
            }

            .development__slider-controls {
                position: absolute;
                right: -10rem;
                top: 50%;
                transform: translateY(-50%);

                @media (min-width: 1160px) {
                    margin-top: -3.9375rem !important;
                }

                @media (min-width: 992px) {
                    right: 1.5rem;
                }

                @media (min-width: 768px) {
                    right: 1rem;
                    margin-top: -3.9375rem !important;
                }

                @media (max-width: 767px) {
                    right: 1rem;
                    margin-top: -3.9375rem !important;
                }

                &-inner {
                    @media (min-width: 1160px) {
                        width: 11.25rem;
                    }

                    @media (min-width: 992px) {
                        width: 1rem;
                    }

                    @media (min-width: 768px) {
                        width: 1rem;
                    }

                    @media (max-width: 767px) {
                        width: 1rem;
                    }

                    .owl-prev {
                        @media (min-width: 768px) and (max-width: 1160px) {
                            margin-right: 1.5rem;
                        }

                        @media (max-width: 767px) {
                            margin-right: 1.5rem;
                        }
                    }

                    .calendar__calendar-arrows {
                        .owl-prev::before,
                        .owl-next::before {
                            width: 2.9375rem;
                            height: 2.9375rem;
                        }

                        @media (max-width: 768px) {
                            display: none;
                        }
                    }
                }
            }

            .icon {
                width: 1rem;
                height: 1rem;
                margin-top: 0;
                margin-bottom: 0;

                &::before {
                    transform: translateY(-50%) translateX(-50%) scale(1);
                    width: 1.75rem;
                    height: 1.75rem;
                }
            }
        }
    }
}
