.competition {
    &__container {
        padding: rem(20px) 0 rem(65px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: rem(45px) 0 rem(80px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            width: 45rem;
            padding: rem(80px) 0 rem(125px);
        }
    }

    &__subnavigation {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(80px);
        }
    }

    &__title {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(65px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(55px);
        }
    }

    &__details {
        margin-bottom: rem(10px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: 0;
        }
    }

    &__field {
        margin-bottom: rem(30px);
        font-weight: bold;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(55px);
        }

        &-name {
            color: #a5b0b9;
            margin-bottom: rem(5px);
        }

        &-text {
            font-size: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(20px);
            }
        }
    }

    &__docs {
        border-top: rem(1px) solid $iron;

        &-title {
            color: #a5b0b9;
            font-weight: bold;
            margin-top: rem(40px);
            margin-bottom: rem(25px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-top: rem(55px);
            }
        }
    }

    &__doc {
        margin-bottom: rem(40px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(50px);
        }
    }

    &__back-link {
        display: flex;
        align-items: center;
        margin-top: rem(25px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(30px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-top: rem(70px);
        }

        &-icon {
            transform: rotate(225deg);
        }

        &-span {
            color: $eucalyptus;
            font-weight: bold;
        }
    }

    .header__background-img {
        object-position: 61%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            object-position: bottom;
        }
    }
}
