@mixin click-area {
    &:before {
        position: absolute;
        content: '';
        top: -20px;
        bottom: -20px;
        right: -20px;
        left: -20px;
    }
}

@mixin click-area-after {
    &:after {
        position: absolute;
        content: '';
        top: -20px;
        bottom: -20px;
        right: -20px;
        left: -20px;
    }
}

@mixin list-reset {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin tdn {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

@mixin line {
    content: '';
    position: absolute;
    width: 100%;
    border-bottom: rem(1px) solid $iron;
}

@mixin phos-row {
    display: flex;
    flex-wrap: wrap;
}

@mixin phos-column($column-size, $total-columns) {
    position: relative;
    width: 100%;
    flex: 0 0 ($column-size / $total-columns) * 100%;
    max-width: ($column-size / $total-columns) * 100%;
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns(
    $columns: $grid-columns,
    $gutter: $grid-gutter-width,
    $breakpoints: $grid-breakpoints
) {
    // Common properties for all breakpoints
    %grid-column {
        position: relative;
        width: 100%;
        min-height: 1px; // Prevent columns from collapsing when empty
        padding-right: ($gutter / 2);
        padding-left: ($gutter / 2);
    }

    @each $breakpoint in map-keys($breakpoints) {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        // Allow columns to stretch full width below their breakpoints
        @for $i from 1 through $columns {
            .col#{$infix}-#{$i} {
                @extend %grid-column;
            }
        }

        .col#{$infix},
        .col#{$infix}-auto {
            @extend %grid-column;
        }

        @include media-breakpoint-up($breakpoint, $breakpoints) {
            // Provide basic `.col-{bp}` classes for equal-width flexbox columns
            .col#{$infix} {
                flex-basis: 0;
                flex-grow: 1;
                max-width: 100%;
            }

            .col#{$infix}-auto {
                flex: 0 0 auto;
                width: auto;
                max-width: none; // Reset earlier grid tiers
            }

            @for $i from 1 through $columns {
                .col#{$infix}-#{$i} {
                    @include make-col($i, $columns);
                }
            }

            .order#{$infix}-first {
                order: -1;
            }

            .order#{$infix}-last {
                order: $columns + 1;
            }

            @for $i from 0 through $columns {
                .order#{$infix}-#{$i} {
                    order: $i;
                }
            }

            // `$columns - 1` because offsetting by the width of an entire row isn't possible
            @for $i from 0 through ($columns - 1) {
                @if not($infix== '' and $i==0) {
                    // Avoid emitting useless .offset-0
                    .offset#{$infix}-#{$i} {
                        @include make-col-offset($i, $columns);
                    }
                }
            }
        }
    }
}

@mixin example-viewport ($point) {
    @if $point==v-mobile {
        @media only screen and (min-width: $breakpoint-v-mobile) {
            @include hide-show(v-mobile);
        }
    } @else if $point==mobile {
        @media only screen and (min-width: $breakpoint-mobile) {
            @include hide-show(mobile);
        }
    } @else if $point==tablet {
        @media only screen and (min-width: $breakpoint-tablet) {
            @include hide-show(tablet);
        }
    } @else if $point==desktop {
        @media only screen and (min-width: $breakpoint-desktop) {
            @include hide-show(desktop);
        }
    }
}

@mixin hide-show ($point) {
    .hide-me--#{$point} {
        display: none !important;
    }

    .show-me--#{$point} {
        display: block !important;
    }

    .show-me--#{$point}-flex {
        display: flex !important;
    }
}

.hide-me--default {
    display: none !important;
}

@include example-viewport (desktop);
@include example-viewport (tablet);
@include example-viewport (mobile);
@include example-viewport (v-mobile);


@mixin breakpoint($point) {
    @if $point==desktop {
        @media only screen and (min-width: $breakpoint-desktop) {
            @content;
        }
    } @else if $point==tablet {
        @media only screen and (min-width: $breakpoint-tablet) {
            @content;
        }
    } @else if $point==mobile {
        @media only screen and (min-width: $breakpoint-mobile) {
            @content;
        }
    } @else if $point==v-mobile {
        @media only screen and (min-width: $breakpoint-v-mobile) {
            @content;
        }
    }
}

// next time
@mixin mq($mq) {
    @each $key, $value in $breakpoints {
        @if $mq == $key {
            @media only screen and (min-width: $value) {
                @content;
            }
        }
    }
}

@mixin mq-up($mq) {
    @each $key, $value in $breakpoints {
        @if $mq == $key {
            @media only screen and (max-width: $value-1) {
                @content;
            }
        }
    }
}

@mixin margin($direction, $prop-value, $mq: "") {
    @each $key, $value in $breakpoints {
        @if $mq == $key {
            @include mq($key) {
                margin-#{$direction}: $prop-value;
            }
        } @else {
            margin-#{$direction}: $prop-value;
        }
    }
}

@mixin padding($direction, $prop-value, $mq: "") {
    @each $key, $value in $breakpoints {
        @if $mq == $key {
            @include mq($key) {
                padding-#{$direction}: $prop-value;
            }
        } @else {
            padding-#{$direction}: $prop-value;
        }
    }
}

@mixin gutter($prop, $negative: false) {
    @if $negative {
        @media screen and (min-width: #{breakpoint-min('sm')}) {
            #{$prop}: -$gutter;
        }
        #{$prop}: -$gutter-sm;
    } @else {
        @media screen and (min-width: #{breakpoint-min('sm')}) {
            #{$prop}: $gutter;
        }
        #{$prop}: $gutter-sm;
    }
}

%grid-gap {
    gap: 24px;

    @include breakpoint (mobile) {
        gap: 48px;
    }
}
