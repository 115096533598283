.location-selection-window {
    &__inner {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            max-width: rem(700px);
            margin: rem(96px) auto;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            max-width: rem(800px);
        }
    }

    &__body {
        position: relative;
        max-width: rem(500px);
        margin: 0 auto;
        padding: rem(32px) rem(16px) rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            max-width: 100%;
            margin: 0;
            padding: rem(48px) rem(64px);
        }
    }

    &__tabs {
        display: flex;
        margin-bottom: rem(24px);
        padding-left: 0;
        list-style: none;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(40px);
        }

        &-item {
            &:not(:last-of-type) {
                margin-right: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-right: rem(40px);
                }
            }
            /**
        * Скрывает табу "Выбор страны", пока не готова интеграция
      &:last-of-type {
        display: none;
      }
      */
        }

        &-tab {
            font-family: $foco;
            font-size: rem(16px);
            font-weight: bold;
            line-height: (24 / 16);
            color: rgba($firefly, 0.4);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(24px);
                line-height: (32 / 24);
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(32px);
                line-height: (40 / 32);
            }

            &--current {
                color: $firefly;

                &:hover,
                &:focus {
                    color: $firefly;
                }
            }
        }
    }

    &__form {
        &-radio {
            &-group {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    flex-direction: row;
                }
            }

            &-item {
                margin-bottom: rem(24px);
                padding-right: rem(8px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: 50%;
                    margin-bottom: rem(32px);
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    width: percentage(1 / 3);
                }
            }

            &-input {
                display: none;

                &:checked + label::after {
                    opacity: 1;
                    transition: all 0.1s ease-in-out;
                }
            }

            &-label {
                position: relative;
                margin-bottom: 0;
                padding-left: rem(40px);
                font-size: rem(16px);
                line-height: (24 / 16);
                letter-spacing: rem(0.2px);

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    border-radius: 50%;
                }

                &::before {
                    top: 0;
                    left: 0;
                    width: rem(24px);
                    height: rem(24px);
                    border: 1px solid $congressBlue;
                }

                &::after {
                    opacity: 0;
                    top: rem(6px);
                    left: rem(6px);
                    width: rem(12px);
                    height: rem(12px);
                    background-color: $congressBlue;
                }
            }
        }

        &-search {
            &-message {
                margin-top: rem(8px);
                margin-bottom: rem(32px);
                font-size: rem(16px);
                font-weight: bold;
                line-height: (24 / 16);
                letter-spacing: rem(0.25px);

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    font-size: rem(20px);
                    line-height: (32 / 20);
                }
            }
        }
    }

    &__close-button {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        padding: rem(24px);
        line-height: 0;
        border: none;
        background: none;
    }
}
