.procurement-types {
    margin-bottom: 4rem;

    &__lead {
        max-width: 38.75rem;

        @media (max-width: 1392px) {
            margin-top: 2rem;
        }
    }

    &__selects {
        display: flex;

        @media (max-width: 767px) {
            flex-direction: column;
        }
    }

    &__select-wrap {
        width: calc(50% - 2rem);
        margin-right: 2rem;

        @media (max-width: 767px) {
            width: 100%;
            margin-right: 0rem;
            margin-bottom: 2rem;
        }
    }

    &__input-wrap {
        width: calc(50% - 2rem);
        margin-right: 2rem;

        @media (max-width: 767px) {
            width: 100%;
            margin-right: 0;
        }
    }

    &__input-text {
        border-radius: 2rem;
        border: 1px solid #aaa;
        width: 100%;
        min-height: 2.5rem;
        padding-left: 1rem;
        color: #0e212f;
        text-overflow: ellipsis;

        &::placeholder {
            color: #0e212f;
        }
    }

    //   @keyframes iterate_color {
    //     from {
    //       fill: #004a86 !important;
    //       margin-left: 40px;
    //     }
    //     to {
    //       fill: #fd7222 !important;
    //       margin-right: -10px;
    //     }
    //   }

    &__table {
        margin-top: 3rem;

        &-info {
            h2 {
                font-weight: bold;
                font-size: 3rem;
                line-height: 2.5rem;
                color: #000000;
                margin-top: 4rem;
            }

            h3 {
                font-weight: bold;
                font-size: 2rem;
                line-height: 2.5rem;
                color: #000000;
                margin-top: 4rem;
            }

            h4 {
                margin-top: 1rem;
            }

            &-chart {
                margin-top: 3rem;
                overflow: auto;
                width: 100%;
            }
        }

        &-preloader {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 3rem 0;
            color: #fd7222;
            text-align: center;
            display: none;

            //   svg {
            //     @for $i from 1 through 7 {
            //       .preloader-#{$i} {
            //         fill: #004a86;
            //         animation: 0.6s
            //           cubic-bezier(0.42, 0, 0.58, 1)
            //           0.3s *
            //           $i
            //           infinite
            //           normal
            //           both
            //           running
            //           iterate_color;
            //       }
            //     }
            //   }
        }

        &-init {
            padding-top: 3rem;
            border-top: 1px solid #cfd3d5;

            p {
                color: #0e212f;
                font-weight: bold;
                opacity: 0.3;
            }
        }

        &-not-found {
            padding-top: 3rem;

            h5 {
                color: #0e212f;
                font-weight: bold;
                font-size: 1.5rem;
            }

            p {
                margin-top: 1rem;
                color: #0e212f;
                font-weight: bold;
                opacity: 0.3;
                max-width: 18rem;
            }
        }

        &-titles {
            display: flex;
            padding-bottom: 1.5rem;
            border-bottom: 1px solid #cfd3d5;
        }

        &-title {
            flex-basis: calc(100% / 3);

            @media (max-width: 768px) {
                margin-right: 3rem;
            }
        }

        &-value {
            display: flex;
            margin-top: 1.5rem;

            &-wrapper {
                @media (max-width: 768px) {
                    overflow: auto;
                }
            }

            &-name {
                flex-basis: calc(100% / 3);

                p {
                    font-weight: bold;
                    color: #0e212f;
                    max-width: 250px;

                    @media (max-width: 768px) {
                        font-size: 0.8rem;
                    }
                }

                @media (max-width: 768px) {
                    margin-right: 1.5rem;
                    min-width: 7rem;
                }
            }

            &-type {
                flex-basis: calc(100% / 3);

                p {
                    font-weight: bold;
                    color: #0e212f;
                    max-width: 240px;
                    opacity: 0.3;

                    @media (max-width: 768px) {
                        font-size: 0.7rem;
                    }
                }

                @media (max-width: 768px) {
                    margin-right: 1.5rem;
                    min-width: 6rem;
                }
            }

            &-contact {
                flex-basis: calc(100% / 3);
                display: flex;
                flex-direction: column;

                &-name {
                    font-weight: bold;

                    @media (max-width: 768px) {
                        font-size: 0.8rem;
                    }
                }

                &-phone {
                    margin-top: 1rem;
                    letter-spacing: 0.2px;
                    padding-left: 1.5rem;
                    position: relative;
                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='11' height='14' viewBox='0 0 11 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3crect x='1.75977' y='1' width='8' height='12' stroke='%230E212F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3crect x='4.75977' y='8' width='2' height='2' rx='1' fill='%230E212F'/%3e%3c/svg%3e ");
                        background-size: cover;
                        width: 11px;
                        height: 14px;
                        top: 50%;
                        margin-top: -7px;
                    }

                    @media (max-width: 768px) {
                        font-size: 0.8rem;
                    }
                }

                &-mail {
                    margin-top: 1rem;
                    letter-spacing: 0.2px;
                    padding-left: 1.5rem;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.75977 5L6.75977 1L11.7598 5V13H1.75977V5Z' stroke='%230E212F' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3crect x='4.75977' y='6' width='4' height='2' rx='1' fill='%230E212F'/%3e%3c/svg%3e ");
                        background-size: cover;
                        width: 11px;
                        height: 12px;
                        top: 50%;
                        margin-top: -6px;
                    }

                    @media (max-width: 768px) {
                        font-size: 0.8rem;
                    }
                }
            }
        }
    }

    .select2-container--default .select2-selection--single {
        min-height: 2.5rem;
    }

    .select2-container--default
        .select2-selection--single
        .select2-selection__rendered {
        line-height: 2.35rem;
        padding-left: 1rem;
        font-size: 1rem;
        color: #0e212f;
    }

    .select2-dropdown.select2-dropdown--below {
        margin-top: 0.5rem !important;
    }

    .select2-container--default
        .select2-selection--single
        .select2-selection__placeholder {
        color: #0e212f;
    }
}

.u-back-white {
    background: white;
}
