$baseFontSize: 16;

//colors
$black: #000000;
$black07: transparentize($black, 0.3);
$black04: transparentize($black, 0.6);
$white: #ffffff;
$black: #000000;
$black01: transparentize($black, 0.9);
$firefly: #0e212f;
$firefly02: transparentize($firefly, 0.8);
$firefly03: transparentize($firefly, 0.7);
$firefly04: transparentize($firefly, 0.6);
$firefly05: transparentize($firefly, 0.5);
$firefly06: transparentize($firefly, 0.4);
$firefly07: transparentize($firefly, 0.3);
$firefly08: transparentize($firefly, 0.2);
$firefly09: transparentize($firefly, 0.1);
$eucalyptus: #2daa55;
$eucalyptus01: transparentize($eucalyptus, 0.9);
$eucalyptus05: transparentize($eucalyptus, 0.5);
$eucalyptus08: transparentize($eucalyptus, 0.2);
$eucalyptus1: #2ba150;
$shamrock: #39d96c;
$seaGreen: #31aa47;
$mossGreen: #9fd5ab;
$goblin: #427c55;
$silverSand: #bec4c7;
$gray: #7e7e7e;
$catskill-white: #869097;
$wildSand: #f6f6f6;
$concrete: #f2f2f2;
$congressBlue: #004a86;
$congressBlue08: transparentize($congressBlue, 0.2);
$congressBlue04: transparentize($congressBlue, 0.6);
$congressBlue01: transparentize($congressBlue, 0.9);
$deepCerulean: #018fa1;
$glacier: #71b2c9;
$morningGlory: #9bd3dd;
$corn: #daaa00;
$buddhaGold: #a8ad00;
$rawSienna: #c88242;
$corduroy: #707372;
$grayChateau: #9fa6ac;
$iron: #cfd3d5;
$ironLight: #e7e9ea;
$iron07: transparentize($iron, 0.3);
$limedSpruce: #3e4d59;
$alto: #d8d8d8;
$tenn: #cc6200;
$supernova: #ffc700;
$goblin: #427c55;
$deepSapphire: #082562;
$mercury: #e9e9e9;
$mapBlue: #004a93;
$yellow-lite: #ffcc66;
$grey-lite: #c4c4c4;
$grey-middle: #979797;
$table-bg-grey: #F7F9FB;
$table-line-grey: #E1E4EB;
$table-text-black: #17171A;
$table-text-grey: #626C84;
$table-custom-bg-30: rgba(229, 237, 244, 0.30);
$table-custom-bg-50: rgba(229, 237, 244, 0.50);
$table-custom-bg-70: rgba(229, 237, 244, 0.70);
$table-custom-bg-90: rgba(229, 237, 244, 0.90);
$table-link: #249A4A;
$table-row-hover: #EAF6EE;
$corn-yellow: #FFCC66;
$corn-yellow-hover: #FFDFA0;
$table-accent-light: #E6EDF5;
$table-accent-middle: #8AA7C4;
$table-accent-dark: #2F5093;
$table-text-totall: #2F5093;
$table-text-middle: #414346;
$table-text-light: #838894;
$table-text-dark: #282830;

//spacers
$spacers-mobile-xl: 64px;
$spacers-mobile-l: 32px;
$spacers-mobile-m: 16px;
$spacers-mobile-s: 8px;
$spacers-desktop-xl: 96px;
$spacers-desktop-l: 48px;
$spacers-desktop-m: 24px;

//fonts
$fonts-default: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
$calibry: 'Calibri', $fonts-default;
$foco: 'Foco', $fonts-default;

//z-indexes
$aside-z-index: 2;
$slider-z-index: 1;

$grid-breakpoints: (
    xs: 0,
    sm: 600px,
    md: 1024px,
    lg: 1360px,
    xl: 1680px
) !default;
$breakpoints: $grid-breakpoints;

$container-max-widths: (
    sm: 696px, // 648
    md: 984px, // 936
    lg: 1476px,// 1296
    xl: 1496px // 1296
) !default;

$breakpoint-desktop: 1680px;
$breakpoint-tablet: 1360px;
$breakpoint-mobile: 1024px;
$breakpoint-v-mobile: 600px;

$gutter: 48px;
$gutter-sm: 16px;

$transition: .2s ease-in;
