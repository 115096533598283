.product-slider {
    max-width: rem(1472px);
    margin: 0 auto;

    padding-bottom: rem(95px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding-bottom: rem(155px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding-bottom: rem(345px);
    }

    &--about {
        padding-bottom: 0;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(5px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(285px);
        }
    }

    &--press {
        padding-bottom: rem(64px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(125px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(410px);
        }
    }

    &__inner {
        position: relative;
    }

    &__slides {
        display: flex;
        padding-bottom: 50px;
    }

    &__slide {
        width: 100%;
        flex-shrink: 0;

        transition: opacity 0.3s ease;
        opacity: 0;
        pointer-events: none;

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(210px);
        }

        &--about {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(285px);
            }
        }

        &--press {
            z-index: 1;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(96px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(317px);
            }
        }

        &--current {
            opacity: 1;
            pointer-events: all;
        }

        &-inner {
            display: flex;
            flex-wrap: wrap;

            flex-direction: column;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                flex-direction: row;
            }
        }

        &-intro-text {
            margin-bottom: rem(32px);
            font-size: rem(20px);
            line-height: (28/20);
            font-weight: bold;
            color: rgba($firefly, 0.4);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(24px);
                line-height: (36/24);
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(64px);
            }
        }

        &-figure {
            position: relative;
            width: 100%;

            flex: 0 0 40%;
            max-width: 40%;

            margin-bottom: rem(20px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                height: auto;

                flex: 0 0 34%;
                max-width: 34%;
                padding-right: rem(20px);

                margin-bottom: 0;

                display: flex;
                align-items: center;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                flex: 0 0 50%;
                max-width: 50%;
                padding-right: rem(24px);

                align-items: flex-start;
            }

            &--press {
                align-items: flex-start;
                flex: 0 0 50%;
                max-width: 50%;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    flex: 0 0 41.75%;
                    max-width: 41.75%;
                    padding-right: 0;

                    align-items: flex-start;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    flex: 0 0 50%;
                    max-width: 50%;
                    padding-right: rem(24px);
                }
            }

            &-img {
                max-width: 100%;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }

        &-info {
            position: relative;
            width: 100%;

            padding-bottom: rem(245px);
            min-height: rem(380px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                flex: 0 0 66%;
                max-width: 58%;
                padding-left: rem(20px);
                min-height: rem(430px);

                padding-bottom: rem(100px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                flex: 0 0 50%;
                max-width: 50%;
                padding-left: rem(24px);
                min-height: rem(562px);
            }

            &--about {
                padding-bottom: 46.875vw;
                min-height: 0;

                @media screen and (min-width: #{breakpoint-min('sm')}) {
                    padding-bottom: rem(245px);
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    padding-left: 0;
                    min-height: rem(450px);
                    padding-bottom: 0;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    // flex: 0 0 72%;
                    // max-width: 72%;
                    min-height: rem(290px);
                    padding-left: 0;
                }
            }

            &--press {
                padding-bottom: rem(96px);
                min-height: 0;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    padding-bottom: 0;
                    flex: 0 0 58.25%;
                    max-width: 58.25%;
                    min-height: rem(342px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    flex: 0 0 50%;
                    max-width: 50%;
                    min-height: rem(410px);
                }
            }
        }

        &-category {
            margin-bottom: rem(40px);
            font-size: rem(14px);
            font-weight: bold;
            line-height: 1.71;
            text-transform: uppercase;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(76px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-top: rem(38px);
                margin-bottom: rem(118px);
            }

            &--about {
                font-family: $foco;
                font-size: rem(24px);
                line-height: 1.4;
                font-weight: 700;
                color: $silverSand;
                text-transform: none;
                margin-bottom: rem(26px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(24px);
                    margin-bottom: rem(26px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: 0;
                    margin-bottom: rem(26px);
                }
            }

            &--press {
                display: flex;
                justify-content: space-between;
                margin-bottom: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(32px);
                    justify-content: flex-start;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: 0;
                    margin-bottom: rem(64px);
                }
            }

            &-link {
                margin-left: rem(32px);
            }
        }

        &-name {
            display: flex;
            align-items: center;
            margin-bottom: rem(8px);
            position: relative;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(30px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(40px);
            }

            &--about {
                font-size: rem(24px);
                font-weight: 900;
                line-height: 1.4;
                margin-bottom: rem(10px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(32px);
                    margin-bottom: rem(20px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(32px);
                    margin-bottom: rem(32px);
                }
            }

            &--press {
                font-size: rem(24px);
                font-weight: bold;
                margin-bottom: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(24px);
                    margin-bottom: rem(32px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(56px);
                    margin-bottom: rem(24px);
                    margin-right: rem(-112px);
                }
            }

            &-icon {
                flex-shrink: 0;
                height: rem(24px);
                width: auto;
                margin-right: rem(16px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    position: relative;
                    left: 0;
                    height: rem(48px);
                    margin-right: rem(16px);
                }
            }
        }

        &-description {
            font-size: rem(14px);
            color: $black;
            letter-spacing: rem(0.2px);
            font-weight: 400;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(16px);
                line-height: rem(24px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                max-width: 72%;
            }

            &--press {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    padding-right: rem(10px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    padding-right: 0;
                    max-width: none;
                    margin-right: rem(-20px);
                    font-weight: bold;
                }
            }

            a {
                line-height: 1.2;
                color: #249a4a;
                background-image: linear-gradient(currentColor, currentColor);
                background-position: 0% 100%;
                background-repeat: no-repeat;
                background-size: 0% 1px;
                transition: all $transition;

                &:hover {
                    color: #33be60;
                    background-size: 100% 1px;
                }

                &:active {
                    color: #2ba150;
                }
            }

            br {
                content: ' ';
                display: block;
                margin-bottom: rem(10px);
            }
        }

        &-button {
            min-width: rem(230px);
            position: absolute;
            bottom: 0;
            left: rem(24px);

            top: auto;

            &:hover {
                top: auto;
                bottom: rem(5px);
            }

            &--press {
                width: 100%;
                left: 0;
                min-width: rem(192px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    width: auto;
                    left: rem(24px);
                }
            }
        }
    }

    &__controls {
        &--btns-container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                bottom: rem(28px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                bottom: 0;
            }

            &-press {
                margin-top: rem(40px);
                position: relative;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-top: 0;
                    position: absolute;
                }
            }
        }

        &-inner {
            @media screen and (min-width: #{breakpoint-min('md')}) {
                justify-content: flex-end;
                height: rem(64px);
            }
        }

        &-button {
            min-width: rem(230px);
            width: 100%;
            margin: 0 auto;
            z-index: 50;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: auto;
                margin-left: 25%;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                margin-left: 24%;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-left: 0;
                margin-right: rem(106px);
            }
        }

        &-timelapse {
            margin-left: rem(24px);
            margin-right: rem(24px);
            flex-grow: 1;
        }

        &-arrows {
            display: none;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: flex;
                position: relative;
                z-index: 2;
            }

            &--press {
                display: flex;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    display: none;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: flex;
                }
            }

            &-prev,
            &-next {
                position: relative;
                padding: rem(24px);
                background: none;
                border: none;
                appearance: none;
                font-size: rem(11px);
                color: $congressBlue;
                transition: all 0.3s ease;

                &:before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: rem(56px);
                    height: rem(56px);
                    transition: all 0.3s ease;
                    transform: translateY(-50%) translateX(-50%) scale(0);
                    background: $eucalyptus;
                    border-radius: 50%;
                    z-index: 1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: rem(-25px);
                    right: rem(-25px);
                    top: rem(-25px);
                    bottom: rem(-25px);
                }

                &:hover {
                    color: $white;

                    &:before {
                        transform: translateY(-50%) translateX(-50%) scale(1);
                    }
                }

                .icon {
                    position: relative;
                    z-index: 2;
                }
            }

            &-prev {
                // margin-right: rem(48px);
            }

            &-next {
            }
        }

        &-circle {
            position: absolute;

            &-container {
                &__inner {
                    position: absolute;
                    height: rem(230px);
                    bottom: 0;
                    left: rem(-9999px);
                    right: rem(-9999px);
                    width: 100vw;
                    margin: 0 auto;

                    z-index: 2;

                    overflow: hidden;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        position: relative;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 100%;
                        width: 100%;

                        overflow: visible;
                    }

                    &--about {
                        height: 40.625vw;

                        @media screen and (min-width: #{breakpoint-min('sm')}) {
                            height: rem(230px);
                        }

                        @media screen and (min-width: #{breakpoint-min('md')}) {
                            height: 100%;
                        }
                    }
                }

                .circles {
                    position: absolute;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        left: rem(-104px);
                    }

                    @media screen and (min-width: #{breakpoint-min('lg')}) {
                        left: rem(42px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        left: rem(-238px);
                    }
                }
            }

            &--main,
            &--first,
            &--second,
            &--third {
                top: rem(110px);
                left: rem(88px);

                height: rem(248px);
                width: rem(248px);

                display: none;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    display: block;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    top: rem(209px);
                    left: rem(256px);

                    height: rem(607px);
                    width: rem(607px);
                }
            }

            &--first,
            &--second,
            &--third {
                background-color: $seaGreen;
            }

            &-about {
                position: absolute;

                &--main,
                &--first,
                &--second,
                &--third {
                    top: rem(108px);
                    right: rem(201px);

                    height: rem(238px);
                    width: rem(238px);

                    display: none;

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        display: block;
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        top: rem(200px);
                        right: rem(205px);

                        height: rem(458px);
                        width: rem(458px);
                    }
                }

                &--first,
                &--second,
                &--third {
                    background-color: $seaGreen;
                }
            }
        }

        &-num {
            font-family: $foco;
            font-weight: bold;
            line-height: 1;
            font-size: rem(60px);
            text-align: right;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(48px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(136px);
            }

            &--total {
                margin-left: rem(8px);
                padding-bottom: rem(11px);
                font-size: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: rem(14px);
                    padding-right: em(10px, 24px);
                    padding-bottom: 0;
                    margin-top: rem(4px);
                    margin-left: 0;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-top: rem(8px);
                    font-size: rem(24px);
                    padding-right: em(20px, 24px);
                }
            }

            &-container {
                position: absolute;
                top: rem(66px);
                right: 50%;
                transform: translateX(50%);

                display: flex;
                flex-direction: row;
                align-items: flex-end;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    flex-direction: column;
                    align-items: inherit;

                    top: rem(205px);
                    right: 95px;
                    transform: translateX(0);
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    right: 65px;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    top: rem(443px);
                    right: 0;
                }

                &--about {
                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        right: auto;
                        left: rem(-14px);
                        top: rem(200px);
                    }

                    @media screen and (min-width: #{breakpoint-min('lg')}) {
                        left: rem(108px);
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        left: rem(-84px);
                        top: rem(357px);
                    }
                }
            }
        }

        &-radial {
            position: absolute;

            top: rem(20px);
            // height: rem(320px);
            // width: rem(320px);
            width: 108vw;
            height: 108vw;

            border-radius: 50%;
            border: rem(1px) dashed $silverSand;

            transition: transform 0.6s ease;

            transform: rotate(0);

            margin: 0 auto;
            left: rem(-9999px);
            right: rem(-9999px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                top: 0;
                left: rem(-24px);
                right: auto;

                height: rem(454px);
                width: rem(454px);

                padding-bottom: 0;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                left: rem(24px);
                height: rem(1022px);
                width: rem(1022px);
            }

            &--about {
                @media screen and (min-width: #{breakpoint-min('md')}) {
                    left: -225px;
                    right: auto;
                }

                @media screen and (min-width: #{breakpoint-min('lg')}) {
                    left: -90px;
                    right: auto;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    left: -435px;
                    right: auto;
                    height: rem(860px);
                    width: rem(860px);
                }
            }

            &-dot {
                position: absolute;
                left: 54vw;
                top: 50%;

                z-index: 3;

                display: flex;
                align-items: center;
                justify-content: center;

                width: rem(8px);
                height: rem(8px);

                margin-left: rem(-4px);
                margin-top: rem(-4px);

                background-color: $silverSand;
                border-radius: 50%;

                transform-style: preserve-3d;

                @include click-area-after;

                &:hover {
                    background-color: $silverSand;
                }

                @for $i from 1 through 12 {
                    &:nth-child(#{$i}) {
                        transform: translateX(54vw * cos($i * 30deg - 120deg))
                            translateY(54vw * sin($i * 30deg - 120deg));
                    }
                }

                &-before {
                    position: absolute;
                    top: rem(-11px);
                    left: rem(-11px);
                    z-index: 3;
                    width: rem(30px);
                    height: rem(30px);
                    background: $white;
                    border-radius: 50%;
                    box-shadow: 0 rem(2px) rem(5px) 0 rgba(0, 0, 0, 0.15);
                    transform: scale(0);
                    transition: all 0.3s ease;
                }

                &-color {
                    width: 100%;
                    height: 100%;
                    background-color: $silverSand;
                    position: relative;
                    z-index: 3;
                    border-radius: 50%;

                    &:hover {
                        background-color: $silverSand;
                    }
                }

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    left: rem(227px);

                    @for $i from 1 through 12 {
                        &:nth-child(#{$i}) {
                            transform: translateX(
                                    #{rem(227px * cos($i * 30deg - 210deg))}
                                )
                                translateY(
                                    #{rem(227px * sin($i * 30deg - 210deg))}
                                );
                        }
                    }
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    left: rem(511px);

                    width: rem(16px);
                    height: rem(16px);

                    margin-left: rem(-8px);
                    margin-top: rem(-8px);

                    @for $i from 1 through 12 {
                        &:nth-child(#{$i}) {
                            transform: translateX(
                                    #{rem(511px * cos($i * 30deg - 210deg))}
                                )
                                translateY(
                                    #{rem(511px * sin($i * 30deg - 210deg))}
                                );
                        }
                    }

                    &-before {
                        position: absolute;
                        top: rem(-20px);
                        left: rem(-20px);
                        z-index: 3;
                        width: rem(56px);
                        height: rem(56px);
                        background: $white;
                        border-radius: 50%;
                        box-shadow: 0 rem(5px) rem(10px) 0 rgba(0, 0, 0, 0.15);
                        transform: scale(0);
                        transition: all 0.3s ease;
                    }
                }

                &--about {
                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        @for $i from 1 through 12 {
                            &:nth-child(#{$i}) {
                                transform: translateX(
                                        #{rem(227px * cos($i * 30deg - 30deg))}
                                    )
                                    translateY(
                                        #{rem(227px * sin($i * 30deg - 30deg))}
                                    );
                            }
                        }
                    }

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        left: rem(430px);

                        @for $i from 1 through 12 {
                            &:nth-child(#{$i}) {
                                transform: translateX(
                                        #{rem(430px * cos($i * 30deg - 30deg))}
                                    )
                                    translateY(
                                        #{rem(430px * sin($i * 30deg - 30deg))}
                                    );
                            }
                        }
                    }
                }

                &--current {
                    background-color: currentColor;

                    &:hover {
                        background-color: currentColor;
                    }

                    .product-slider__controls-radial-dot-color {
                        background-color: currentColor;

                        &:hover {
                            background-color: currentColor;
                        }
                    }

                    .product-slider__controls-radial-dot-before {
                        transform: scale(1);
                    }
                }
            }
        }
    }
}
