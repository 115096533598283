.info-panel {
    $parent: &;

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 4rem;
    overflow: hidden;
    background-color: #c88242;
    transition: height 0.3s;

    &.is-hidden {
        height: 0;
    }

    &__content {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;

        margin-left: 2em;
        margin-right: 2em;
    }

    &__link {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
    }

    &__text,
    &__badge {
        line-height: 1rem;
        font-size: 0.875rem;
        text-transform: uppercase;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: 0.875rem;
            line-height: 1.5rem;
        }
    }

    &__text {
        color: #fff;
        font-weight: 700;
    }

    &__text + &__badge {
        margin-left: 0.875rem;
    }

    &__badge {
        display: block;
        flex-shrink: 0;
        padding: 0 0.75rem;
        color: #ff0000;
        border-radius: 1.25rem;
        background-color: #fff;
    }

    &__close {
        position: absolute;
        right: 0;
        margin-right: -2em;
        display: block;
        width: 1.5rem;
        height: 1.5rem;
        flex-shrink: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 0.75rem 0.75rem;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMC4zNDMxODYgMC4zNDMxODZDMC43MzM3MTEgLTAuMDQ3MzM4IDEuMzY2ODggLTAuMDQ3MzM4IDEuNzU3NCAwLjM0MzE4NkwxMS42NTY5IDEwLjI0MjdDMTIuMDQ3NCAxMC42MzMyIDEyLjA0NzQgMTEuMjY2NCAxMS42NTY5IDExLjY1NjlDMTEuMjY2NCAxMi4wNDc0IDEwLjYzMzIgMTIuMDQ3NCAxMC4yNDI3IDExLjY1NjlMMC4zNDMxODYgMS43NTc0Qy0wLjA0NzMzOCAxLjM2Njg4IC0wLjA0NzMzOCAwLjczMzcxMSAwLjM0MzE4NiAwLjM0MzE4NloiIGZpbGw9IndoaXRlIi8+PHBhdGggZD0iTTAuMzQzMTg2IDExLjY1NjlDLTAuMDQ3MzM4MSAxMS4yNjY0IC0wLjA0NzMzODEgMTAuNjMzMiAwLjM0MzE4NiAxMC4yNDI3TDEwLjI0MjcgMC4zNDMxODZDMTAuNjMzMiAtMC4wNDczMzgzIDExLjI2NjQgLTAuMDQ3MzM4IDExLjY1NjkgMC4zNDMxODZDMTIuMDQ3NCAwLjczMzcxMSAxMi4wNDc0IDEuMzY2ODggMTEuNjU2OSAxLjc1NzRMMS43NTc0IDExLjY1NjlDMS4zNjY4OCAxMi4wNDc0IDAuNzMzNzEgMTIuMDQ3NCAwLjM0MzE4NiAxMS42NTY5WiIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
        cursor: pointer;
    }

    &--theme-1 {
        height: 60px;
        background-color: #285943;

        #{$parent}__text + #{$parent}__badge {
            margin-left: 2rem;
        }

        #{$parent} {
            &__text,
            &__badge {
                line-height: 1rem;
                font-size: 0.875rem;
            }

            &__text {
                font-weight: 700;
                font-size: 18px;
                line-height: 1.2;
                letter-spacing: 0.25px;
                text-transform: none;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    font-size: 20px;
                }
            }

            &__badge {
                padding: 0.25rem 2rem;
                font-family: $calibry;
                font-weight: 700;
                font-size: 14px;
                line-height: 24px;
                color: #285943;
                text-transform: none;
            }
        }
    }
}
