.popup {
    &__cookie-policy {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background: white;
        z-index: 100;
        box-shadow: 15px 15px 45px rgba(8, 37, 98, 0.1);

        &-container {
            display: flex;
            justify-content: center;
            padding: rem(32px) rem(8px) rem(24px);
        }

        &-content {
            width: 100%;

            p {
                margin-bottom: rem(24px);
            }

            margin-bottom: rem(8px);
        }

        &-button {
            max-width: rem(288px);
            width: 100%;
        }
    }

    @media screen and (min-width: #{breakpoint-min('sm')}) {
        &__cookie-policy {
            border-radius: rem(6px);
            margin-bottom: rem(24px);

            &-container {
                padding: rem(48px) rem(32px);
                justify-content: flex-start;
            }

            &-content {
                margin-bottom: rem(16px);
            }

            &-button {
                max-width: none;
                width: auto;
            }
        }
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        &__cookie-policy {
            font-size: rem(16px);

            &-container {
                padding: rem(40px) rem(56px);
            }
        }
    }
}
