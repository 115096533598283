.color {
    &-text {
        &--firefly {
            color: $firefly;
        }

        &--firefly-40 {
            color: rgba($firefly, 0.4);
        }

        &--eucalyptus {
            color: $eucalyptus;
        }

        &--grey-lite {
            color: $grey-lite;
        }

        &--grey-middle {
            color: $grey-middle;
        }

        &--gray-chateau {
            color: $grayChateau;
        }
    }
}
