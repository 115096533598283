.input {
    &-search {
        &.active {
            color: $firefly;
        }

        &__input {
            padding: rem(21px) rem(42px) rem(24px) rem(21px);
            border-radius: rem(50px);
            border: 1px solid $grayChateau;
            width: 100%;
            height: rem(64px);
            margin-top: rem(7px);
        }

        &--header {
            display: none;

            &.active {
                color: $firefly;
            }

            &__input {
                padding: rem(21px) rem(24px);
                border-radius: rem(50px);
                border: 1px solid $grayChateau;
                width: 100%;
            }

            .html--header-search-opened & {
                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    display: block;
                    width: 73%;
                    position: absolute;
                    left: 0;
                }

                @media all and (-ms-high-contrast: none),
                    (-ms-high-contrast: active) {
                    & {
                        top: -10px;
                    }
                }
            }
        }

        &--main {
            display: block;
            position: relative;

            .icon-container {
                position: absolute;
                right: rem(14px);
                top: 10%;
                color: $firefly04;
                cursor: default;
                width: rem(16px);
                height: rem(16px);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: rem(24px);

                &:hover {
                    width: rem(18px);
                    height: rem(18px);
                    color: $firefly06;
                }
            }

            .icon {
                width: 100%;
                height: 100%;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                display: none;
            }
        }

        &--top {
            display: none;
            position: relative;

            .icon-container {
                position: absolute;
                right: rem(14px);
                top: 10%;
                color: $firefly04;
                cursor: default;
                width: rem(16px);
                height: rem(16px);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: rem(24px);

                &:hover {
                    width: rem(18px);
                    height: rem(18px);
                    color: $firefly06;
                }
            }

            .icon {
                width: 100%;
                height: 100%;
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                .html--header-search-opened & {
                    display: block;
                }
            }
        }

        &--page {
            position: relative;

            .icon-container {
                position: absolute;
                right: rem(14px);
                top: 10%;
                color: $firefly04;
                cursor: default;
                width: rem(16px);
                height: rem(16px);
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0;
                margin: rem(24px);

                &:hover {
                    width: rem(18px);
                    height: rem(18px);
                    color: $firefly06;
                }
            }

            .icon {
                width: 100%;
                height: 100%;
            }
        }
    }

    &-textarea {
        width: 100%;
        min-height: rem(150px);
        padding: rem(10px) rem(15px);
        border-radius: rem(4px);
        border: rem(1px) solid $iron;
        text-align: left;
        font-family: $foco;
        font-size: rem(16px);
        font-weight: bold;

        &::placeholder {
            color: $silverSand;
        }

        &:focus {
            outline: none;
        }

        &__label {
            margin-bottom: rem(15px);
            font-weight: bold;
            color: #0e212f;
        }
    }

    &-calendar {
        &__wrap {
            position: relative;
        }

        &__icon {
            position: absolute;
            top: 0;
            right: 0;

            .icon {
                color: $firefly03;
            }
        }
    }
}
