.product {
    &-card {
        display: inline-flex;
        margin-bottom: rem(70px);
        width: 100%;

        &__figure {
            max-width: rem(100px);

            &-img {
                width: 100%;
            }
        }
        &__info {
            margin-left: rem(28px);
            max-width: rem(404px);
        }
    }

    &-cards {
        display: flex;
        flex-wrap: wrap;

        &__item--small {
            width: 50%;
        }
    }

    &-info {
        &--small {
            max-width: rem(192px);
            margin-right: rem(10px);
        }
    }
}
