.btn-top {
    pointer-events: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: rem(24px);
    z-index: 50;

    &__btn {
        pointer-events: all;
        width: rem(48px);
        height: rem(48px);
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 rem(15px) rem(45px) rgba(8, 37, 98, 0.15);
        border-radius: 50%;
        font-size: rem(18px);

        background: $eucalyptus;
        color: $white;

        transition: background 0.3s ease, color 0.3s ease, opacity 0.2s ease,
            visibility 0.2s ease;

        visibility: hidden;
        opacity: 0;

        &:hover {
            background: $white;
            color: $eucalyptus;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            position: fixed;
            width: rem(64px);
            height: rem(64px);
            right: rem(64px + 17px);
            bottom: rem(64px);
        }

        &-icon {
        }

        &--visible {
            visibility: visible;
            opacity: 1;
        }
    }
}
