.form {
    position: relative;

    &__row {
        margin-bottom: -24px;
    }

    &__item {
        margin-bottom: 24px;

        .button {
            width: 100%;
            max-width: none;
        }
    }

    &__agreement {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;

        .z-input-file {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            overflow: hidden;
        }
    }

    &__phone {
        position: relative;

        .select2 {
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 2;
            transform: translateY(-50%);
        }

        .select2-selection {
            min-height: auto;
        }

        .select2-selection__rendered {
            line-height: 1.1 !important;
        }

        .z-input,
        .z-input__placeholder {
            padding-left: 55px !important;
        }
    }

    &__success-msg {
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.85);
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        transition: 0.2s ease-in;

        &.is-opened {
            opacity: 1;
        }
    }

    &__error {
        color: #D82623;
        font-size: 0.8125em;
    }

    @media screen and (min-width: 768px) {
        &__row {
            margin-bottom: -32px;
        }

        &__item {
            margin-bottom: 32px;

            .button {
                width: auto;
                max-width: 18rem;
            }
        }
    }
}