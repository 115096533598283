.main {
    &__projects {
        max-width: rem(1472px);
        margin: 0 auto;

        padding-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(80px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-bottom: rem(130px);
        }

        &-card {
            padding-top: rem(15px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(15px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                &:hover {
                    transition: 0.4s;
                    box-shadow: 0.9375rem 0.9375rem 2.8125rem 0
                        rgba(8, 37, 98, 0.1);
                    .link--black-hover-green {
                        color: #2daa55;
                    }
                }
            }
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;

            height: rem(370px);
            margin-bottom: rem(100px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                height: rem(500px);
                margin-bottom: 0;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                height: rem(900px);
            }

            &-inner {
                position: relative;
                z-index: 2;
                text-align: center;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    text-align: left;
                }
            }

            &-name {
                font-size: rem(14px);
                font-weight: bold;
                color: $silverSand;
                line-height: 1.44;
                letter-spacing: 0.2px;
                margin-bottom: rem(16px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(6px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(16px);
                    margin-bottom: rem(4px);
                }
            }

            &-place {
                display: flex;
                justify-content: center;
                margin-bottom: rem(36px);
                align-items: baseline !important;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    justify-content: flex-start;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(55px);
                }

                &-toggler {
                    color: $silverSand;
                    transition: all 0.3s ease;

                    &:hover {
                        color: $firefly;
                    }

                    &:not(:last-of-type) {
                        margin-right: rem(32px);

                        @media screen and (min-width: #{breakpoint-min('xl')}) {
                            margin-right: rem(40px);
                        }
                    }

                    &--current {
                        color: $firefly;
                    }
                }
            }

            &-date {
                font-size: 12px;
                color: #858C92;
                font-weight: 400;
                margin-left: 40px;
            }

            &-number {
                display: flex;
                justify-content: center;
                margin-bottom: rem(40px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    justify-content: flex-start;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(60px);
                }

                &-price {
                    font-family: $foco;
                    font-size: rem(60px);
                    font-weight: bold;
                    line-height: 0.81;
                    height: em(70px, 104px);
                    margin-right: rem(10px);

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(104px);
                    }
                }

                &-right {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    font-family: $foco;
                    font-weight: bold;
                    line-height: 1;

                    font-size: rem(14px);

                    @media screen and (min-width: #{breakpoint-min('xl')}) {
                        font-size: rem(16px);
                    }
                }
            }

            &-link {
                font-weight: bold;
                color: $eucalyptus;

                &:hover {
                    color: $shamrock;
                }

                &:visited {
                    color: $eucalyptus;
                    &:hover {
                        color: $shamrock;
                    }
                }
            }

            &-circle {
                position: absolute;
                border-radius: 50%;
                pointer-events: none;

                &--main,
                &--first,
                &--second,
                &--third {
                    top: 0;

                    height: rem(370px);
                    width: rem(370px);

                    margin: 0 auto;
                    left: rem(-999px);
                    right: rem(-999px);

                    /*@media screen and (min-width: #{breakpoint-min('sm')}) {
            left: rem(-75px);
            right: auto;
          }*/

                    @media screen and (min-width: #{breakpoint-min('md')}) {
                        right: rem(48px);
                        left: auto;
                        margin: 0;

                        height: rem(500px);
                        width: rem(500px);
                    }

                    @media screen and (min-width: #{breakpoint-min('lg')}) {
                        right: rem(24px);

                        height: rem(900px);
                        width: rem(900px);
                    }
                }

                &--first,
                &--second,
                &--third {
                    background-color: $corn;
                }
            }
        }

        &-projects {
            padding-top: 0;

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(120px);
            }
        }

        &-project {
            margin-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(60px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(180px);
            }

            &-header {
                display: flex;
                align-items: center;
                margin-bottom: rem(16px);

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-bottom: rem(22px);
                }
            }

            &-img {
                width: auto;
                height: rem(40px);
                margin-right: rem(24px);
                flex-shrink: 0;

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    height: rem(72px);
                    margin-bottom: rem(48px);
                }
            }

            &-title {
            }

            &-text {
                color: $silverSand;
                font-size: rem(14px);
                letter-spacing: 0.2px;

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(16px);
                    font-weight: bold;
                }
            }
        }
    }
}
