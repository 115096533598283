.product-details-list {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;

    &__item {
        display: flex;

        &:not(:last-of-type) {
            margin-bottom: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(24px);
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                margin-bottom: rem(32px);
            }
        }
    }

    &__icon {
        flex-shrink: 0;
        margin-right: rem(16px);
        font-size: rem(27px);
        color: $deepCerulean;
    }
}
