.circles {
    &--default {
        width: 16.25rem;
        height: 16.25rem;
        margin: auto;
        position: relative;

        @media screen and (min-width: #{breakpoint-min('md')}),
        screen and (min-width: #{breakpoint-min('xl')}) {
            width: 21rem;
            height: 21rem;
        }
    }

    &--large {
        width: 18rem;
        height: 18rem;
        position: relative;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: 31.25rem;
            height: 31.25rem;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}),
        screen and (min-width: #{breakpoint-min('xl')}) {
            width: 56.25rem;
            height: 56.25rem;
        }
    }

    &--xl {
        width: 47.5rem;
        height: 47.5rem;
        position: relative;
    }

    &__content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column nowrap;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 2;
    }

    &__center {
        display: block;
        font-weight: 700;
        font-size: 8.5rem;
        line-height: 8.5rem;
        color: $congressBlue;
        font-family: Foco, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
            Segoe UI Symbol;
    }

    &__title {
        display: block;
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.5rem;
        max-width: 11.25rem;
        text-align: center;
        font-family: Foco, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
            Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,
            Segoe UI Symbol;
    }

    &__text {
        display: block;
        font-size: 1rem;
        line-height: 1.5rem;
        max-width: 11.25rem;
        text-align: center;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &--color {
        &-glacier {
            .circle:not(.circle--main) {
                background-color: $glacier;
            }
        }

        &-corn {
            .circle:not(.circle--main) {
                background-color: $corn;
            }
        }
    }
}

.circle {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    pointer-events: none;
    transition: background-color 0.3s ease;

    &--main {
        z-index: 1;
        background-size: cover;
        background-color: $white;
        background-position: center;
        background-repeat: no-repeat;
    }

    &--first {
        opacity: 0.1;
        animation: firstCircle 10s infinite ease;
    }

    &--second {
        opacity: 0.1;
        animation: secondCircle 10s infinite ease;
    }

    &--third {
        opacity: 0.1;
        animation: thirdCircle 10s infinite ease;
    }

    @keyframes firstCircle {
        0% {
            transform: translateX(0) translateY(0);
        }

        35% {
            transform: translateX(2%) translateY(-14%);
        }

        65% {
            transform: translateX(5%) translateY(14%);
        }

        100% {
            transform: translateX(0) translateY(0);
        }
    }

    @keyframes secondCircle {
        0% {
            transform: translateX(0) translateY(0);
        }

        40% {
            transform: translateX(-9%) translateY(-1%);
        }

        75% {
            transform: translateX(11%) translateY(-14%);
        }

        100% {
            transform: translateX(0) translateY(0);
        }
    }

    @keyframes thirdCircle {
        0% {
            transform: translateX(0) translateY(0);
        }

        30% {
            transform: translateX(5%) translateY(11%);
        }

        70% {
            transform: translateX(-13%) translateY(-5%);
        }

        100% {
            transform: translateX(0) translateY(0);
        }
    }

    .bx-ie & {
        &--first {
            animation: firstCircleIE 10s infinite ease;
        }

        &--second {
            animation: secondCircleIE 10s infinite ease;
        }

        &--third {
            animation: thirdCircleIE 10s infinite ease;
        }

        @keyframes firstCircleIE {
            0% {
                margin-top: 0;
                margin-left: 0;
            }

            35% {
                margin-top: 5%;
                margin-left: -5%;
            }

            65% {
                margin-top: -3%;
                margin-left: 5%;
            }

            100% {
                margin-top: 0;
                margin-left: 0;
            }
        }

        @keyframes secondCircleIE {
            0% {
                margin-top: 0;
                margin-left: 0;
            }

            40% {
                margin-top: -3%;
                margin-left: -5%;
            }

            75% {
                margin-top: 5%;
                margin-left: -4%;
            }

            100% {
                margin-top: 0;
                margin-left: 0;
            }
        }

        @keyframes thirdCircleIE {
            0% {
                margin-top: 0;
                margin-left: 0;
            }

            30% {
                margin-top: 5%;
                margin-left: 3%;
            }

            70% {
                margin-top: -4%;
                margin-left: -5%;
            }

            100% {
                margin-top: 0;
                margin-left: 0;
            }
        }
    }
}
