.contacts {
    padding-top: rem(40px);
    padding-bottom: rem(8px);

    &--calendar-detail {
        padding-top: 0;
        padding-bottom: 0;
    }

    &__title {
        margin-bottom: rem(24px);
        font-size: rem(14px);
        line-height: (24 / 14);
        letter-spacing: rem(1px);
        text-transform: uppercase;
    }

    &__list {
        &-wrap {
            display: flex;
        }

        &-icon {
            margin-top: em(4px, 16px);
            flex-shrink: 0;
        }
    }

    &__icon-wrap {
        width: 1rem;
        display: flex;
        justify-content: center;
    }
}
