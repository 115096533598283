.content-tabs {
    &__link {
        width: auto;
        font-size: rem(16px);
        line-height: (24 / 16);
        font-weight: bold;

        &.active {
            color: $firefly;
        }

        &:not(.active) {
            color: rgba($firefly, 0.5);
        }

        @media screen and (min-width: #{breakpoint-min('sm')}) {
            cursor: default;
            color: $firefly !important;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(24px);
            line-height: (32 / 24);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            font-size: rem(32px);
            line-height: (40 / 32);
        }
    }
}
