.documents-header {
    display: none;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        display: flex;
        justify-content: space-between;
        padding-bottom: rem(30px);
        border-bottom: 1px solid $firefly02;
        border-radius: 1px;
    }

    &__name {
        font-size: rem(14px);
        color: $firefly08;
        letter-spacing: rem(0.175px);
    }

    &__event {
        font-size: rem(14px);
        color: $firefly08;
        letter-spacing: rem(0.175px);
    }
}
