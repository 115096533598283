.document {
    display: inline-flex;
    transition: all linear 0.3s;

    &__info {
        padding-left: rem(32px);

        &--small {
            padding-left: rem(10px);
        }
    }

    &-info {
        width: 100%;

        &--bottom {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        &__title {
            font-size: rem(14px);
            line-height: (22/14);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                font-size: rem(20px);
                line-height: (28 / 20);
            }
        }

        &__size {
            color: #0E212F;
            font-size: rem(14px);
            font-weight: bold;
            line-height: 24px;
            white-space: nowrap;
            opacity: 0.4;

            & span:nth-child(2) {
                padding-left: 36px;
                position: relative;

                &::before {
                    content: '';
                    width: rem(4px);
                    height: rem(4px);
                    background-color: #0E212F;
                    position: absolute;
                    left: 16px;
                    top: 0;
                    bottom: 0;
                    border-radius: 50%;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }

        &__link {
            display: flex;
            align-items: center;
            font-size: rem(16px);
            font-weight: bold;
            line-height: (20 / 16);

            &-icon {
                margin-right: rem(16px);
            }
        }
    }

    &__icon {
        font-size: rem(73px);
        line-height: 0.5em;
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        min-height: rem(77px);
    }
}
