.contact-accordion {
    &--popup {
        padding-top: rem(16px);
        display: none;
    }

    &__wrapper {
        border-top: 1px solid $ironLight;

        &:last-child {
            border-bottom: 1px solid $ironLight;
        }
    }

    &__head {
        padding-top: rem(8px);
        padding-right: rem(10px);
        padding-bottom: rem(8px);
        padding-left: 0;
        cursor: pointer;
        position: relative;
        font-weight: 700;
        font-size: rem(14px);

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-top: rem(16px);
            padding-bottom: rem(16px);
        }

        .icon {
            transition: 0.3s;
        }

        .icon--plu {
            margin-right: rem(-2px);
        }

        .icon--min {
            display: none;
            width: rem(16px);
            height: rem(16px);
        }

        &--plus {
            transition: 0.2s;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: bold;

            span {
                opacity: 0.4;
            }
            svg {
                opacity: 0.4;
            }

            &:hover {
                span {
                    opacity: 1;
                }
                svg {
                    opacity: 1;
                }
            }

            &.open {
                span {
                    opacity: 1 !important;
                }
                svg {
                    opacity: 1 !important;
                }
            }
        }

        &.open {
            .icon {
                transform: rotate(180deg);
                transition: 0.3s;
            }

            .icon--plu {
                display: none;
            }

            .icon--min {
                display: inline-block;
            }
        }

        &--centers {
            padding-top: rem(24px);

            &.open {
                padding-bottom: rem(8px);
            }
        }
    }

    &__tab {
        &-heading {
            display: flex;
            align-items: center;
            padding-top: rem(8px);
            padding-bottom: rem(8px);
        }

        &-inner {
            display: flex;
            align-items: center;
            padding-top: rem(16px);

            &--leader {
                & ~ & {
                    padding-top: rem(32px);
                }
            }
        }

        &-inner-text {
            &:first-child {
                width: 60%;
            }

            &:last-child {
                width: 40%;
                text-align: right;
            }

            &--fluid {
                &:first-child {
                    width: 100%;
                    text-align: left;
                }
            }
        }

        &-list {
            padding-left: rem(24px);
            padding-top: rem(8px);
            margin-bottom: 0;

            &-item {
                padding-bottom: rem(16px);
                color: $grayChateau;

                span {
                    color: $firefly;
                }
            }
        }

        &-heading-text {
            color: $grayChateau;

            &:first-child {
                width: 60%;
            }

            &:last-child {
                width: 40%;
                text-align: right;
            }

            &--fluid {
                &:first-child {
                    width: 100%;
                    text-align: left;
                }
            }
        }
    }

    &__body {
        padding: rem(10px);
        margin-bottom: rem(5px);
        display: none;
        font-size: rem(14px);

        &--popup {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: rem(32px);
            padding-top: 0;
            margin-bottom: 0;
        }

        &--products {
            margin-bottom: rem(-16px);
        }

        &--plus {
        }
    }

    .icon {
        &-accordion-arrow-down {
            position: absolute;
            right: 0;
            margin-top: rem(6px);
            font-size: rem(13px);
        }
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        &__head {
            font-size: rem(16px);

            &--plus {
                font-size: rem(20px);
                line-height: rem(32px);
            }
        }
        &__body {
            font-size: rem(16px);
        }
    }
}
