.sitemap {
    &__item {
        margin-bottom: rem(40px);
        padding: 0;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(52px);

            &:nth-last-child(-n + 2) {
                margin-bottom: 0;
            }
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(88px);
        }

        &--hidden {
            display: none;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                display: flex;
            }
        }

        & h3 {
            max-width: rem(260px);
        }

        & h4 {
            max-width: rem(270px);
        }

        & p {
            width: 100%;
            max-width: rem(250px);
        }

        & p > a {
            width: 100%;
            font-size: rem(16px);
            line-height: (24/16);
            color: $catskill-white;

            &:hover {
                color: $eucalyptus;
            }
        }
    }

    &__list {
        @include list-reset;

        & * ul {
            @include list-reset;
        }

        & .sitemap__list-1 {
            &:first-child li > h3 {
                margin-bottom: rem(28px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(32px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(40px);
                }
            }
        }

        & .sitemap__list-2 {
            & h4 {
                margin-bottom: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    margin-bottom: rem(28px);
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    margin-bottom: rem(32px);
                }
            }
        }

        & .sitemap__list-3 {
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(28px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                margin-bottom: rem(40px);
            }

            & li {
                margin-bottom: rem(16px);

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
