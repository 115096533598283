.square-radio {
    &__label {
        position: relative;
    }

    &__wrap {
        width: rem(40px) !important;
        height: rem(40px) !important;
        margin-right: rem(10px);
        margin-top: rem(5px);
        margin-bottom: rem(5px);
        font-size: 0;
    }

    & + &__label::before {
        position: absolute;
        content: attr(data-course);
        width: rem(40px);
        height: rem(40px);
        color: #b7bcc1;
        font-size: rem(16px);
        text-align: center;
        padding: rem(7px);
        border: rem(1px) solid $iron;
        border-radius: rem(4px);
    }

    &:checked + &__label::before {
        color: $black;
        border: rem(1px) solid $eucalyptus;
    }
}
