.product-carousel {
    overflow: hidden;
    margin-right: 0;
    margin-bottom: rem(32px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-right: -30%;
        margin-bottom: rem(64px);
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        margin-right: -46%;
        margin-bottom: rem(96px);
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        margin-right: -48px;
        padding-right: 48px;
    }

    .product-info-card{
        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding: 32px;
        }
        &:hover {
            box-shadow: 15px 15px 30px rgba(8, 37, 98, 0.1);
        }
    }
}
