.table {
    width: 100%;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
        border: 2px solid white;
        background-color: #ccc;
    }

    &::-webkit-scrollbar {
        -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
        height: 13px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 6px;
        border: 2px solid white;
        background-color: rgba(0, 0, 0, 0.5);
    }

    table {
        font-family: Calibri;
        font-size: .875rem;
        line-height: 120%;
        color: $table-text-black;

        & p {
            font-size: .875rem;
            line-height: 120%;
        }

        @include breakpoint (mobile) {
            font-size: 1.125rem;

            & p {
                font-size: 1.125rem;
            }
        }

        tbody {

            tr {
                transition: all 0.2s ease;

                &.border-bottom {
                    border-bottom: 1px solid $table-accent-dark !important;
                }
    
                &.bg-grey {
                    background-color: $table-bg-grey;
                }
    
                &.bottom-info {
                    font-size: 0.875rem;
                    color: $table-text-light;
                    border-top: 1px solid $table-accent-light;
                }
            }
        }


        td {
            min-width: rem(164px);
            line-height: rem(24px);
            padding: rem(9px) rem(24px);
            font-weight: bold;

            &.active {
                background-color: rgba($eucalyptus, 0.12);
            }

            &.first-cell {
                font-weight: 700;
                color: $table-text-middle;
            }

            &.border-bottom {
                border-bottom: 1px solid $table-accent-light !important;
            }
        }

        &__caption {
            color: rgba($firefly, 0.3);

            &-heading {
                display: block;
                padding-top: rem(40px);
                padding-bottom: rem(16px);
            }

            &-list {
                padding-left: rem(16px);
            }
        }
    }

    &__column {

        &--highlighted {
            background-color: rgba($eucalyptus, 0.12);
        }
    }

    &__figure {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;

        &-image {
            width: 3rem;
            height: 3rem;
            flex-shrink: 0;
            overflow: hidden;
            margin-right: 0.75rem;
            position: relative;
            text-align: center;
            border-radius: 50%;
            border: 1px solid $iron;

            &:after {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                border-radius: 50%;
                box-shadow: inset 0 0 0 0.325rem #fff;
            }

            img {
                max-width: 100%;
                max-height: 100%;
                width: 100%;
                height: 100%;
                object-fit: contain;
                font-family: 'object-fit: contain;';
            }
        }
    }

    &--theme-press {

        table {

            p {
                line-height: 120%; 
            }

            thead {
                background: $table-accent-light;

                th {
                    padding: 1.125rem 1.5rem;
                }

                p {
                    font-size: 22px;
                    font-weight: 700;
                    letter-spacing: 1px;
                    text-align: left;
                    color: $table-text-totall;
                }
            }

            tbody {
                color: $table-text-middle;

                tr {
                    transition: background-color 0.2s ease;

                    &:nth-child(even) {
                       background-color: $table-bg-grey; 
                    }

                    &:hover {
                        background-color: $table-row-hover;
                    }
                }

                td {
                    padding: 1rem 1.5rem;
                    min-width: 6.25rem;

                    &:first-child {
                        font-weight: 700;
                    }

                    &:not(:first-child) {
                        text-align: right;
                    }

                    &:last-child {

                        p {

                            >em {
                                color: $table-accent-dark;
                            }
                        }
                    }
                }
            }

            td {
                font-weight: 400;
                min-width: 6.25rem;

                &.highlighted {
                    background: rgba(45, 170, 85, 0.12);
                    font-weight: 700;
                }

                &.active {
                    background: none;

                    &.highlighted {
                        background: rgba(45, 170, 85, 0.12);
                    }
                }
            }

            tr {

                &.underline {

                    td {
                        border-bottom: 1px solid #000;
                    }
                }
            }
        }
    }

    &--layout-fixed {

        table {
            table-layout: fixed;
        }
    }

    &--theme-simple {

        table {

            thead {
                white-space: nowrap;
                background: $table-accent-light;
                border-bottom: 1px solid $table-line-grey;

                th {
                    color: $table-accent-dark;
                    vertical-align: middle;
                    padding: 1.5rem 1.5rem 0.5rem;
                }
            }

            tbody {
                vertical-align: middle;
            }

            th,
            td {
                min-width: auto;

                &:not(:first-of-type) {
                    padding-left: 1.5rem;
                }

                &:not(:last-of-type) {
                    padding-right: 1.5rem;
                }
            }

            th {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;
            }

            th[colspan="2"] {
                text-align: center;
                border-bottom: 1px solid $table-accent-middle;
            }

            th[colspan="3"] {
                border-top: 1px solid $table-accent-middle;
            }

            td {
                padding-top: 1.5rem;
                padding-bottom: 1rem;
                font-weight: 400;
            }

            tr {

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    &--theme-custom {
        table {
            border-bottom: 1px solid $table-accent-light;

            thead {
                border-bottom: 1px solid $table-accent-light;

                th {
                    padding: 1.5rem 1.5rem 0.5rem;
                    vertical-align: middle;
                }

                tr:first-child {
                    & th {
                        color: $table-accent-dark;
                        font-weight: 700;

                        &[rowspan="3"] {
                            font-size: 1rem;
                            font-weight: 400;
                            color: $table-text-light;
                            vertical-align: bottom;
                        }

                        &:nth-child(3) {
                            background: $table-custom-bg-30;
                        }

                        &:nth-child(4) {
                            background: $table-custom-bg-50;
                        }

                        &:nth-child(5) {
                            background: $table-custom-bg-70;
                        }

                        &:nth-child(6) {
                            background: $table-custom-bg-90;
                        }
                    }
                }

                tr:nth-child(2) {
                    & th {
                        font-size: 1rem;
                        text-align: right;
                        font-weight: 400;
                        color: $table-text-light;

                        &:nth-child(1) {
                            background: $table-custom-bg-30;
                        }

                        &:nth-child(2) {
                            background: $table-custom-bg-30;
                        }

                        &:nth-child(3) {
                            background: $table-custom-bg-50;
                        }

                        &:nth-child(4) {
                            background: $table-custom-bg-50;
                        }

                        &:nth-child(5) {
                            background: $table-custom-bg-70;
                        }

                        &:nth-child(6) {
                            background: $table-custom-bg-70;
                        }

                        &:nth-child(7) {
                            background: $table-custom-bg-90;
                        }

                        &:nth-child(8) {
                            background: $table-custom-bg-90;
                        }
                    }
                }
            }

            tbody {
                & tr {

                    & td {
                        vertical-align: middle;
                        mix-blend-mode: multiply;

                        &:not(:nth-child(1)),
                        &:not(:nth-child(2)) {
                            color: $table-text-dark;
                            text-align: right;
                        }
    
                        &:nth-child(3) {
                            background: $table-custom-bg-30;
                        }

                        &:nth-child(4) {
                            background: $table-custom-bg-30;
                        }

                        &:nth-child(5) {
                            background: $table-custom-bg-50;
                        }

                        &:nth-child(6) {
                            background: $table-custom-bg-50;
                        }

                        &:nth-child(7) {
                            background: $table-custom-bg-70;
                        }

                        &:nth-child(8) {
                            background: $table-custom-bg-70;
                        }

                        &:nth-child(9) {
                            background: $table-custom-bg-90;
                        }

                        &:nth-child(10) {
                            background: $table-custom-bg-90;
                        }

                        &:first-child {
                            font-weight: 700;
                            color: $table-text-light;
                            text-align: center;
                            padding-left: 1.5rem;
                            padding-right: 1.5rem;
                        }

                        &:nth-child(2) {
                            color: $table-text-middle;
                            text-align: left;
                            font-weight: 700;
                        }
                    }
                }
            }

            th,
            td {
                min-width: auto;

                &:not(:first-of-type) {
                    padding-left: 1.5rem;
                }

                &:not(:last-of-type) {
                    padding-right: 1.5rem;
                }
            }

            th {
                padding-top: 1.5rem;
                padding-bottom: 1.5rem;

                &.col-bg {
                    position: relative;
                    z-index: 10;

                    &::before {
                        content: '';
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0px;
                        left: 0px;
                    }
                }
            }

            th[colspan="2"] {
                color: $table-accent-dark;
                text-align: right;
                border-bottom: 1px solid $table-accent-middle;
            }

            th[colspan="3"] {
                border-top: 1px solid $table-accent-middle;
            }

            td {
                vertical-align: top;
                padding-top: 1.5rem;
                padding-bottom: 1rem;
                font-weight: 400;
            }

            tr {
                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    &--theme-yellow {
        th,
        td {
            border: 1px solid #cfd3d5;
            padding: 0.5rem 1rem;
        }
    
        td:first-child {
            color: inherit !important;
        }
        
        thead {
            background-color: #FBF7E5;
        }
    }

}
