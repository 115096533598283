.procurement {
    &__scheme {
        margin-top: 4.25rem;
        margin-bottom: 6rem;
        padding-top: 2rem;
        position: relative;
        width: 100%;

        svg {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;

            a[href^='mailto:'],
            a[href^='tel:'] {
                &:hover {
                    cursor: pointer;

                    text,
                    tspan {
                        fill: #2daa55;
                    }
                }
            }

            a.title {
                cursor: pointer;
                text,
                tspan {
                    text-decoration: underline;
                }

                &:hover {
                    text,
                    tspan {
                        fill: #2daa55;
                    }
                }
            }
        }

        &--planning {
            &::before {
                content: '';
                padding-top: 350%;
                display: block;
            }
        }

        &--directorate {
            &::before {
                content: '';
                padding-top: 263%;
                display: block;
            }
        }

        &--materials {
            &::before {
                content: '';
                padding-top: 305%;
                display: block;
            }
        }

        &--contacts-and-structure {
            &::before {
                content: '';
                display: block;
                padding-top: 210%;
            }
        }

        @media screen and (max-width: 564px) {
            // can`t use display: none cause of image cache problem
            opacity: 0;
            position: absolute;
        }

        &-mobile {
            display: none;

            @media screen and (max-width: 564px) {
                display: block;
                margin-top: 2rem;
                opacity: 1;
                position: relative;
            }

            &--planning {
                &::before {
                    padding-top: 1400%;
                }
            }

            &--directorate {
                &::before {
                    padding-top: 1400%;
                }
            }

            &--materials {
                &::before {
                    padding-top: 1000%;
                }
            }

            &--contacts-and-structure {
                &::before {
                    padding-top: 850%;
                }
            }
        }
    }
}
