.product-details-cards {
    display: flex;
    flex-wrap: wrap;
    margin: rem(-16px);
    padding-left: 0;
    list-style: none;

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin: rem(-24px);
    }

    &__item {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: calc(50% - 32px);
        margin: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            width: calc(#{percentage(1 / 3)} - 48px);
            margin: rem(24px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            width: calc(25% - 48px);
            margin: rem(24px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            width: calc(20% - 48px);
            margin: rem(24px);
        }
    }

    &__figure {
        margin-bottom: rem(32px);
    }

    &__text {
        margin-top: auto;
    }
}
