.page-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(24px);

    @media screen and (min-width: #{breakpoint-min('md')}) {
        margin-bottom: rem(48px);
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        margin-bottom: rem(64px);
    }

    &__settings {
        &-toggle {
            margin: rem(-8px);
            padding: rem(8px);
            border: none;
            background-color: transparent;
            color: $firefly;

            &:hover,
            &:focus,
            &:active {
                color: $eucalyptus;
            }
        }

        &-icon {
            width: rem(24px);
            height: rem(24px);
        }

        &-accordion {
            position: absolute;
            top: 100%;
            left: 0;
            z-index: 1;
            width: 100%;
            padding-top: rem(24px);
            padding-left: rem(24px);
            padding-bottom: rem(24px);
            background-color: $white;
            transform: translateY(rem(24px));
            box-shadow: 15px 15px 45px rgba($deepSapphire, 0.1);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(40px);
                padding-left: rem(40px);
                padding-bottom: rem(40px);
            }
        }
    }
}
