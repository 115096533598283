@media print {

    @page {
        margin-top: 0.8cm;
        margin-bottom: 0.8cm;
    }

    .info-panel,
    .header__controls,
    .header__search-block,
    .header__menu,
    .header__background,
    .btn-top,
    .cookie-popup,
    .subscribe,
    .made-by,
    .grecaptcha-badge,
    .breadcrumbs {
        display: none !important;
    }

    .page-container {
        height: auto !important;
    }

    header {
        height: 14rem !important;
    }

    .header {
        background: #fff !important;

        &__top {
            position: static !important;

            >.container {
                padding-left: 80px;
                padding-right: 80px;
            }
        }

        &__top-inner {
            padding-top: 3rem !important;
        }

        &__logo-img {
            display: none;

            &--coloured {
                display: block !important;
            }
        }

        &__banner {
            height: auto !important;
            padding-top: 2rem !important;

            >.container {
                max-width: none !important;
                padding-left: 80px;
                padding-right: 80px;

                >.row {
                    >div {
                        margin-left: 0 !important;
                    }
                }
            }
        }

        &__banner-header {
            font-size: 3.5rem !important;
            line-height: 1.125 !important;
            color: #0e212f !important;
        }
    }

    .wrapper {
        >.container {
            max-width: none !important;
            padding-left: 80px;
            padding-right: 80px;

            >.row {
                >div {
                    &:nth-child(1) {
                        min-width: 100% !important;
                        margin: 0 !important;
                    }

                    &:nth-child(2) {
                        display: none !important;
                    }
                }
            }
        }
    }

    .press {
        &__news {
            &--facility {
                display: none !important;
            }
        }
    }

    .press-detail {
        &__detail {
            padding-top: 2rem !important;
            padding-bottom: 2rem !important;
        }

        &__header {
            font-size: 2rem !important;
            line-height: 1.25 !important;
        }

        &__header-bottom-type {
            display: none !important;
        }

        &__article {
            +div {
                display: none !important;
            }
        }
    }

    .z-table {

        thead {
            display: table-header-group !important;
        }

        colgroup {
            display: table-column-group !important;
        }

        &--theme-news {
            
            .z-table-row {

                .z-table-col {
                    &:not(:first-child) {
                        text-align: right !important;
                    }
                }
            }

            .z-table-col {
                padding: 1rem 0.75rem !important;

                &--head {
                    padding: 1.5rem 0.75rem 0.5rem 0.75rem !important;
                }

                &__title {
                    display: none !important;
                }
            }
        }

        .z-table__container {
            overflow: hidden !important;
        }

        .z-table-col {
            width: auto !important;
        }

        .z-table-row {
            &--body {
                display: table-row !important;
            }
        }

        .z-table-col {
            &--body {
                display: table-cell !important;
            }
        }

        .z-table-row,
        .z-table-col {
            break-inside: avoid;
        }
    }


    .footer {
        background-color: #fff !important;

        >.container {
            display: none !important;
        }

        &__print {
            display: block !important;

            >.container {
                max-width: none !important;
                padding-left: 80px;
                padding-right: 80px;
            }
        }
    }
}