.quote {
    background: #eaf6ee;
    border-radius: 3px;
    padding: rem(32px) rem(64px);
    font-size: rem(20px);
    line-height: rem(32px);
    letter-spacing: rem(0.25px);
    color: #0e212f;
    margin-bottom: rem(16px);

    &__title {
        font-weight: bold;
    }

    &--eucalyptus {
        background-color: rgba($eucalyptus, 0.2);
    }

    &--congress-blue {
        background-color: rgba($congressBlue, 0.1);
    }

    &--transparent {
        background-color: transparent;
    }
}
