.contacts-page {
    &__heading {
        &--contacts {
            padding-top: rem(25px);
            padding-bottom: rem(24px);
            font-size: rem(28px);
            line-height: rem(36px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(38px);
                font-size: rem(48px);
                line-height: rem(60px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(98px);
                font-size: rem(64px);
                line-height: rem(72px);
            }
        }

        &--n-offices {
            padding-top: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(64px);
            }
        }

        &--f-name {
            padding-top: rem(32px);
        }

        &--investors {
            padding-top: rem(64px);
            padding-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(80px);
                padding-bottom: rem(24px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(120px);
                padding-bottom: 0;
            }
        }

        &--press {
            padding-top: rem(40px);
            padding-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(56px);
                padding-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(120px);
                padding-bottom: rem(64px);
            }
        }

        &--hr {
            padding-top: rem(64px);
            padding-bottom: rem(32px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(80px);
                padding-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(120px);
                padding-bottom: rem(64px);
            }
        }

        &--name {
            padding-bottom: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(24px);
            }
        }

        &--tab-pane {
            padding-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(32px);
            }
        }
    }

    &__concern-card {
        padding-top: rem(24px);
        padding-bottom: rem(16px);
    }

    &__aside-list {
        list-style: none;
        padding-left: 0;
        margin-bottom: rem(24px);
        margin-right: rem(40px);
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;

        li {
            padding-bottom: rem(24px);
            padding-right: rem(40px);
            cursor: pointer;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-left: rem(24px);
            margin-bottom: 0;
            display: block;

            li {
                padding-bottom: rem(32px);
            }
        }
    }

    &__link {
        font-size: rem(16px);
        line-height: rem(20px);
        font-weight: bold;
        color: $eucalyptus;
        padding-top: rem(24px);
    }

    &__card {
        &-column {
            padding-top: rem(64px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(80px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(120px);
            }

            &-heading {
                padding-top: rem(24px);

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    padding-top: 0;
                }
            }

            &-list {
                padding-top: rem(24px);
            }
        }
    }

    &__investors {
        &-list {
            padding-top: 0;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(16px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: rem(24px);
            }

            &-item {
                padding-bottom: rem(32px);

                .concern-card__figure {
                    max-width: 600px;
                }

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    padding-top: rem(40px);
                    padding-bottom: 0;
                }
            }
        }
    }

    &__controls {
        padding-top: rem(64px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(80px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(120px);
        }
    }

    &__contacts-link {
        padding-top: rem(24px);
        font-size: rem(16px);
        line-height: rem(20px);
        font-weight: bold;
    }

    &__concern-card-name {
        padding-bottom: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(24px);
        }
    }

    &__attention {
        background: #E5EDF4;
        border-radius: 6px;
        padding: 48px 149px 48px 48px;
        display: flex;
        flex-direction: row;
        gap: 32px;
        justify-content: space-between;
        align-items: center;

        &-subtitle {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.8px;
            text-transform: uppercase;
            color: #1D498E;
            display: flex;
            flex-direction: column;
            gap: 16px;

            > a {
                font-size: 16px;

                > svg {
                    width: 10px;
                    height: 14px;
                }
            }
        }

        @media screen and (max-width: #{breakpoint-min('xl')}) {
            margin-bottom: 48px;
        }

        @media screen and (max-width: #{breakpoint-min('lg')}) {
            padding: 36px;
            margin-bottom: 36px;
            gap: 16px;
        }

        @media screen and (max-width: #{breakpoint-min('md')}) {
            padding: 24px;
            margin-bottom: 24px;
            flex-direction: column;
            align-items: flex-start;

            &-subtitle {
                gap: 8px;
            }
        }

        @media screen and (max-width: #{breakpoint-min('sm')}) {
            padding: 16px;
            margin-bottom: 16px;
        }
    }

    &__feedback-form {
        .feedback-form {
            max-width: none;
        }
    }
}
