.subscribe-modal {
    display: block;
    background-color: $black07;

    &--admin {
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: auto;
        padding-bottom: 0;
        background-color: $white;
        overflow: unset;
    }

    &__content {
        display: block;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow-y: auto;
        height: 100%;
        width: 100%;
        max-width: 100%;
        min-height: 100vh;
        padding: 3rem 1rem;
        background-color: $white;
        z-index: 999999;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            max-width: rem(624px);
            min-height: auto;
            height: auto;
            padding-top: rem(48px);
            padding-right: rem(64px);
            padding-bottom: rem(48px);
            padding-left: rem(64px);
            border-radius: rem(6px);
            box-shadow: 0 rem(4px) rem(80px) $black04;
        }

        &--success {
            //display: none;
            text-align: center;
            height: auto;
            width: 100%;
            padding-top: rem(56px);
            padding-bottom: rem(56px);
            max-width: rem(288px);
            min-height: auto;
            border-radius: rem(6px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                max-width: rem(624px);
            }
        }

        &--admin {
            position: static;
            display: block;
            min-height: auto;
            top: auto;
            left: auto;
            transform: none;
            padding: 0;
            box-shadow: none;
            z-index: auto;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                max-width: 100%;
            }

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                max-width: rem(624px);
                padding: 0;
            }
        }
    }

    &__title {
        margin-bottom: rem(20px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(40px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(48px);
        }

        &--green {
            color: $eucalyptus;
        }

        &--success {
            margin-bottom: rem(8px);
            color: $eucalyptus;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(12px);
            }
        }
    }

    &__text {
        &--success {
            width: 100%;
            margin: 0 auto;
            max-width: rem(190px);
            margin-bottom: rem(8px);
            font-family: $foco, $fonts-default;
            font-size: rem(16px);
            font-weight: bold;
            line-height: (24/16);
            color: $eucalyptus;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(12px);
                max-width: rem(320px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
                line-height: (32/20);
            }
        }
    }

    &__fieldset {
        margin: 0;

        &--email {
            margin-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(48px);
            }
        }

        &--checkboxes {
            margin-bottom: rem(24px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(28px);
            }
        }
    }

    &__legend {
        margin-bottom: rem(24px);
        font-family: $foco, $fonts-default;
        font-size: rem(16px);
        line-height: (24/16);
        font-weight: bold;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(32px);
            font-size: rem(20px);
            line-height: (32/20);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            font-size: rem(20px);
            line-height: (32/20);
        }
    }

    &__field {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        margin-bottom: rem(24px);
        width: 100%;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(28px);
            width: 50%;
        }

        &--agree {
            width: 100%;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 100%;
            }
        }
    }

    &__input {
        font-size: rem(14px);
        line-height: (22/14);
        font-weight: normal;
        padding: 20px 0 0 10px;
        margin: 0;
        border: none;
        color: #000;
        -webkit-appearance: none;

        &--email {
            font-family: $foco, $fonts-default;
            padding-bottom: rem(14px);
            width: 100%;
            max-width: 100%;
            font-size: rem(16px);
            line-height: (24/16);
            font-weight: bold;
            border-bottom: 1px solid $iron;
            color: $firefly;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-bottom: rem(16px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(20px);
                line-height: (32/20);
            }

            &::placeholder {
                font-family: $foco, $fonts-default;
                font-size: rem(16px);
                line-height: (24/16);
                font-weight: bold;
                color: $firefly03;

                @media screen and (min-width: #{breakpoint-min('xl')}) {
                    font-size: rem(20px);
                    line-height: (32/20);
                }
            }
        }

        &--checkbox:checked + .subscribe-modal__label::before {
            border: 1px solid $eucalyptus;
            background-image: url('/local/templates/.default/img/icons/check-green.svg');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: rem(16px);
        }
    }

    &__label {
        position: relative;
        margin: 0;
        font-size: rem(14px);
        line-height: (22/14);
        font-weight: normal;
        padding-left: rem(40px);
        cursor: pointer;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(16px);
            line-height: (24/16);
            letter-spacing: 0.2px;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: rem(24px);
            height: rem(24px);
            border-radius: rem(4px);
            border: 1px solid $iron;
        }

        &--agree {
            margin-bottom: rem(24px);
            color: $firefly04;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: rem(28px);
            }
        }
    }

    &__confirm-link {
        color: $eucalyptus;
    }

    &__button {
        &--submit {
            width: 100%;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: auto;
            }
        }

        &--save-changes {
            width: 100%;
            margin-bottom: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: 0;
                margin-right: rem(12px);
                max-width: rem(250px);
            }
        }

        &--cancel {
            width: 100%;
            margin-bottom: rem(16px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: 0;
                max-width: rem(250px);
            }
        }
    }

    &__close-button {
        position: absolute;
        top: 3.2rem;
        right: rem(20px);
    }

    &__close-button-text {
        font-size: 0;
    }
}
