.product-details {
    .agroexperiences-card {
        padding: 32px 100px 32px 32px;
        border: 1px solid #D0D3DA;
        border-radius: 3px;
        position: relative;
        transition: all 500ms ease;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &:hover {
            border: 1px solid transparent;
            transform: translateY(-10px);
            box-shadow: 15px 15px 45px rgba(8, 37, 98, 0.1);
        }

        &__tag {
            padding-left: 8px;
            padding-right: 8px;
            color: white;
            text-transform: uppercase;
            background-color: #2DAA55;
            font-size: 12px;
            font-weight: 400;
            display: inline-block;
            max-width: 150px;
        }

        &__title {
            margin-top: 32px;
            color: #0E212F;
            opacity: 0.6;
            font-weight: 700;
            font-size: 24px;
        }

        &__icon {
            max-width: 60px;
            max-height: 60px;
            position: absolute;
            right: 24px;
            top: 24px;
        }

        &__link {
            margin-top: 16px;
            color: #2DAA55;
            font-weight: 700;
            font-size: 20px;
            display: inline-block;
            position: relative;

            &:after {
                content: '';
                display: inline-block;
                position: absolute;
                margin-top: 0.5em;
                width: 12px;
                height: 12px;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4375 5.25C11.286 5.25 11.151 5.1885 11.049 5.0925C11.046 5.0895 11.0415 5.0895 11.0385 5.0865L9.6405 3.687L6.1005 7.227C5.7345 7.5915 5.1405 7.5915 4.7745 7.227C4.4085 6.861 4.4085 6.267 4.7745 5.901L8.313 2.3625L6.9135 0.9615C6.9105 0.9585 6.9105 0.954 6.9075 0.951C6.8115 0.849 6.75 0.714 6.75 0.5625C6.75 0.252 7.002 0 7.3125 0H11.25C11.664 0 12 0.336 12 0.75V4.6875C12 4.998 11.748 5.25 11.4375 5.25ZM1.5 3.375V10.125C1.5 10.332 1.668 10.5 1.875 10.5H8.625C8.832 10.5 9 10.332 9 10.125V6L10.5 7.5V10.5C10.5 11.3295 9.8295 12 9 12H1.5C0.672 12 0 11.3295 0 10.5V3C0 2.172 0.672 1.5 1.5 1.5H4.5L6 3H1.875C1.668 3 1.5 3.168 1.5 3.375Z' fill='%232DAA55'/%3E%3C/svg%3E%0A");
                margin-left: 8px;
                vertical-align: middle;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }

    .proagro-block {
        margin-top: 0 !important;
        
        .proagro-block__wrapper {
            display: flex;
            flex-direction: column-reverse;
        }
        
        .proagro-block__button {
            display: block;
            max-width: 100%;
            font-size: 14px;
            font-family: Foco;
            border-radius: 50px;
        }

        .proagro-block__text {
            max-width: 100%;
            margin-right: 0;
            text-align: center;
        }

        .proagro-block__image {
            width: 100%;
            margin-bottom: 50px;
        }

        .content__circles-outer {
            margin: 0 auto;
        }

        .proagro-logo {
            max-width: 50%;
            transform: translateX(50%);
        }

        .proagro-block__announce {
            background: rgba(45, 170, 85, 0.1);
            padding: 24px;
        }
        
        .proagro-block__annonunce-link {
            margin-top: 24px;
            display: flex;
            align-items: center;
            font-weight: 700;
            justify-content: center;

            &:before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                margin-right: 8px;
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.95011 4.63607L4.70747 8.87871C4.31695 9.26923 4.31695 9.9024 4.70747 10.2929C5.098 10.6834 5.73116 10.6834 6.12169 10.2929L10.3643 6.05028C10.7549 5.65976 10.7549 5.02659 10.3643 4.63607C9.9738 4.24554 9.34064 4.24554 8.95011 4.63607Z' fill='%232DAA55'/%3E%3Cpath d='M5.17188 3.82849H11.1719V9.82849' stroke='%232DAA55' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
        }

        .proagro-yt {
            background-image: url("data:image/svg+xml,%3Csvg width='128' height='90' viewBox='0 0 128 90' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M114.081 2.7412C119.564 4.21775 123.887 8.53999 125.363 14.0233C128.105 24.0388 127.999 44.9159 127.999 44.9159C127.999 44.9159 127.999 65.6863 125.363 75.7027C123.887 81.1851 119.565 85.5083 114.081 86.9839C104.065 89.6207 63.9995 89.6207 63.9995 89.6207C63.9995 89.6207 24.0389 89.6207 13.9179 86.8795C8.43451 85.4027 4.11228 81.0795 2.63573 75.5971C0 65.6863 0 44.8104 0 44.8104C0 44.8104 0 24.0388 2.63573 14.0233C4.11132 8.54095 8.53999 4.11228 13.9169 2.63672C23.9335 7.15255e-07 63.9987 0 63.9987 0C63.9987 0 104.065 -4.76837e-07 114.081 2.7412ZM84.5591 44.8099L51.2419 63.9995V25.6208L84.5591 44.8099Z' fill='%23FF0F00'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            z-index: 1;
            position: absolute;
            width: 71px;
            height: 50px;
            background-size: contain;
            bottom: -15%;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            transition: all .15s ease-in-out;

            &:hover {
                transform: translateY(-0.3125rem) translateX(-50%);
                cursor: pointer;
            }

        }

        .proagro-rut {
            background-image: url("/images/production/Icon_RUTUBE_dark_color.svg");
            background-repeat: no-repeat;
            z-index: 1;
            position: absolute;
            width: 55px;
            height: 55px;
            background-size: contain;
            bottom: -15%;
            left: 50%;
            transform: translateX(-50%);
            display: inline-block;
            transition: all .15s ease-in-out;

            &:hover {
                transform: translateY(-0.3125rem) translateX(-50%);
                cursor: pointer;
            }

        }
        
        .proagro-block__button {
            margin-top: 24px;
        }

        @include breakpoint (tablet) {
            .proagro-block__annonunce-link {
                justify-content: flex-start;
            }

            .proagro-block__text {
                max-width: 50%;
                margin-right: 64px;
                text-align: left;
            }
            
            .proagro-block__wrapper {
                flex-direction: row;
            }

            .proagro-block__image {
                margin-bottom: 0;
            }

            .content__circles-content {
                position: relative;
            }
    
            .proagro-block__button {
                font-size: 20px;
                margin-top: 72px;
                max-width: fit-content;
            }

            .proagro-yt {
                width: 128px;
                height: 90px;
            }

            .proagro-rut {
                width: 100px;
                height: 100px;
            }
    
            .proagro-logo {
                position: absolute;
                transform: translateX(50%) translateY(-50%);
                top: 50%;
            }
    
            .proagro-qr {
                position: absolute;
                right: 0;
                top: -23%;
                transform: translateX(40%);
            }
        }

        @include breakpoint (mobile) {
            margin-top: 120px !important;

            .proagro-yt {
                width: 90px;
                height: 70px;
            }

            .proagro-rut {
                width: 70px;
                height: 70px;
            }

            .proagro-qr {
                width: 50%;
            }
        }
    }


    .button-subscribe {
        border: 1px solid #B7BCC1;
        border-radius: 500px;
        transition: all 0.2s ease-in;

        &:hover {
            box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.09);
            border-color: transparent;
        }

        .subscribe-page__title-wrapper {
            padding: 20px 0;
            justify-content: center;
            width: 100%;
            height: 100%;
            max-width: none;

            @media (max-width: 600px) {
                justify-content: flex-start !important;
            }
        }

        @media (max-width: 768px) {
            .subscribe__title {
                font-size: 28px !important;
            }

            .subscribe-page__title-wrapper {
                padding: 20px;
            }
        }


        @media (max-width: 600px) {
            border: none;
            justify-content: flex-start !important;
            display: flex;

            .subscribe-page__title-wrapper {
                padding: 0;
            }

            .subscribe__title {
                font-size: 24px !important;
            }

            .subscribe__form-submit {
                width: 64px;
                height: 64px;
            }

            &:hover {
                box-shadow: none;
            }
        }

        .subscribe  {
            padding-bottom: 0;
        }

        .subscribe__title {
            font-weight: 700;
            font-size: 32px;
            line-height: 120%;
            margin-right: 20px;
        }
    }

    .agronomic-experiments-map-and-regions__title {
        display: none;
    }

    &__section {
        margin-bottom: rem(64px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: rem(96px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-bottom: rem(120px);
        }
    }

    &__header {
        margin-bottom: rem(24px);

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-bottom: rem(48px);
        }
    }

    &__group {
        &:not(:last-of-type) {
            margin-bottom: rem(40px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                margin-bottom: 0;
            }
        }
    }

    &__heading {
        font-size: rem(20px);
        line-height: 110%;

        @include breakpoint(v-mobile) {
            font-size: rem(23px);
            line-height: 120%;
        }

        @include breakpoint(tablet) {
            font-size: rem(24px);
        }

        &--carousel {
            margin-bottom: 32px;
        }
    }

    &__subheading {
        margin-bottom: rem(24px);
        font-size: rem(20px);
        line-height: 2;
    }

    &__tagline {
        margin-top: rem(16px);
        font-size: rem(20px);
        letter-spacing: rem(0.25px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-top: rem(24px);
        }
    }

    &__hr {
        display: block;
        margin-top: rem(40px);
        margin-bottom: rem(40px);
        border: 2px solid rgba($congressBlue, 0.1);
    }

    &__reserch {
        background-image: url('/upload/apasil/apasil-bg.jpg');
        background-position: 50% 35%;
        padding-left: rem(48px);
        padding-right: rem(48px);
        padding-top: rem(64px);
        padding-bottom: rem(64px);
        text-align: center;

        &--header {
            color: white;
        }

        &--text {
            color: white;
        }
    }

    .iframe_container {
        height: 0px;
        padding-bottom: 56.2%;
        position: relative;
    }
        .iframe_container iframe {
            width: 100%;
            height: 100%;
            position: absolute;
    }
}
