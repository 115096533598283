.agrochemical-soil-survey {
    $parent: &;
    margin-top: 0;

    @include breakpoint(tablet) {
        margin-top: -1rem;
    }

    img {
        width: 100%;
        height: auto;
    }

    &__section {
        margin-bottom: 4rem;

        @include breakpoint(v-mobile) {
            margin-bottom: 5rem;
        }

        @include breakpoint(mobile) {
            margin-bottom: 6rem;
        }
    }

    &__title {
        display: inline-block;
        font-family: 'Foco';
        color: #000;
        line-height: 110% !important;
        margin-bottom: 1.5rem !important;

        @media (max-width: 600px) {
            font-size: 1.25rem !important;
        }

        @include breakpoint(v-mobile) {
            margin-bottom: 2rem !important;
        }

        @include breakpoint(mobile) {
            margin-bottom: 3rem !important;
        }
    }

    &__highlighted {
        .z-caption {
            color: #17171A !important;
            line-height: 120%;
        }
    }

    &__highlighted-title {
        font-family: 'Foco';
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 120%;
        color: #000;
        margin-bottom: 1.5rem;

        @include breakpoint(v-mobile) {
            font-size: 1.5rem;
        }

        @include breakpoint(mobile) {
            font-size: 1.625rem;
        }
    }

    &__caption {
        font-family: 'Foco';
        
        @media (max-width: 600px) {
            font-size: 1rem !important;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        gap: 2rem 5.5rem;
    }

    &__list-item {
        max-width: none;
        display: flex;
        align-items: center;
        flex: 1 1 100%;
        gap: 1rem;

        @include breakpoint(mobile) {
            max-width: 436px;
            flex: 1 1 45%;
        }
    }

    &__list-icon {
        min-width: 3.375rem;
        height: 3.375rem;
        display: flex;

        @include breakpoint(v-mobile) {
            min-width: 4.375rem;
            height: 4.375rem;
        }

        @include breakpoint(mobile) {
            min-width: 5.375rem;
            height: 5.375rem;
        }
    }

    &__list-text {
        font-size: 0.875rem;

        @include breakpoint(v-mobile) {
            font-size: 1.125rem;
        }
    }

    &__top {
        margin-bottom: 3rem;
        
        @include breakpoint(mobile) {
            margin-bottom: 4rem;
        }
    }

    &__top-text {
        font-size: 0.875rem;
        color: #000;
        margin-top: 2rem;
        margin-bottom: 2rem;

        @include breakpoint(v-mobile) {
            font-size: 1.125rem;
            margin-top: 3rem;
        }
        
        @include breakpoint(mobile) {
            margin-top: 4rem;
            margin-bottom: 3rem;
        }
    }

    &__top-list {
        font-size: 0.875rem !important;
        color: #000;

        @include breakpoint(v-mobile) {
            font-size: 1.125rem !important;
        }

        .z-list-item {
            &::before {
                font-size: 1rem !important;
                margin-top: 0.2em !important;

                @include breakpoint(v-mobile) {
                    font-size: 1.375rem !important;
                    margin-top: 0.06em !important;
                }
            }
        }
    }

    &__video {
        margin-top: 4rem;
        margin-bottom: 4rem;

        @include breakpoint(v-mobile) {
            margin-top: 5rem;
            margin-bottom: 5rem;
        }

        @include breakpoint(mobile) {
            margin-top: 6rem;
            margin-bottom: 6rem;
        }
    }

    &__reasons {
        #{$parent}__caption {
            color: #000;
        }
    }

    &__reasons-list {
        margin-top: 2rem;
        margin-bottom: 2rem;

        @include breakpoint(v-mobile) {
            margin-top: 2.5rem;
            margin-bottom: 3rem;
        }
    }

    &__notice {
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            z-index: -1;
        }

        &--warning {
            background: rgba(236, 212, 127, 0.15);
            padding: 1rem;
            margin-bottom: 2rem;

            @include breakpoint(v-mobile) {
                padding: 2rem;  
                margin-bottom: 3rem;
            }

            @include breakpoint(mobile) {
                padding: 3rem;  
            }

            &::before {
                width: 140px;
                height: 140px;
                background-image: url('/local/templates/.default/img/agroservice/notice-warning-bg.png');
                bottom: -16px;
                right: -28px;

                @include breakpoint(mobile) {
                    width: 200px;
                    height: 200px;
                    bottom: -30px;
                    right: -28px;    
                }
            }
        }

        &--example {
            background: rgba(45, 170, 85, 0.15);
            padding: 1rem;

            @include breakpoint(v-mobile) {
                padding: 1.5rem 2rem;
            }

            @include breakpoint(mobile) {
                padding: 1.5rem 3rem;
            }

            &::before {
                width: 180px;
                height: 150px;
                background-image: url('/local/templates/.default/img/agroservice/notice-example-bg.png');
                bottom: -8px;
                right: -17px;

                @include breakpoint(mobile) {
                    width: 290px;
                    height: 237px;
                    bottom: -13px;
                    right: -17px;
                }
            }

            #{$parent}__notice-text {
                margin-bottom: 1rem;
            }
        }
    }

    &__notice-text {
        max-width: 712px;
        font-family: 'Foco';
        font-size: 1rem;
        line-height: 140%;
        letter-spacing: 0.25px;
        color: #17171A;

        @include breakpoint(v-mobile) {
            font-size: 1.25rem;
        }
    }

    &__notice-tag {
        width: fit-content;
        font-size: 0.75rem;
        line-height: 120%;
        letter-spacing: 0.69px;
        text-transform: uppercase;
        color: #fff;
        border-radius: 2px;
        background:#018FA1;
        padding: 0.25rem 0.5rem 0.125rem 0.5rem;
        margin-top: 0.5rem;
        margin-bottom: 2rem;

        @include breakpoint(mobile) {
            margin-bottom: 2.625rem;
        }
    }

    &__repeated-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 2rem;
        margin-bottom: 3rem;

        @include breakpoint(mobile) {
            gap: 2.5rem;
            margin-bottom: 5rem;    
        }
    }

    &__repeated-item {
        max-width: none;
        width: 100%;
        color: #000;

        @include breakpoint(mobile) {
            max-width: 400px;
        }
    }

    &__repeated-title {
        font-family: 'Foco';

        @media (max-width: 600px) {
            font-size: 1rem !important;
        }
    }

    &__repeated-text {
        font-size: 0.875rem;
        margin-top: 1rem;

        @include breakpoint(v-mobile) {
            font-size: 1rem;
        }

        @include breakpoint(mobile) {
            margin-top: 1.5rem;
        }
    }

    &__technology-title {
        color: #000;
        margin-bottom: 1.5rem !important;
    }

    &__technology-text {
        font-size: 0.875rem;
        color: #000;

        @include breakpoint(v-mobile) {
            font-size: 1rem;
        }

        &:not(:last-child) {
            margin-bottom: 1.5rem;
        }  
    }

    &__reviews {
        #{$parent}__title {
            margin-bottom: 1.625rem !important;
        }
    }
}

.agrochemical-soil-survey-partners {
    border-radius: 6px;
    border: 1px solid #D0D3DA;
    padding: 1rem;

    @include breakpoint(v-mobile) {
        padding: 2rem;
    }

    @include breakpoint(mobile) {
        padding: 3rem;
    }

    .z-caption {
        max-width: 773px;
        display: inline-block;
    }

    &__list {
        display: flex;
        align-items: center;
        gap: 3.5rem;
        margin-top: 1.375rem;

        @include breakpoint(v-mobile) {
            gap: 7.5rem;
        }
    }
}

.agrochemical-soil-survey-callback-header {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    border-radius: 6px;
    background:#E5EDF4;
    padding: 1rem;
    margin-bottom: 1.5rem;

    @include breakpoint(v-mobile) {
        padding: 3rem;
    }

    @include breakpoint(mobile) {
        flex-direction: row;
    }

    .z-caption {
        margin-bottom: 0 !important;
    }

    &__left {
        max-width: 380px;
    }

    &__right {
        flex: 1;
    }

    &__info {
        color: #1D498E;
        font-size: 1rem;
        line-height: 22px;
    }

    &__tel {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        font-family: 'Foco';
        font-size: 1.25rem;
        font-weight: 700;
        line-height: 120%;

        @include breakpoint(v-mobile) {
            font-size: 1.625rem;
        }
    }
}

.agrochemical-soil-survey-callback-card {
    border-radius: 10px;
    border: 1px solid #D0D3DA;
    margin-top: 4rem;
    position: relative;
    overflow: hidden;

    @include breakpoint(v-mobile) {
        margin-top: 5rem;
    }

    @include breakpoint(mobile) {
        margin-top: 6rem;
    }

    &__body {
        display: flex;
        justify-content: flex-end;
        background-image: none;
        background-size: 350px 100%;
        background-position: -234px 0;
        background-repeat: no-repeat;
        padding: 2rem;

        @include breakpoint(v-mobile) {
            background-image: url('/local/templates/.default/img/agroservice/callback-card-img.png');
            background-size: auto 100%;
            background-position: -60px 0;
            padding: 4rem 1rem 4rem 14rem;
        }

        @include breakpoint(mobile) {
            background-position: 0 0;
            padding: 5.75rem 3.375rem 5.75rem 25rem;
        }
    }

    &__circles {
        width: 40%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: -1;

        .circles {
            width: 200%;
            height: 200%;
            bottom: 50%;
            right: 200%;

            @include breakpoint(v-mobile) {
                bottom: 78%;
                right: 119%;
            }

            @include breakpoint(mobile) {
                bottom: 78%;
                right: 98%;
            }

            @include breakpoint(tablet) {
                bottom: 72%;
                right: 101%;
            }

            @include breakpoint(desktop) {
                bottom: 75%;
                right: 100%;
            }
        }

        .circle {
            &--main {
                background: none;
            }

            &--first {
                animation: firstCircleAnim 10s infinite ease;
            }
        
            &--second {
                animation: secondCircleAnim 10s infinite ease;
            }
        
            &--third {
                animation: thirdCircleAnim 10s infinite ease;
            }

            @keyframes firstCircleAnim {
                0% {
                    transform: translateX(0) translateY(0);
                }
                35% {
                    transform: translateX(4%) translateY(-14%);
                }
                65% {
                    transform: translateX(10%) translateY(14%);
                }
                100% {
                    transform: translateX(0) translateY(0);
                }
            }
        
            @keyframes secondCircleAnim {
                0% {
                    transform: translateX(0) translateY(0);
                }
                40% {
                    transform: translateX(-9%) translateY(-1%);
                }
                75% {
                    transform: translateX(22%) translateY(-14%);
                }
                100% {
                    transform: translateX(0) translateY(0);
                }
            }
        
            @keyframes thirdCircleAnim {
                0% {
                    transform: translateX(0) translateY(0);
                }
                30% {
                    transform: translateX(10%) translateY(11%);
                }
                70% {
                    transform: translateX(-13%) translateY(-5%);
                }
                100% {
                    transform: translateX(0) translateY(0);
                }
            }
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }

    &__text {
        max-width: 490px;
        font-family: 'Foco';
        color: #0E212F;
        margin-bottom: 0 !important;
    }
}