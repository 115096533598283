.button {
    $parent: &;
    position: relative;
    top: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: none;
    transition: all 0.15s ease-in-out;
    line-height: 1.71;
    background: none;
    appearance: none;
    font-size: rem(14px);
    padding: rem(20px) rem(50px);
    font-weight: bold;
    min-width: rem(190px);
    border-radius: rem(32px);
    color: $firefly;
    text-transform: uppercase;
    max-width: rem(288px);

    &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &:hover {
        color: $firefly;
        top: rem(-5px);

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: rem(-5px);
        }
    }

    &--white {
        background: $white;

        &:hover {
            color: $firefly;
        }

        &:active {
            background: $concrete;
        }
    }

    &--green-border {
        color: $eucalyptus;
        border: 1px solid $eucalyptus;
    }

    &--green {
        color: $white;
        background: $eucalyptus;

        &:hover {
            color: $white;
        }

        &:active {
            background: $eucalyptus1;
        }
    }

    &--header {
        text-transform: none;
        color: $white;
        background: $eucalyptus;

        &:hover {
            color: $white;
        }

        &:active {
            background: $eucalyptus1;
        }
    }

    &--anticorruption {
        text-transform: none;
        color: $white;
        background: $eucalyptus;
        max-width: 28rem;
        padding: rem(8.5px) rem(32px);

        &:hover {
            color: $white;
        }

        &:active {
            background: $eucalyptus1;
        }
    }

    &--disabled {
        background-color: $iron;
    }

    &--bg-white {
        background-color: $white;
        color: $eucalyptus;
        border: 1px solid $eucalyptus;
    }

    &--link {
        display: inline-flex;
        max-width: none;
        min-width: auto;
        padding: 0;
        background: none;
        border: none;
        font-size: 16px;
        line-height: 1.5;
        color: #2DAA55;
        text-transform: none;
        border-radius: 0;

        :before {
            content: none;
        }

        #{$parent}__icon {
            margin-left: 0.3em;
        }

        #{$parent}__icon--left {
            margin-right: 0.3em;
        }

        &:hover {
            color: $firefly;
            top: 0;
        }

        &-dashed {
            border-bottom: 2px dashed #2DAA55;

            &:hover {
                border-bottom-color: $firefly;
            }
        };

        @include breakpoint (mobile) {
            font-size: 18px;
        }
    }

    &[data-toggle="collapse"] {
        .collapse-text {
            display: initial;
        }
        .collapse-text--expanded {
            display: none;
        }

        &[aria-expanded="true"] {
            #{$parent}__icon .icon {
                transform: rotate(180deg);
                transition: transform .2s ease-in;
            }
            .collapse-text {
                display: none;
            }
            .collapse-text--expanded {
                display: initial;
            }
        }
    }
}
