.wrapper {
    /*max-width: rem(1472px);*/
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;

    width: 100%;
    // overflow-x: hidden;

    position: relative;

    &--news-filter {
        @media screen and (min-width: #{breakpoint-min('md')}) {
            .select2-dropdown {
                min-width: rem(100px) !important;
            }
        }
    }
}

.container {
    padding-left: 16px;
    padding-right: 16px;

    @media screen and (min-width: #{breakpoint-min('sm')}) {
        padding-left: 32px;
        padding-right: 32px;
    }

    @media screen and (min-width: #{breakpoint-min('md')}) {
        padding-left: 48px;
        padding-right: 48px;
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        padding-left: 90px;
        padding-right: 90px;
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding-left: 100px;
        padding-right: 100px;
    }

    &__pt-100 {
        padding-top: rem(48px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(72px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(100px);
        }
    }
}

.container--wide {
    max-width: 1920px;
}

.row {
    margin-left: -8px;
    margin-right: -8px;

    @media screen and (min-width: #{breakpoint-min('sm')}) {
        margin-left: -12px;
        margin-right: -12px;
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        margin-left: -16px;
        margin-right: -16px;
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        margin-left: -24px;
        margin-right: -24px;
    }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    padding-left: 8px;
    padding-right: 8px;

    @media screen and (min-width: #{breakpoint-min('sm')}) {
        padding-left: 12px;
        padding-right: 12px;
    }

    @media screen and (min-width: #{breakpoint-min('lg')}) {
        padding-left: 16px;
        padding-right: 16px;
    }

    @media screen and (min-width: #{breakpoint-min('xl')}) {
        padding-left: 24px;
        padding-right: 24px;
    }

    //new
    // @extend %grid-gap;
    // padding-left: 12px;
    // padding-right: 12px;
}
