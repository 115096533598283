.document-item {
    &__box {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__title {
        max-width: rem(600px);
        margin-bottom: rem(10px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            font-size: rem(24px);
            line-height: (32 / 24);
            margin-bottom: 0;
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            font-size: rem(32px);
            line-height: (40 / 32);
            margin-bottom: 0;
        }

        &--accordion {
            @media screen and (min-width: #{breakpoint-min('xl')}) {
                font-size: rem(24px);
            }
        }
    }

    &__date {
        font-size: rem(14px);
        color: $firefly04;
    }
}
