.u-overflow--hidden {
    overflow: hidden;
}

.list-reset {
    @include list-reset;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

// Flex utilities
$flex-align-values: (center, flex-end, flex-start, end, start, stretch, baseline);
$flex-justify-values: (center, flex-end, flex-start, end, start, stretch, space-between, space-around, space-evenly);
$flex-wrap-values: (wrap, nowrap, wrap-reverse);

@each $direction in $flex-align-values {
    .u-flex-align--#{$direction} {
        display: flex;
        align-items: $direction;
    }
    .u-flex-align--#{$direction}-important {
        display: flex !important;
        align-items: $direction !important;
    }
}

@each $direction in $flex-justify-values {
    .u-flex-justify--#{$direction} {
        display: flex;
        justify-content: $direction;
    }
    .u-flex-justify--#{$direction}-important {
        display: flex !important;
        justify-content: $direction !important;
    }
}

@each $direction in $flex-justify-values {
    .u-flex-justify-self--#{$direction} {
        display: flex;
        justify-self: $direction;
    }
    .u-flex-justify-self--#{$direction}-important {
        display: flex !important;
        justify-self: $direction !important;
    }
}

@each $wrap in $flex-wrap-values {
    .u-flex-wrap--#{$wrap} {
        display: flex;
        flex-wrap: $wrap;
    }
    .u-flex-wrap--#{$wrap}-important {
        display: flex !important;
        flex-wrap: $wrap !important;
    }
}

.u-normal {
    font-weight: normal !important;
}

.u-bold {
    font-weight: 700 !important;
}

.u-height-100 {
    height: 100%;
}

.u-cover {
    object-fit: cover;
}

.u-flex {
    display: flex;
}

.u-flex-col {
    flex-direction: column;
}