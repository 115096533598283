.meeting-shareholders {
    &__heading {
        &--title {
            padding-bottom: 0;
            margin-bottom: rem(32px);
            font-size: rem(28px);
            line-height: rem(36px);

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: rem(38px);
                font-size: rem(48px);
                line-height: rem(60px);
                margin-bottom: rem(40px);
            }

            @media screen and (min-width: #{breakpoint-min('xl')}) {
                padding-top: 0;
                margin-bottom: rem(64px);
                font-size: rem(64px);
                line-height: rem(72px);
            }
        }
    }

    &__subnavigation {
        margin-bottom: rem(40px);
    }

    &__text {
        margin-bottom: rem(25px);
    }

    &__descr {
        max-width: rem(704px);
        margin-bottom: rem(30px);

        &:nth-last-of-type(1) {
            margin-bottom: 0;
        }
    }

    &__documents-header {
        margin-bottom: rem(48px);

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            margin-bottom: rem(64px);
        }
    }
}
