.text-card {
    cursor: pointer;

    // &:before {
    //   position: absolute;
    //   content: "";
    //   top: rem(-24px);
    //   bottom: rem(-24px);
    //   left: 0;
    //   right: 0;
    //   background: $white;
    //   transition: 0.3s ease all;
    //   opacity: 0;
    //   z-index: -1;
    // }

    // &:hover {
    //   &:before {
    //     box-shadow: rem(15px) rem(15px) rem(45px) 0 rgba(8, 37, 98, 0.1);
    //     opacity: 1;
    //   }
    // }

    &__title {
        margin-bottom: rem(10px);
    }

    // &__inner {
    //   position: relative;
    //   z-index: 2;
    // }

    &--no-hover {
        cursor: default;
        position: static;

        &:before {
            display: none;
        }

        &:after {
            display: none;
        }
    }
}
