.news-filter {
    width: 100%;

    &__lists {
        display: none;
        flex-direction: column;

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            display: flex;
        }
    }

    &__wrapper {
        overflow: auto;
    }

    &__list {
        display: inline-flex;
        list-style: none;
        padding-left: 0;
        padding-bottom: rem(16px);
        padding-top: rem(16px);
        margin-bottom: 0;

        &-item {
            margin-right: rem(40px);
            font-size: 1rem;
            font-weight: 700;
            color: $grayChateau;
            white-space: nowrap;
            line-height: 1.5rem;
            letter-spacing: rem(0.2px);
            transition: 0.2s;
            cursor: pointer;

            &.active {
                color: $firefly;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__selects {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-direction: row;
            justify-content: space-between;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            display: none;
        }

        &-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                flex-direction: row;
                width: 50%;

                .news-filter__selects-wrapper {
                    padding-right: rem(15px);
                }
            }
        }

        &-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            label {
                margin-bottom: rem(3px);
            }

            @media screen and (min-width: #{breakpoint-min('md')}) {
                width: 25%;

                label {
                    margin-bottom: 0 !important;
                    height: 100%;
                    vertical-align: unset;
                    display: flex;
                    align-items: center;
                    padding-bottom: rem(4px);
                }
            }

            &--year {
                width: 36%;
            }

            &--theme {
                width: 43%;
            }

            .select2 {
                display: flex;
                justify-content: flex-end;
            }
        }
    }
}
