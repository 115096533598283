.executives {
    &__header {
        padding-top: rem(50px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(65px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            padding-top: rem(80px);
        }
    }

    &__box-content {
        display: none;

        &.active-tab-main {
            display: block;
        }
    }

    &__detail-header {
        align-items: flex-start;
        padding-top: rem(48px);
        padding-bottom: rem(74px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-top: rem(85px);
        }
    }

    &__detail-heading {
        margin-bottom: rem(16px);

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            margin-bottom: rem(24px);
        }
    }

    &__list-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: rem(46px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-direction: column;
            align-items: flex-start;
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            padding-right: rem(24px);
            padding-bottom: rem(55px);
            min-height: rem(300px);
        }
    }

    &__img {
        height: 100%;
    }

    &__image {
        margin-bottom: rem(20px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            margin-bottom: auto;
        }
    }

    &__img-wrapper {
        align-self: flex-start;
        height: rem(78px);
        padding-right: rem(16px);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-left: 0;
            padding-right: rem(24px);
            margin-bottom: rem(24px);
            height: rem(128px);
        }

        @media screen and (min-width: #{breakpoint-min('lg')}) {
            height: rem(156px);
            // padding-left: rem(24px);
            // padding-right: rem(24px);
        }
    }

    &__svg-wrapper {
        position: relative;

        &:before {
            content: "";
            position: relative;
            display: block;
            height: 0px;
            padding-top: 250%;

            @media screen and (min-width: #{breakpoint-min('md')}) {
                padding-top: 75%;
            }
        }

        &--procurement {

            &:before {
                padding-top: 150%;

                @media screen and (min-width: #{breakpoint-min('md')}) {
                    padding-top: 40%;
                }
            }
        }
    }

    &__svg {
        display: none;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;

        &--mobile {
            display: block;
        }

        @media screen and (min-width: #{breakpoint-min('md')}) {
            display: block;

            &--mobile {
                display: none;
            }
        }
    }

    &__name {
        padding-bottom: rem(8px);
        line-height: (24/16);

        @media screen and (min-width: #{breakpoint-min('md')}) {
            padding-bottom: rem(16px);
        }

        @media screen and (min-width: #{breakpoint-min('xl')}) {
            font-size: rem(16px);
        }
    }

    &__list {
        padding-top: rem(24px);
        padding-bottom: rem(10px);
        display: grid;
        gap: 0 8%;
        grid-template-columns: 1fr;
        
        @media screen and (min-width: #{breakpoint-min('md')}) {
            grid-template-columns: repeat(2, 1fr);
            padding-bottom: rem(20px);
        }
        
        @media screen and (min-width: #{breakpoint-min('lg')}) {
            grid-template-columns: repeat(3, 1fr);
            margin-right: rem(-24px);
            padding-top: rem(48px);
            padding-bottom: rem(40px);
        }
    }

    &__text-num {
        font-family: $foco;
        color: $grayChateau;
        font-weight: 600;
        font-size: rem(32px);
        line-height: rem(40px);
        text-align: left;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            text-align: end;
        }
    }

    &__text-block {
        padding-bottom: rem(24px);
        display: flex;
        flex-direction: column;

        @media screen and (min-width: #{breakpoint-min('md')}) {
            flex-direction: row;
        }

        &-inner {
            display: block;
            padding-bottom: rem(16px);

            @media screen and (min-width: #{breakpoint-min('lg')}) {
                padding-bottom: rem(24px);
            }
        }
    }
}
